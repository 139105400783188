import { Component, Inject, OnInit, ViewChild } from '@angular/core';

import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { SpeedDialModule } from 'primeng/speeddial';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { MainMenuComponent } from '../../components/shared/main-menu/main-menu.component';
import { RoleService } from '../../services/role.service';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { RedirectRequest } from '@azure/msal-browser';
@Component({
  selector: 'app-home',
  standalone: true,
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  imports: [
    SidebarModule,
    ButtonModule,
    SplitButtonModule,
    SpeedDialModule,
    AvatarModule,
    AvatarGroupModule,
    MainMenuComponent,
    CommonModule,
  ],
})
export class HomeComponent implements OnInit {
  userRoles: any[] = [];
  items: any = {};
  menuRol: any[] = [];
  constructor(private authService: MsalService) {}
  ngOnInit(): void {
    this.checkAndSetActiveAccount();

  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }

    this.userRoles = this.authService.instance.getActiveAccount()?.idTokenClaims?.roles || [];
    this.buildMenu();  
    this.loadMenuByUserRole();
  }

  buildMenu() {
    this.items = {
      [environment.roles.gestionRole]: {
        title: 'Gestión',
        class: 'pi pi-id-card text-4xl text-green-500',
        description:
          'Este módulo te permite gestionar solicitudes, realizar matriculas, configurar grupos, gestionar informes y más!',
        routerLink: '/gestor',
      },

      [environment.roles.adminRole]: {
        title: 'Administración',
        class: 'pi pi-sitemap text-4xl text-green-500',
        description:
          'Este módulo te permite realizar la administración de las sedes, ciclos, horarios y más!',
        routerLink: '/admin',
      },

      [environment.roles.instructorRole]: {
        title: 'Instructor',
        class: 'pi pi-stopwatch text-4xl text-green-500',
        description:
          'Este módulo te permite realizar la gestión de los grupos asignados como instructor',
        routerLink: '/instructor',
      },
      [environment.roles.checkerRole]: {
        title: 'Gestión de Accesos',
        class: 'pi pi-sign-in text-4xl text-green-500',
        description:
          'Este módulo te permite realizar la gestión de los ingresos de los alumnos a las clases',
        routerLink: '/checker',
      },
    };
  }

  loadMenuByUserRole() {
    for (let rol of this.userRoles) {
      this.menuRol?.push(this.items[rol]);
    }
  }
}
