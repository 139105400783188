<app-page-header
  [title]="'Matriculas'"
  [subtitle]="'Módulo de gestión matriculas y renovaciones'"
></app-page-header>
<p-toast ></p-toast>

<p-inplace [closable]="true" [active]="searchNewMember" (onActivate)="newMemberSearch()" >
    <ng-template pTemplate="display">
        <p-button label="Buscador de Alumnos" [rounded]="true"></p-button>
    </ng-template>
    <ng-template pTemplate="content">

        <div class="surface-section px-4 py-4 md:px-6 lg:px-8 text-center">
            <div class="mb-3 font-bold text-2xl">
                <span class="text-900">Buscador, </span>
                <span class="text-primary-600">Alumnos</span>
            </div>
            <div class="text-700 mb-6">Ingresa la información de la identificación del alumno.</div>
            <div class="search-inputs">
                <p-dropdown
                  class="m-2 w-2"
                  [options]="idTypes"
                  [(ngModel)]="idTypeSelected"
                  optionLabel="name"
                  optionValue="value"
                  placeholder="Seleccione tipo de identificación"
                ></p-dropdown>
                <p-inputNumber
                class="m-2"
                  [(ngModel)]="idNumberSelected"
                  mode="decimal"
                  inputId="withoutgrouping"
                  [useGrouping]="false"
                  placeholder="numero de identificación del alumno"
                  [allowEmpty]="true"
                  (keydown.enter)="searchMemberById()"
                  autocomplete="off"
                >
                </p-inputNumber>
                <button
                  type="button"
                  pButton
                  icon="pi pi-search"
                  class="m-2 p-button-primary"
                  (click)="searchMemberById()"
                ></button>
              </div>
        </div>
    </ng-template>
</p-inplace>

<p-divider></p-divider>

<div *ngIf="showNoFindedMember">
    <p-card header="Alumno No Encontrado" subheader="No hemos encontrado información" [style]="{ width: '360px', margin:'0 auto','text-align':'center', padding:'1rem'}">
        <ng-template pTemplate="header">
            <div class="flex justify-content-center m-2">
                <i class="pi pi-exclamation-circle" style="font-size: 4rem"></i>
            </div>
        </ng-template>
        <p [style]="{'text-align':'justify'}">
            No hemos encontrado información para el alumno identificado con {{idTypeSelected}} número {{idNumberSelected}}, por favor verifica la información.
        </p>
        <p [style]="{'text-align':'justify'}">
            Si es un alumno nuevo, realiza su registro en el sistema para continuar con la matricula.
        </p>
        <ng-template pTemplate="footer">
            <p-button label="Nuevo Registro" icon="pi pi-plus" (click)="initMemberRegister()"></p-button>
        </ng-template>
    </p-card>
</div>

<div *ngIf="showMemberMenu" class="member-menu">
    <app-member-menu [member]="memberSelected"></app-member-menu>
</div>  

<div *ngIf="showMemberRegister" class="member-menu">
    <app-member-register [idNumber]="idNumberSelected" [idType]="idTypeSelected" (memberCreated)="refreshMemberCreated($event)"></app-member-register>
</div>
