import * as i1 from '@angular/common';
import { isPlatformBrowser, DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, signal, PLATFORM_ID, Component, ChangeDetectionStrategy, ViewEncapsulation, Inject, Input, Output, ViewChild, ContentChildren, NgModule } from '@angular/core';
import * as i5 from '@angular/router';
import { RouterModule } from '@angular/router';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import * as i2 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { DomHandler } from 'primeng/dom';
import { PlusIcon } from 'primeng/icons/plus';
import * as i3 from 'primeng/ripple';
import { RippleModule } from 'primeng/ripple';
import * as i4 from 'primeng/tooltip';
import { TooltipModule } from 'primeng/tooltip';
import { UniqueComponentId } from 'primeng/utils';
import { asapScheduler } from 'rxjs';

/**
 * When pressed, a floating action button can display multiple primary actions that can be performed on a page.
 * @group Components
 */
const _c0 = ["container"];
const _c1 = ["list"];
function SpeedDial_PlusIcon_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "PlusIcon");
  }
}
function SpeedDial_ng_container_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SpeedDial_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, SpeedDial_ng_container_4_ng_container_1_Template, 1, 0, "ng-container", 8);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r2.buttonTemplate);
  }
}
function SpeedDial_li_7_a_1_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 14);
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵproperty("ngClass", item_r7.icon);
  }
}
const _c2 = a0 => ({
  "p-disabled": a0
});
const _c3 = () => ({
  exact: false
});
function SpeedDial_li_7_a_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r16 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 12);
    i0.ɵɵlistener("click", function SpeedDial_li_7_a_1_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r16);
      const item_r7 = i0.ɵɵnextContext().$implicit;
      const ctx_r14 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r14.onItemClick($event, item_r7));
    })("keydown.enter", function SpeedDial_li_7_a_1_Template_a_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r16);
      const ctx_r18 = i0.ɵɵnextContext();
      const item_r7 = ctx_r18.$implicit;
      const i_r8 = ctx_r18.index;
      const ctx_r17 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r17.onItemClick($event, item_r7, i_r8));
    });
    i0.ɵɵtemplate(1, SpeedDial_li_7_a_1_span_1_Template, 1, 1, "span", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext().$implicit;
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵproperty("routerLink", item_r7.routerLink)("queryParams", item_r7.queryParams)("ngClass", i0.ɵɵpureFunction1(15, _c2, item_r7.disabled))("routerLinkActiveOptions", item_r7.routerLinkActiveOptions || i0.ɵɵpureFunction0(17, _c3))("fragment", item_r7.fragment)("queryParamsHandling", item_r7.queryParamsHandling)("preserveFragment", item_r7.preserveFragment)("skipLocationChange", item_r7.skipLocationChange)("replaceUrl", item_r7.replaceUrl)("state", item_r7.state);
    i0.ɵɵattribute("target", item_r7.target)("tabindex", item_r7.disabled || ctx_r9.readonly || !ctx_r9.visible ? null : item_r7.tabindex ? item_r7.tabindex : "0")("aria-label", item_r7.label)("data-pc-section", "action");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.icon);
  }
}
function SpeedDial_li_7_ng_template_2_a_0_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 14);
  }
  if (rf & 2) {
    const item_r7 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵproperty("ngClass", item_r7.icon);
  }
}
function SpeedDial_li_7_ng_template_2_a_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r25 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "a", 16);
    i0.ɵɵlistener("click", function SpeedDial_li_7_ng_template_2_a_0_Template_a_click_0_listener($event) {
      i0.ɵɵrestoreView(_r25);
      const item_r7 = i0.ɵɵnextContext(2).$implicit;
      const ctx_r23 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r23.onItemClick($event, item_r7));
    })("keydown.enter", function SpeedDial_li_7_ng_template_2_a_0_Template_a_keydown_enter_0_listener($event) {
      i0.ɵɵrestoreView(_r25);
      const ctx_r27 = i0.ɵɵnextContext(2);
      const item_r7 = ctx_r27.$implicit;
      const i_r8 = ctx_r27.index;
      const ctx_r26 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r26.onItemClick($event, item_r7, i_r8));
    });
    i0.ɵɵtemplate(1, SpeedDial_li_7_ng_template_2_a_0_span_1_Template, 1, 1, "span", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r28 = i0.ɵɵnextContext(2);
    const item_r7 = ctx_r28.$implicit;
    const i_r8 = ctx_r28.index;
    const ctx_r20 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(7, _c2, item_r7.disabled));
    i0.ɵɵattribute("href", item_r7.url || null, i0.ɵɵsanitizeUrl)("target", item_r7.target)("data-pc-section", "action")("aria-label", item_r7.label)("tabindex", item_r7.disabled || i_r8 !== ctx_r20.activeIndex && ctx_r20.readonly || !ctx_r20.visible ? null : item_r7.tabindex ? item_r7.tabindex : "0");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", item_r7.icon);
  }
}
function SpeedDial_li_7_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SpeedDial_li_7_ng_template_2_a_0_Template, 2, 9, "a", 15);
  }
  if (rf & 2) {
    const ctx_r10 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngIf", ctx_r10._visible);
  }
}
const _c4 = (a0, a1) => ({
  "p-hidden": a0,
  "p-focus": a1
});
function SpeedDial_li_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "li", 9);
    i0.ɵɵtemplate(1, SpeedDial_li_7_a_1_Template, 2, 18, "a", 10)(2, SpeedDial_li_7_ng_template_2_Template, 1, 1, "ng-template", null, 11, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const item_r7 = ctx.$implicit;
    const i_r8 = ctx.index;
    const _r11 = i0.ɵɵreference(3);
    const ctx_r4 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngStyle", ctx_r4.getItemStyle(i_r8))("tooltipOptions", item_r7.tooltipOptions)("ngClass", i0.ɵɵpureFunction2(8, _c4, item_r7.visible === false, ctx_r4.focusedOptionId == ctx_r4.id + "_" + i_r8))("id", ctx_r4.id + "_" + i_r8);
    i0.ɵɵattribute("aria-controls", ctx_r4.id + "_item")("data-pc-section", "menuitem");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r4._visible && ctx_r4.isClickableRouterLink(item_r7))("ngIfElse", _r11);
  }
}
const _c5 = a1 => ({
  "p-speeddial-mask": true,
  "p-speeddial-mask-visible": a1
});
function SpeedDial_div_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 0);
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r5.maskClassName);
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(4, _c5, ctx_r5.visible))("ngStyle", ctx_r5.maskStyle);
  }
}
class SpeedDial {
  platformId;
  el;
  cd;
  document;
  renderer;
  /**
   * List of items id.
   * @group Props
   */
  id;
  /**
   * MenuModel instance to define the action items.
   * @group Props
   */
  model = null;
  /**
   * Specifies the visibility of the overlay.
   * @defaultValue false
   * @group Props
   */
  get visible() {
    return this._visible;
  }
  set visible(value) {
    this._visible = value;
    if (this._visible) {
      this.bindDocumentClickListener();
    } else {
      this.unbindDocumentClickListener();
    }
  }
  /**
   * Inline style of the element.
   * @group Props
   */
  style;
  /**
   * Style class of the element.
   * @group Props
   */
  className;
  /**
   * Specifies the opening direction of actions.
   * @gruop Props
   */
  direction = 'up';
  /**
   * Transition delay step for each action item.
   * @group Props
   */
  transitionDelay = 30;
  /**
   * Specifies the opening type of actions.
   * @group Props
   */
  type = 'linear';
  /**
   * Radius for *circle types.
   * @group Props
   */
  radius = 0;
  /**
   * Whether to show a mask element behind the speeddial.
   * @group Props
   */
  mask = false;
  /**
   * Whether the component is disabled.
   * @group Props
   */
  disabled = false;
  /**
   * Whether the actions close when clicked outside.
   * @group Props
   */
  hideOnClickOutside = true;
  /**
   * Inline style of the button element.
   * @group Props
   */
  buttonStyle;
  /**
   * Style class of the button element.
   * @group Props
   */
  buttonClassName;
  /**
   * Inline style of the mask element.
   * @group Props
   */
  maskStyle;
  /**
   * Style class of the mask element.
   * @group Props
   */
  maskClassName;
  /**
   * Show icon of the button element.
   * @group Props
   */
  showIcon;
  /**
   * Hide icon of the button element.
   * @group Props
   */
  hideIcon;
  /**
   * Defined to rotate showIcon when hideIcon is not present.
   * @group Props
   */
  rotateAnimation = true;
  /**
   * Defines a string value that labels an interactive element.
   * @group Props
   */
  ariaLabel;
  /**
   * Identifier of the underlying input element.
   * @group Props
   */
  ariaLabelledBy;
  /**
   * Fired when the visibility of element changed.
   * @param {boolean} boolean - Visibility value.
   * @group Emits
   */
  onVisibleChange = new EventEmitter();
  /**
   * Fired when the visibility of element changed.
   * @param {boolean} boolean - Visibility value.
   * @group Emits
   */
  visibleChange = new EventEmitter();
  /**
   * Fired when the button element clicked.
   * @param {MouseEvent} event - Mouse event.
   * @group Emits
   */
  onClick = new EventEmitter();
  /**
   * Fired when the actions are visible.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onShow = new EventEmitter();
  /**
   * Fired when the actions are hidden.
   * @param {Event} event - Browser event.
   * @group Emits
   */
  onHide = new EventEmitter();
  container;
  list;
  templates;
  buttonTemplate;
  isItemClicked = false;
  _visible = false;
  documentClickListener;
  focusedOptionIndex = signal(null);
  focused = false;
  get focusedOptionId() {
    return this.focusedOptionIndex() !== -1 ? this.focusedOptionIndex() : null;
  }
  constructor(platformId, el, cd, document, renderer) {
    this.platformId = platformId;
    this.el = el;
    this.cd = cd;
    this.document = document;
    this.renderer = renderer;
  }
  ngOnInit() {
    this.id = this.id || UniqueComponentId();
  }
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.type !== 'linear') {
        const button = DomHandler.findSingle(this.container?.nativeElement, '.p-speeddial-button');
        const firstItem = DomHandler.findSingle(this.list?.nativeElement, '.p-speeddial-item');
        if (button && firstItem) {
          const wDiff = Math.abs(button.offsetWidth - firstItem.offsetWidth);
          const hDiff = Math.abs(button.offsetHeight - firstItem.offsetHeight);
          this.list?.nativeElement.style.setProperty('--item-diff-x', `${wDiff / 2}px`);
          this.list?.nativeElement.style.setProperty('--item-diff-y', `${hDiff / 2}px`);
        }
      }
    }
  }
  ngAfterContentInit() {
    this.templates?.forEach(item => {
      switch (item.getType()) {
        case 'button':
          this.buttonTemplate = item.template;
          break;
      }
    });
  }
  show() {
    this.onVisibleChange.emit(true);
    this.visibleChange.emit(true);
    this._visible = true;
    this.onShow.emit();
    this.bindDocumentClickListener();
    this.cd.markForCheck();
  }
  hide() {
    this.onVisibleChange.emit(false);
    this.visibleChange.emit(false);
    this._visible = false;
    this.onHide.emit();
    this.unbindDocumentClickListener();
    this.cd.markForCheck();
  }
  onButtonClick(event) {
    this.visible ? this.hide() : this.show();
    this.onClick.emit(event);
    this.isItemClicked = true;
  }
  onItemClick(e, item) {
    if (item.command) {
      item.command({
        originalEvent: e,
        item
      });
    }
    this.hide();
    this.isItemClicked = true;
  }
  onKeyDown(event) {
    switch (event.code) {
      case 'ArrowDown':
        this.onArrowDown(event);
        break;
      case 'ArrowUp':
        this.onArrowUp(event);
        break;
      case 'ArrowLeft':
        this.onArrowLeft(event);
        break;
      case 'ArrowRight':
        this.onArrowRight(event);
        break;
      case 'Enter':
      case 'Space':
        this.onEnterKey(event);
        break;
      case 'Escape':
        this.onEscapeKey(event);
        break;
      case 'Home':
        this.onHomeKey(event);
        break;
      case 'End':
        this.onEndKey(event);
        break;
      default:
        break;
    }
  }
  onFocus(event) {
    this.focused = true;
  }
  onBlur(event) {
    this.focused = false;
    asapScheduler.schedule(() => this.focusedOptionIndex.set(-1));
  }
  onArrowUp(event) {
    if (this.direction === 'up') {
      this.navigateNextItem(event);
    } else if (this.direction === 'down') {
      this.navigatePrevItem(event);
    } else {
      this.navigateNextItem(event);
    }
  }
  onArrowDown(event) {
    if (this.direction === 'up') {
      this.navigatePrevItem(event);
    } else if (this.direction === 'down') {
      this.navigateNextItem(event);
    } else {
      this.navigatePrevItem(event);
    }
  }
  onArrowLeft(event) {
    const leftValidDirections = ['left', 'up-right', 'down-left'];
    const rightValidDirections = ['right', 'up-left', 'down-right'];
    if (leftValidDirections.includes(this.direction)) {
      this.navigateNextItem(event);
    } else if (rightValidDirections.includes(this.direction)) {
      this.navigatePrevItem(event);
    } else {
      this.navigatePrevItem(event);
    }
  }
  onArrowRight(event) {
    const leftValidDirections = ['left', 'up-right', 'down-left'];
    const rightValidDirections = ['right', 'up-left', 'down-right'];
    if (leftValidDirections.includes(this.direction)) {
      this.navigatePrevItem(event);
    } else if (rightValidDirections.includes(this.direction)) {
      this.navigateNextItem(event);
    } else {
      this.navigateNextItem(event);
    }
  }
  onEndKey(event) {
    event.preventDefault();
    this.focusedOptionIndex.set(-1);
    this.navigatePrevItem(event);
  }
  onHomeKey(event) {
    event.preventDefault();
    this.focusedOptionIndex.set(-1);
    this.navigateNextItem(event);
  }
  onEnterKey(event) {
    const items = DomHandler.find(this.container.nativeElement, '[data-pc-section="menuitem"]');
    const itemIndex = [...items].findIndex(item => item.id === this.focusedOptionIndex);
    this.onItemClick(event, this.model[itemIndex]);
    this.onBlur(event);
    const buttonEl = DomHandler.findSingle(this.container.nativeElement, 'button');
    buttonEl && DomHandler.focus(buttonEl);
  }
  onEscapeKey(event) {
    this.hide();
    const buttonEl = DomHandler.findSingle(this.container.nativeElement, 'button');
    buttonEl && DomHandler.focus(buttonEl);
  }
  onTogglerKeydown(event) {
    switch (event.code) {
      case 'ArrowDown':
      case 'ArrowLeft':
        this.onTogglerArrowDown(event);
        break;
      case 'ArrowUp':
      case 'ArrowRight':
        this.onTogglerArrowUp(event);
        break;
      case 'Escape':
        this.onEscapeKey(event);
        break;
      default:
        break;
    }
  }
  onTogglerArrowUp(event) {
    this.focused = true;
    DomHandler.focus(this.list.nativeElement);
    this.show();
    this.navigatePrevItem(event);
    event.preventDefault();
  }
  onTogglerArrowDown(event) {
    this.focused = true;
    DomHandler.focus(this.list.nativeElement);
    this.show();
    this.navigateNextItem(event);
    event.preventDefault();
  }
  navigateNextItem(event) {
    const optionIndex = this.findNextOptionIndex(this.focusedOptionIndex());
    this.changeFocusedOptionIndex(optionIndex);
    event.preventDefault();
  }
  navigatePrevItem(event) {
    const optionIndex = this.findPrevOptionIndex(this.focusedOptionIndex());
    this.changeFocusedOptionIndex(optionIndex);
    event.preventDefault();
  }
  findPrevOptionIndex(index) {
    const items = DomHandler.find(this.container.nativeElement, '[data-pc-section="menuitem"]');
    const filteredItems = [...items].filter(item => !DomHandler.hasClass(DomHandler.findSingle(item, 'a'), 'p-disabled'));
    const newIndex = index === -1 ? filteredItems[filteredItems.length - 1].id : index;
    let matchedOptionIndex = filteredItems.findIndex(link => link.getAttribute('id') === newIndex);
    matchedOptionIndex = index === -1 ? filteredItems.length - 1 : matchedOptionIndex - 1;
    return matchedOptionIndex;
  }
  findNextOptionIndex(index) {
    const items = DomHandler.find(this.container.nativeElement, '[data-pc-section="menuitem"]');
    const filteredItems = [...items].filter(item => !DomHandler.hasClass(DomHandler.findSingle(item, 'a'), 'p-disabled'));
    const newIndex = index === -1 ? filteredItems[0].id : index;
    let matchedOptionIndex = filteredItems.findIndex(link => link.getAttribute('id') === newIndex);
    matchedOptionIndex = index === -1 ? 0 : matchedOptionIndex + 1;
    return matchedOptionIndex;
  }
  changeFocusedOptionIndex(index) {
    const items = DomHandler.find(this.container.nativeElement, '[data-pc-section="menuitem"]');
    const filteredItems = [...items].filter(item => !DomHandler.hasClass(DomHandler.findSingle(item, 'a'), 'p-disabled'));
    if (filteredItems[index]) {
      this.focusedOptionIndex.set(filteredItems[index].getAttribute('id'));
    }
  }
  calculatePointStyle(index) {
    const type = this.type;
    if (type !== 'linear') {
      const length = this.model.length;
      const radius = this.radius || length * 20;
      if (type === 'circle') {
        const step = 2 * Math.PI / length;
        return {
          left: `calc(${radius * Math.cos(step * index)}px + var(--item-diff-x, 0px))`,
          top: `calc(${radius * Math.sin(step * index)}px + var(--item-diff-y, 0px))`
        };
      } else if (type === 'semi-circle') {
        const direction = this.direction;
        const step = Math.PI / (length - 1);
        const x = `calc(${radius * Math.cos(step * index)}px + var(--item-diff-x, 0px))`;
        const y = `calc(${radius * Math.sin(step * index)}px + var(--item-diff-y, 0px))`;
        if (direction === 'up') {
          return {
            left: x,
            bottom: y
          };
        } else if (direction === 'down') {
          return {
            left: x,
            top: y
          };
        } else if (direction === 'left') {
          return {
            right: y,
            top: x
          };
        } else if (direction === 'right') {
          return {
            left: y,
            top: x
          };
        }
      } else if (type === 'quarter-circle') {
        const direction = this.direction;
        const step = Math.PI / (2 * (length - 1));
        const x = `calc(${radius * Math.cos(step * index)}px + var(--item-diff-x, 0px))`;
        const y = `calc(${radius * Math.sin(step * index)}px + var(--item-diff-y, 0px))`;
        if (direction === 'up-left') {
          return {
            right: x,
            bottom: y
          };
        } else if (direction === 'up-right') {
          return {
            left: x,
            bottom: y
          };
        } else if (direction === 'down-left') {
          return {
            right: y,
            top: x
          };
        } else if (direction === 'down-right') {
          return {
            left: y,
            top: x
          };
        }
      }
    }
    return {};
  }
  calculateTransitionDelay(index) {
    const length = this.model.length;
    return (this.visible ? index : length - index - 1) * this.transitionDelay;
  }
  containerClass() {
    return {
      ['p-speeddial p-component' + ` p-speeddial-${this.type}`]: true,
      [`p-speeddial-direction-${this.direction}`]: this.type !== 'circle',
      'p-speeddial-opened': this.visible,
      'p-disabled': this.disabled
    };
  }
  buttonClass() {
    return {
      'p-speeddial-button p-button-rounded': true,
      'p-speeddial-rotate': this.rotateAnimation && !this.hideIcon,
      [this.buttonClassName]: true
    };
  }
  get buttonIconClass() {
    return !this.visible && this.showIcon || !this.hideIcon ? this.showIcon : this.hideIcon;
  }
  getItemStyle(index) {
    const transitionDelay = this.calculateTransitionDelay(index);
    const pointStyle = this.calculatePointStyle(index);
    return {
      transitionDelay: `${transitionDelay}ms`,
      ...pointStyle
    };
  }
  isClickableRouterLink(item) {
    return item.routerLink && !this.disabled && !item.disabled;
  }
  isOutsideClicked(event) {
    return this.container && !(this.container.nativeElement.isSameNode(event.target) || this.container.nativeElement.contains(event.target) || this.isItemClicked);
  }
  bindDocumentClickListener() {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.documentClickListener && this.hideOnClickOutside) {
        this.documentClickListener = this.renderer.listen(this.document, 'click', event => {
          if (this.visible && this.isOutsideClicked(event)) {
            this.hide();
          }
          this.isItemClicked = false;
        });
      }
    }
  }
  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }
  ngOnDestroy() {
    this.unbindDocumentClickListener();
  }
  static ɵfac = function SpeedDial_Factory(t) {
    return new (t || SpeedDial)(i0.ɵɵdirectiveInject(PLATFORM_ID), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(i0.Renderer2));
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SpeedDial,
    selectors: [["p-speedDial"]],
    contentQueries: function SpeedDial_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    viewQuery: function SpeedDial_Query(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵviewQuery(_c0, 5);
        i0.ɵɵviewQuery(_c1, 5);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.container = _t.first);
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.list = _t.first);
      }
    },
    hostAttrs: [1, "p-element"],
    inputs: {
      id: "id",
      model: "model",
      visible: "visible",
      style: "style",
      className: "className",
      direction: "direction",
      transitionDelay: "transitionDelay",
      type: "type",
      radius: "radius",
      mask: "mask",
      disabled: "disabled",
      hideOnClickOutside: "hideOnClickOutside",
      buttonStyle: "buttonStyle",
      buttonClassName: "buttonClassName",
      maskStyle: "maskStyle",
      maskClassName: "maskClassName",
      showIcon: "showIcon",
      hideIcon: "hideIcon",
      rotateAnimation: "rotateAnimation",
      ariaLabel: "ariaLabel",
      ariaLabelledBy: "ariaLabelledBy"
    },
    outputs: {
      onVisibleChange: "onVisibleChange",
      visibleChange: "visibleChange",
      onClick: "onClick",
      onShow: "onShow",
      onHide: "onHide"
    },
    decls: 9,
    vars: 25,
    consts: [[3, "ngClass", "ngStyle"], ["container", ""], ["pRipple", "", "pButton", "", 1, "p-button-icon-only", 3, "icon", "ngClass", "disabled", "click", "keydown"], [4, "ngIf"], ["role", "menu", 1, "p-speeddial-list", 3, "id", "tabindex", "focus", "focusout", "keydown"], ["list", ""], ["class", "p-speeddial-item", "pTooltip", "", "role", "menuitem", 3, "ngStyle", "tooltipOptions", "ngClass", "id", 4, "ngFor", "ngForOf"], [3, "ngClass", "class", "ngStyle", 4, "ngIf"], [4, "ngTemplateOutlet"], ["pTooltip", "", "role", "menuitem", 1, "p-speeddial-item", 3, "ngStyle", "tooltipOptions", "ngClass", "id"], ["pRipple", "", "class", "p-speeddial-action", "role", "menuitem", 3, "routerLink", "queryParams", "ngClass", "routerLinkActiveOptions", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "click", "keydown.enter", 4, "ngIf", "ngIfElse"], ["elseBlock", ""], ["pRipple", "", "role", "menuitem", 1, "p-speeddial-action", 3, "routerLink", "queryParams", "ngClass", "routerLinkActiveOptions", "fragment", "queryParamsHandling", "preserveFragment", "skipLocationChange", "replaceUrl", "state", "click", "keydown.enter"], ["class", "p-speeddial-action-icon", 3, "ngClass", 4, "ngIf"], [1, "p-speeddial-action-icon", 3, "ngClass"], ["class", "p-speeddial-action", "role", "menuitem", "pRipple", "", 3, "ngClass", "click", "keydown.enter", 4, "ngIf"], ["role", "menuitem", "pRipple", "", 1, "p-speeddial-action", 3, "ngClass", "click", "keydown.enter"]],
    template: function SpeedDial_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0, 1)(2, "button", 2);
        i0.ɵɵlistener("click", function SpeedDial_Template_button_click_2_listener($event) {
          return ctx.onButtonClick($event);
        })("keydown", function SpeedDial_Template_button_keydown_2_listener($event) {
          return ctx.onTogglerKeydown($event);
        });
        i0.ɵɵtemplate(3, SpeedDial_PlusIcon_3_Template, 1, 0, "PlusIcon", 3)(4, SpeedDial_ng_container_4_Template, 2, 1, "ng-container", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "ul", 4, 5);
        i0.ɵɵlistener("focus", function SpeedDial_Template_ul_focus_5_listener($event) {
          return ctx.onFocus($event);
        })("focusout", function SpeedDial_Template_ul_focusout_5_listener($event) {
          return ctx.onBlur($event);
        })("keydown", function SpeedDial_Template_ul_keydown_5_listener($event) {
          return ctx.onKeyDown($event);
        });
        i0.ɵɵtemplate(7, SpeedDial_li_7_Template, 4, 11, "li", 6);
        i0.ɵɵelementEnd()();
        i0.ɵɵtemplate(8, SpeedDial_div_8_Template, 1, 6, "div", 7);
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.className);
        i0.ɵɵproperty("ngClass", ctx.containerClass())("ngStyle", ctx.style);
        i0.ɵɵattribute("data-pc-name", "speeddial")("data-pc-section", "root");
        i0.ɵɵadvance(2);
        i0.ɵɵstyleMap(ctx.buttonStyle);
        i0.ɵɵproperty("icon", ctx.buttonIconClass)("ngClass", ctx.buttonClass())("disabled", ctx.disabled);
        i0.ɵɵattribute("aria-expanded", ctx.visible)("aria-haspopup", true)("aria-controls", ctx.id + "_list")("aria-label", ctx.ariaLabel)("aria-labelledby", ctx.ariaLabelledBy)("data-pc-name", "button");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", !ctx.showIcon && !ctx.buttonTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.buttonTemplate);
        i0.ɵɵadvance();
        i0.ɵɵproperty("id", ctx.id + "_list")("tabindex", -1);
        i0.ɵɵattribute("aria-activedescendant", ctx.focused ? ctx.focusedOptionId : undefined)("data-pc-section", "menu");
        i0.ɵɵadvance(2);
        i0.ɵɵproperty("ngForOf", ctx.model);
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngIf", ctx.mask && ctx.visible);
      }
    },
    dependencies: () => [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle, i2.ButtonDirective, i3.Ripple, i4.Tooltip, i5.RouterLink, PlusIcon],
    styles: ["@layer primeng{.p-speeddial{position:absolute;display:flex;z-index:1}.p-speeddial-list{margin:0;padding:0;list-style:none;display:flex;align-items:center;justify-content:center;transition:top 0s linear .2s;pointer-events:none}.p-speeddial-item{transform:scale(0);opacity:0;transition:transform .2s cubic-bezier(.4,0,.2,1) 0ms,opacity .8s;will-change:transform}.p-speeddial-action{display:flex;align-items:center;justify-content:center;border-radius:50%;position:relative;overflow:hidden;cursor:pointer}.p-speeddial-circle .p-speeddial-item,.p-speeddial-semi-circle .p-speeddial-item,.p-speeddial-quarter-circle .p-speeddial-item{position:absolute}.p-speeddial-rotate{transition:transform .25s cubic-bezier(.4,0,.2,1) 0ms;will-change:transform}.p-speeddial-mask{position:absolute;left:0;top:0;width:100%;height:100%;opacity:0;transition:opacity .25s cubic-bezier(.25,.8,.25,1)}.p-speeddial-mask-visible{pointer-events:none;opacity:1;transition:opacity .4s cubic-bezier(.25,.8,.25,1)}.p-speeddial-opened .p-speeddial-list{pointer-events:auto}.p-speeddial-opened .p-speeddial-item{transform:scale(1);opacity:1}.p-speeddial-opened .p-speeddial-rotate{transform:rotate(45deg)}.p-speeddial-direction-up{align-items:center;flex-direction:column-reverse}.p-speeddial-direction-up .p-speeddial-list{flex-direction:column-reverse}.p-speeddial-direction-down{align-items:center;flex-direction:column}.p-speeddial-direction-down .p-speeddial-list{flex-direction:column}.p-speeddial-direction-left{justify-content:center;flex-direction:row-reverse}.p-speeddial-direction-left .p-speeddial-list{flex-direction:row-reverse}.p-speeddial-direction-right{justify-content:center;flex-direction:row}.p-speeddial-direction-right .p-speeddial-list{flex-direction:row}}\n"],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpeedDial, [{
    type: Component,
    args: [{
      selector: 'p-speedDial',
      template: `
        <div #container [ngClass]="containerClass()" [class]="className" [ngStyle]="style" [attr.data-pc-name]="'speeddial'" [attr.data-pc-section]="'root'">
            <button
                pRipple
                pButton
                class="p-button-icon-only"
                [style]="buttonStyle"
                [icon]="buttonIconClass"
                [ngClass]="buttonClass()"
                [disabled]="disabled"
                [attr.aria-expanded]="visible"
                [attr.aria-haspopup]="true"
                [attr.aria-controls]="id + '_list'"
                [attr.aria-label]="ariaLabel"
                [attr.aria-labelledby]="ariaLabelledBy"
                (click)="onButtonClick($event)"
                (keydown)="onTogglerKeydown($event)"
                [attr.data-pc-name]="'button'"
            >
                <PlusIcon *ngIf="!showIcon && !buttonTemplate" />
                <ng-container *ngIf="buttonTemplate">
                    <ng-container *ngTemplateOutlet="buttonTemplate"></ng-container>
                </ng-container>
            </button>
            <ul
                #list
                class="p-speeddial-list"
                role="menu"
                [id]="id + '_list'"
                (focus)="onFocus($event)"
                (focusout)="onBlur($event)"
                (keydown)="onKeyDown($event)"
                [attr.aria-activedescendant]="focused ? focusedOptionId : undefined"
                [tabindex]="-1"
                [attr.data-pc-section]="'menu'"
            >
                <li
                    *ngFor="let item of model; let i = index"
                    [ngStyle]="getItemStyle(i)"
                    class="p-speeddial-item"
                    pTooltip
                    [tooltipOptions]="item.tooltipOptions"
                    [ngClass]="{ 'p-hidden': item.visible === false, 'p-focus': focusedOptionId == id + '_' + i }"
                    [id]="id + '_' + i"
                    [attr.aria-controls]="id + '_item'"
                    role="menuitem"
                    [attr.data-pc-section]="'menuitem'"
                >
                    <a
                        *ngIf="_visible && isClickableRouterLink(item); else elseBlock"
                        pRipple
                        [routerLink]="item.routerLink"
                        [queryParams]="item.queryParams"
                        class="p-speeddial-action"
                        [ngClass]="{ 'p-disabled': item.disabled }"
                        role="menuitem"
                        [routerLinkActiveOptions]="item.routerLinkActiveOptions || { exact: false }"
                        (click)="onItemClick($event, item)"
                        (keydown.enter)="onItemClick($event, item, i)"
                        [attr.target]="item.target"
                        [attr.tabindex]="item.disabled || readonly || !visible ? null : item.tabindex ? item.tabindex : '0'"
                        [fragment]="item.fragment"
                        [queryParamsHandling]="item.queryParamsHandling"
                        [preserveFragment]="item.preserveFragment"
                        [skipLocationChange]="item.skipLocationChange"
                        [replaceUrl]="item.replaceUrl"
                        [state]="item.state"
                        [attr.aria-label]="item.label"
                        [attr.data-pc-section]="'action'"
                    >
                        <span class="p-speeddial-action-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
                    </a>
                    <ng-template #elseBlock>
                        <a
                            *ngIf="_visible"
                            [attr.href]="item.url || null"
                            class="p-speeddial-action"
                            role="menuitem"
                            pRipple
                            (click)="onItemClick($event, item)"
                            [ngClass]="{ 'p-disabled': item.disabled }"
                            (keydown.enter)="onItemClick($event, item, i)"
                            [attr.target]="item.target"
                            [attr.data-pc-section]="'action'"
                            [attr.aria-label]="item.label"
                            [attr.tabindex]="item.disabled || (i !== activeIndex && readonly) || !visible ? null : item.tabindex ? item.tabindex : '0'"
                        >
                            <span class="p-speeddial-action-icon" *ngIf="item.icon" [ngClass]="item.icon"></span>
                        </a>
                    </ng-template>
                </li>
            </ul>
        </div>
        <div *ngIf="mask && visible" [ngClass]="{ 'p-speeddial-mask': true, 'p-speeddial-mask-visible': visible }" [class]="maskClassName" [ngStyle]="maskStyle"></div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      host: {
        class: 'p-element'
      },
      styles: ["@layer primeng{.p-speeddial{position:absolute;display:flex;z-index:1}.p-speeddial-list{margin:0;padding:0;list-style:none;display:flex;align-items:center;justify-content:center;transition:top 0s linear .2s;pointer-events:none}.p-speeddial-item{transform:scale(0);opacity:0;transition:transform .2s cubic-bezier(.4,0,.2,1) 0ms,opacity .8s;will-change:transform}.p-speeddial-action{display:flex;align-items:center;justify-content:center;border-radius:50%;position:relative;overflow:hidden;cursor:pointer}.p-speeddial-circle .p-speeddial-item,.p-speeddial-semi-circle .p-speeddial-item,.p-speeddial-quarter-circle .p-speeddial-item{position:absolute}.p-speeddial-rotate{transition:transform .25s cubic-bezier(.4,0,.2,1) 0ms;will-change:transform}.p-speeddial-mask{position:absolute;left:0;top:0;width:100%;height:100%;opacity:0;transition:opacity .25s cubic-bezier(.25,.8,.25,1)}.p-speeddial-mask-visible{pointer-events:none;opacity:1;transition:opacity .4s cubic-bezier(.25,.8,.25,1)}.p-speeddial-opened .p-speeddial-list{pointer-events:auto}.p-speeddial-opened .p-speeddial-item{transform:scale(1);opacity:1}.p-speeddial-opened .p-speeddial-rotate{transform:rotate(45deg)}.p-speeddial-direction-up{align-items:center;flex-direction:column-reverse}.p-speeddial-direction-up .p-speeddial-list{flex-direction:column-reverse}.p-speeddial-direction-down{align-items:center;flex-direction:column}.p-speeddial-direction-down .p-speeddial-list{flex-direction:column}.p-speeddial-direction-left{justify-content:center;flex-direction:row-reverse}.p-speeddial-direction-left .p-speeddial-list{flex-direction:row-reverse}.p-speeddial-direction-right{justify-content:center;flex-direction:row}.p-speeddial-direction-right .p-speeddial-list{flex-direction:row}}\n"]
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [PLATFORM_ID]
    }]
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.Renderer2
  }], {
    id: [{
      type: Input
    }],
    model: [{
      type: Input
    }],
    visible: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    className: [{
      type: Input
    }],
    direction: [{
      type: Input
    }],
    transitionDelay: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    radius: [{
      type: Input
    }],
    mask: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    hideOnClickOutside: [{
      type: Input
    }],
    buttonStyle: [{
      type: Input
    }],
    buttonClassName: [{
      type: Input
    }],
    maskStyle: [{
      type: Input
    }],
    maskClassName: [{
      type: Input
    }],
    showIcon: [{
      type: Input
    }],
    hideIcon: [{
      type: Input
    }],
    rotateAnimation: [{
      type: Input
    }],
    ariaLabel: [{
      type: Input
    }],
    ariaLabelledBy: [{
      type: Input
    }],
    onVisibleChange: [{
      type: Output
    }],
    visibleChange: [{
      type: Output
    }],
    onClick: [{
      type: Output
    }],
    onShow: [{
      type: Output
    }],
    onHide: [{
      type: Output
    }],
    container: [{
      type: ViewChild,
      args: ['container']
    }],
    list: [{
      type: ViewChild,
      args: ['list']
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class SpeedDialModule {
  static ɵfac = function SpeedDialModule_Factory(t) {
    return new (t || SpeedDialModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: SpeedDialModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, ButtonModule, RippleModule, TooltipModule, RouterModule, PlusIcon, SharedModule, ButtonModule, TooltipModule, RouterModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SpeedDialModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ButtonModule, RippleModule, TooltipModule, RouterModule, PlusIcon],
      exports: [SpeedDial, SharedModule, ButtonModule, TooltipModule, RouterModule],
      declarations: [SpeedDial]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { SpeedDial, SpeedDialModule };
