import { Injectable } from '@angular/core';
import { CallHttpService } from './call-http.service';
import { AdministratorEntity } from '../model/administrator/AdministratorEntity';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GestionService {

  constructor(private callHttpService: CallHttpService) { }
  sendRequest(entity:AdministratorEntity):Observable<any>{
    let service = entity.type;
    return this.callHttpService.post(`${environment.academyApi.gestion}/${service}`,entity);
  }
}
