import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import {
  AcademyQualification,
  AcademyState,
} from '../../../../model/AcademyState';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { FOTO_GUILLO } from '../../../../model/const/const';
import { Member } from '../../../../model/member';
import { DocumentsService } from '../../../../services/documents.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { InstructorSeasonsMemberQualificationComponent } from '../../../instructor/instructor-seasons-member-qualification/instructor-seasons-member-qualification.component';
import { PageHeaderComponent } from '../../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../../shared/tables/crud-table/crud-table.component';
import { MemberEditMembershipComponent } from '../member-edit-membership/member-edit-membership.component';
@Component({
  selector: 'app-member-courses',
  standalone: true,
  templateUrl: './member-courses.component.html',
  styleUrl: './member-courses.component.scss',
  providers: [MessageService, ConfirmationService],
  imports: [
    TableModule,
    TagModule,
    CommonModule,
    ButtonModule,
    MemberEditMembershipComponent,
    SplitButtonModule,
    ToastModule,
    MenuModule,
    TooltipModule,
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    TableModule,
    ToolbarModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    TagModule,
    SplitButtonModule,
    InstructorSeasonsMemberQualificationComponent,
    TooltipModule,
  ],
})
export class MemberCoursesComponent implements OnInit {
  constructor(
    private documentsService: DocumentsService,
    private sanitizer: DomSanitizer,
    private loadingService: LoadingService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private gestionService: GestionService
  ) {}

  @Input()
  member!: Member;

  @Input()
  membershipsDatail: MembershipDetail[] = [];

  @Output()
  membershipIsUpdated: EventEmitter<any> = new EventEmitter();

  showCourses: boolean = false;
  showEditMembership: boolean = false;

  membershipSelected!: MembershipDetail;
  itemsEditMenu: MenuItem[] | undefined;
  documentPreviewSelected: any = null;
  showDocumentPreview: boolean = false;
  ngOnInit(): void {
    if (this.membershipsDatail.length > 0) {
      this.showCourses = true;
    }

    this.itemsEditMenu = [
      {
        label: 'Matricula',
        items: [
          {
            label: 'Editar',
            icon: 'pi pi-pencil',
            command: (event) => {},
          },
        ],
      },
      {
        label: 'Carnet',
        items: [
          {
            label: 'Reenviar Digital',
            icon: 'pi pi-send',
            command: () => {},
          },
          {
            label: 'Generar Físico',
            icon: 'pi pi-print',
            command: () => {},
          },
        ],
      },
      {
        label: 'Soporte',
        items: [
          {
            label: 'Generar Soporte',
            icon: 'pi pi-print',
            command: () => {},
          },
          {
            label: 'Reenviar Digital',
            icon: 'pi pi-send',
            command: () => {},
          },
        ],
      },
    ];
  }

  getSeverity(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'info';
      case AcademyQualification.APPROVED:
        return 'success';
      case AcademyQualification.FAILED:
        return 'danger';
      case AcademyQualification.PENDING:
        return 'warning';
      default:
        return 'warning';
    }
  }

  getStatus(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'En curso';
      case AcademyQualification.APPROVED:
        return 'Aprobado';
      case AcademyQualification.FAILED:
        return 'No Aprobado';
      case AcademyQualification.PENDING:
        return 'Sin Iniciar';
      default:
        return status.state;
    }
  }

  initEdit(item: MembershipDetail) {
    this.membershipSelected = item;
    this.showEditMembership = true;
  }

  initDelete(item: MembershipDetail) {}

  closeEditDialog() {
    this.membershipSelected = this.membershipsDatail[0];
    this.showEditMembership = false;
  }

  updateCourseHistory() {
    this.membershipIsUpdated.emit();
  }

  showPreview(membership: Membership) {
    if (membership.administrativeData.transactionDocId) {
      this.loadingService.showLoading();
      this.documentsService
        .getDocument(membership.administrativeData.transactionDocId)
        .subscribe((res: HttpResponse) => {
          this.loadingService.hideLoading();

          if (res.response.id) {
            this.documentPreviewSelected = {
              documentName: 'Comprobante de Transacción',
              base64: this.sanitizer.bypassSecurityTrustResourceUrl(
                res.response.filestream
              ),
            };
            this.showDocumentPreview = true;
          } else {
            this.documentPreviewSelected = null;
            this.showDocumentPreview = false;

            this.messageService.add({
              severity: 'warn',
              summary: 'Sin Comprobante',
              detail: 'No se ha encontrado comprobante de transacción',
              life: 3000,
            });
          }
        });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'No se ha encontrado identificador de transacción para esta matricula',
        life: 3000,
      });
    }
  }

  showDigitalLicense(membership: MembershipDetail) {
    if (membership.member.digitalLicense?.generated) {
      this.loadingService.showLoading();
      this.documentsService
        .getDocument(membership.member.digitalLicense.documentId)
        .subscribe((res: HttpResponse) => {
          this.loadingService.hideLoading();

          if (res.response.id) {
            this.documentPreviewSelected = {
              documentName: `Carnet Digital ${membership.member.memberId}`,
              base64: this.sanitizer.bypassSecurityTrustResourceUrl(
                res.response.filestream
              ),
            };
            this.showDocumentPreview = true;
          } else {
            this.documentPreviewSelected = null;
            this.showDocumentPreview = false;

            this.messageService.add({
              severity: 'warn',
              summary: 'Sin Carnet Digital',
              detail: 'No se ha encontrado carnet Digital',
              life: 3000,
            });
          }
        });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Procesando Carnet Digital',
        detail: 'El Carnet Digital aun esta en proceso de generación puede tardar unos 2 minutos Aproximadamente',
        life: 3000,
      });
    }
  }

  resendInformation(membership: MembershipDetail) {
    this.member.digitalLicense = {
      documentId: this.member.digitalLicense?.documentId || '',
      generated: false,
      isMigratedMember: false,
    };

    this.member.picture = this.member.picture || FOTO_GUILLO;

    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: this.member,
      type: AcademyAdministratorType.MEMBERS,
    };

    this.confirmationService.confirm({
      message: `¿Desea reenviar el Carnet Digital y la información de la matricula al correo ${this.member.contactInformation.email}?`,
      header: 'Confirmación de Reenvío',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        this.gestionService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            this.loadingService.hideLoading();
            if (res.response) {
              this.getMembershipInformation(membership)
              this.messageService.add({
                severity: 'success',
                summary: 'Carnet Digital Reenviado',
                detail: 'El carnet digital se ha reenviado correctamente',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'No se ha podido reenviar la información de la matricula',
                life: 3000,
              });
            }
          });
      },
    });
  }

  getMembershipInformation(membership: MembershipDetail) {
    this.loadingService.showLoading();
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options:{
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'id',
              parameterName: '@Id',
              parameterValue: membership.id,
            }
          ]
        }
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      this.loadingService.hideLoading();
      if (res.response) {
        this.updateMembershipWelcomeMail(res.response[0] as Membership);
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se ha podido reenviar la información de la matricula',
          life: 3000,
        });
      }
    });
  }

  updateMembershipWelcomeMail(membership: Membership) {
    this.loadingService.showLoading();

    console.log("Membership", membership);
    
    membership.comunications!.welcome = false;

    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: membership,
      type: AcademyAdministratorType.MEMBERSHIPS,
    };

    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      this.loadingService.hideLoading();
      if (res.response) {
        this.messageService.add({
          severity: 'success',
          summary: 'Información de Matricula Reenviada',
          detail: 'La información de la matricula se ha reenviado correctamente',
          life: 3000,
        });
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'No se ha podido reenviar la información de la matricula',
          life: 3000,
        });
      }
    });
  }
}
