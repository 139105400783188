import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ImageModule } from 'primeng/image';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import {
  AcademyQualification,
  AcademyState,
} from '../../../../model/AcademyState';
import { ClassGroup, ClassGroupDetail } from '../../../../model/ClassGroup';
import { Course } from '../../../../model/Course';
import { Headquarters } from '../../../../model/Headquarter';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Instructor } from '../../../../model/Instructor';
import { Level } from '../../../../model/Level';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { Sch } from '../../../../model/Sch';
import { Season } from '../../../../model/Season';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { Member } from '../../../../model/member';
import { AdministrationService } from '../../../../services/administration.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { MemberCoursesComponent } from '../../../gestor/matriculas/member-courses/member-courses.component';
import { MemberNewMembershipComponent } from '../../../gestor/matriculas/member-new-membership/member-new-membership.component';
import { MemberInformationComponent } from '../member-information/member-information.component';
@Component({
  selector: 'app-checkin-memberships',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  imports: [
    ImageModule,
    CardModule,
    CommonModule,
    TabViewModule,
    MemberInformationComponent,
    MemberCoursesComponent,
    MemberNewMembershipComponent,
    ButtonModule,
    ConfirmDialogModule,
    ToastModule,
  ],
  templateUrl: './checkin-memberships.component.html',
  styleUrl: './checkin-memberships.component.scss',
})
export class CheckinMembershipsComponent implements OnInit {
  @Input()
  member!: Member;

  @Input()
  season!: Season;

  @Input()
  headquarter!: Headquarters;

  @Output()
  checkinFinished:EventEmitter<any> = new EventEmitter();

  memberships: Membership[] = [];
  membershipsDatail: MembershipDetail[] = [];
  showMemberships: boolean = false;
  showMemberInfo: boolean = false;
  showNotFoundItems: boolean = false;
  activeIndex: number = 0;
  userName: string = '';
  showCheckinSuccess: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private gestionService: GestionService,
    private administrationService: AdministrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.selectMemberships();
    this.userName =
      this.msalService.instance.getActiveAccount()?.idTokenClaims?.name || '';
  }

  async selectMemberships() {
    this.memberships = [];
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: 'AND',
              logicOperator: '=',
              evaluatedProperty: 'membershipId',
              parameterName: '@Option1',
              parameterValue: this.member.memberId,
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '=',
              evaluatedProperty: 'headquarterId',
              parameterName: '@Option2',
              parameterValue: this.headquarter.id,
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '=',
              evaluatedProperty: 'seasonId',
              parameterName: '@Option3',
              parameterValue: this.season.id,
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '<>',
              evaluatedProperty: 'academyState.state',
              parameterName: '@Option4',
              parameterValue: AcademyQualification.APPROVED,
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '<>',
              evaluatedProperty: 'academyState.state',
              parameterName: '@Option5',
              parameterValue: AcademyQualification.FAILED,
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '<>',
              evaluatedProperty: 'academyState.state',
              parameterName: '@Option6',
              parameterValue: AcademyQualification.CANCELED,
            },
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      this.memberships = res.response as Membership[];
      if (this.memberships.length > 0) {
        this.mapperMemberships();
      } else {
        this.showNotFoundItems = true;
        this.showMemberships = false;
      }

      this.loadingService.hideLoading();
    });
  }

  async mapperMemberships() {
    this.membershipsDatail = [];
    for await (const membership of this.memberships) {
      const classGroup: ClassGroup = await this.getMembeshipGroup(membership);
      let classGroupDetail: ClassGroupDetail = {
        ...classGroup,
        sch: await this.getMembeshipSch(classGroup),
        instructor: await this.getInstructor(classGroup),
      };
      const course: Course = await this.getMembeshipCourse(classGroup);
      const membershipDetail: MembershipDetail = {
        classGroup: classGroupDetail,
        headquarter: await this.getMembeshipHeadquarter(classGroup),
        course: course,
        level: course.levels.find((x) => x.id === classGroup.levelId),
        season: await this.getMembeshipSeason(classGroup),
        id: membership.id,
        member: this.member,
        academyState: membership.academyState,
        administrativeData: membership.administrativeData,
        auditData: membership.auditData,
        membershipId: membership.membershipId,
        state: membership.state,
      };

      this.membershipsDatail.push(membershipDetail);
    }
    this.showMemberships = true;
  }

  async getMembeshipGroup(membership: Membership): Promise<ClassGroup> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.GROUPS,
      membership.classGroupId,
      'id'
    );
    return groupData[0] as ClassGroup;
  }

  async getInstructor(classGroup: ClassGroup): Promise<Instructor> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.INSTRUCTORS,
      classGroup.instructorId,
      'id'
    );
    return groupData[0] as Instructor;
  }

  async getMembeshipSch(classGroup: ClassGroup): Promise<Sch> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SCHS,
      classGroup.schId,
      'id'
    );
    return groupData[0] as Sch;
  }

  async getMembeshipHeadquarter(classGroup: ClassGroup): Promise<Headquarters> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.HEADQUARTERS,
      classGroup.headquarterId,
      'id'
    );
    return groupData[0] as Headquarters;
  }

  async getMembeshipCourse(classGroup: ClassGroup): Promise<Course> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.COURSES_LEVELS,
      classGroup.courseId,
      'id'
    );
    return groupData[0] as Course;
  }

  getMembeshipLevel(course: Course, levelId: string): any {
    return course.levels.find((levelFind: Level) => levelFind.id === levelId);
  }

  async getMembeshipSeason(classGroup: ClassGroup): Promise<Season> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SEASONS,
      classGroup.seasonId,
      'id'
    );
    return groupData[0] as Season;
  }

  async getEntity(
    academyAdministratorType: AcademyAdministratorType,
    value: any,
    property?: string
  ): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();

      let data: any[] = [];
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: property || 'id',
                parameterName: '@Option1',
                parameterValue: value,
              },
            ],
          },
        },
        type: academyAdministratorType,
      };
      try {
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.response.length > 0) {
              data = res.response as any[];
            }
            resolve(data);
            this.loadingService.hideLoading();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  changeTab(tabNumber: number) {
    this.activeIndex = tabNumber;
  }

  async reloadMemberships() {
    this.showMemberInfo = false;
    await this.selectMemberships();
    this.activeIndex = 1;
  }

  getStatus(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'En curso';
      case AcademyQualification.APPROVED:
        return 'Aprobado';
      case AcademyQualification.FAILED:
        return 'No Aprobado';
      case AcademyQualification.PENDING:
        return 'Sin Iniciar';
      default:
        return status.state;
    }
  }

  onMembershipCheckin(membership: MembershipDetail) {
    if (this.validateCheckinonSameDay(membership)) {
      this.confirmationService.confirm({
        message: `Ya realizó un registro de ingreso el dia de hoy para ${membership.member.name} ${membership.member.lastName} en el mismo grupo, ¿esta seguro de registrar un ingreso nuevamente?`,
        header: 'Confirmar',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        acceptButtonStyleClass: 'p-button-outlined p-button-sm',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.updateMemberShip(membership);
        },
      });
    }else{
      this.confirmationService.confirm({
        message: `¿Estas seguro de registrar el ingreso a clase para ${membership.member.name} ${membership.member.lastName}?`,
        header: 'Confirmar',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        rejectButtonStyleClass: 'p-button-outlined p-button-sm',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.updateMemberShip(membership);
        },
      });
    }

  }

  async updateMemberShip(membership: MembershipDetail) {
    this.loadingService.showLoading();
    const item = this.createMembershipItem(membership);
    if(item.academyState.attendances.length <= 0){
      item.academyState.state = AcademyQualification.IN_PROGRESS;
    }
    item.academyState.attendances.push({
      date: moment().format('YYYY-MM-DD HH:mm:ss'),
      headquarterId: this.headquarter.id!,
      registerBy: this.userName,
    });

    // Validar funcionalidad: Cambiar a inactivo cuando complete todas las clases
    // if(item.academyState.attendances.length >= membership.level!.clases){
    //   item.state.state = 'Inactive'
    // }

    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: item,
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.code === 200) {
        this.messageService.add({
          severity: 'success',
          summary: 'OK',
          detail: 'Se registró el ingreso correctamente!',
          life: 3000,
        });
        this.showCheckinSuccess = true;
        this.showMemberships = false;
        setTimeout(()=>{
          this.checkinFinished.emit(membership);
        },2000)
      } else {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'Ocurrió un error en el registro de ingreso, inténtelo nuevamente; si el error persiste contacte el administrador del sistema',
          life: 3000,
        });
      }
      this.loadingService.hideLoading();
    });
  }

  createMembershipItem(membershipDetail: MembershipDetail) {
    let item: Membership = {
      id: membershipDetail.id,
      memberId: membershipDetail.member.memberId,
      membershipId: membershipDetail.membershipId,
      headquarterId: membershipDetail.headquarter.id!,
      classGroupId: membershipDetail.classGroup.id!,
      courseId: membershipDetail.course.id!,
      levelId: membershipDetail.level?.id!,
      seasonId: membershipDetail.season.id!,
      academyState: {
        attendances: membershipDetail.academyState.attendances,
        state: membershipDetail.academyState.state,
      },
      administrativeData: membershipDetail.administrativeData,
      auditData: membershipDetail.auditData,
      state: membershipDetail.state,
      comunications: membershipDetail.comunications!,
    };

    return item;
  }

  validateCheckinonSameDay(membership: MembershipDetail): boolean {
    let hasOtherAttendenceOnSameDay = false;

    for (let attendence of membership.academyState.attendances) {
      let difference = moment(attendence.date).diff(
        moment().format('YYYY-MM-DD HH:mm:ss'),
        'days'
      );
      if(difference === 0){
          hasOtherAttendenceOnSameDay = true;
       }
    }

    return hasOtherAttendenceOnSameDay;
  }

  onMembershipNotFound(){
    this.checkinFinished.emit();
  }
}
