import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { Application } from '../../../../model/Application';
import { HttpResponse } from '../../../../model/HttpResponse';
import { QueryOptionDto } from '../../../../model/QueryOptionDto';
import { ApplicationService } from '../../../../services/application.service';
import { LoadingService } from '../../../../services/loading.service';
@Component({
  selector: 'app-solicitudes-table',
  standalone: true,
  imports: [
    TableModule,
    InputNumberModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    MessagesModule,
    ToastModule,
    ToolbarModule,
    FileUploadModule,
    ButtonModule,
    InputTextModule,
    ConfirmDialogModule,
    DataViewModule,
    TagModule,
    SelectButtonModule,
  ],
  providers: [MessageService, ConfirmationService],
  templateUrl: './solicitudes-table.component.html',
  styleUrl: './solicitudes-table.component.scss',
})
export class SolicitudesTableComponent implements OnInit {
  productDialog: boolean = false;

  @Input()
  applications!: Application[];

  application!: Application | null;

  selectedApplications!: Application[] | null;

  submitted: boolean = false;

  statuses!: any[];

  viewOptions: any[] = [
    { label: 'Nuevas Solicitudes', value: 'new' },
    { label: 'Todas las Solicitudes', value: 'all' },
  ];

  viewSelected: any = null;
  @Output()
  reviewApplication: EventEmitter<Application> = new EventEmitter();

  constructor(
    private applicationService: ApplicationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private loadingServices: LoadingService
  ) {}

  ngOnInit() {
    this.statuses = [
      { label: 'INSTOCK', value: 'instock' },
      { label: 'LOWSTOCK', value: 'lowstock' },
      { label: 'OUTOFSTOCK', value: 'outofstock' },
    ];

    this.viewSelected = 'new';
    this.getNewApplications();
  }

  getDataOfView() {
    switch (this.viewSelected) {
      case 'new':
        this.getNewApplications();
        break;

      default:
        this.getApplications();
        break;
    }
  }

  getApplications() {
    this.loadingServices.showLoading();
    const queryOptions: QueryOptionDto = {
      options: {
        conditionals: [
          {
            conditionalConnector: 'AND',
            evaluatedProperty: 'process.processStage',
            logicOperator: '<>',
            parameterName: '@Queued',
            parameterValue: 'created',
          },
        ],
      },
    };
    this.applicationService
      .getApplications(queryOptions)
      .subscribe((res: HttpResponse) => {
        this.applications = res.response || [];
        this.loadingServices.hideLoading();
      });
  }

  getNewApplications() {
    this.loadingServices.showLoading();
    const queryOptions: QueryOptionDto = {
      options: {
        conditionals: [
          {
            conditionalConnector: 'OR',
            evaluatedProperty: 'process.processStage',
            logicOperator: '=',
            parameterName: '@Created',
            parameterValue: 'created',
          },
        ],
      },
    };
    this.applicationService
      .getApplications(queryOptions)
      .subscribe((res: HttpResponse) => {
        this.applications = res.response || [];
        this.loadingServices.hideLoading();
      });
  }

  openAll() {}

  deleteSelectedProducts() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected products?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //   this.application = this.applications.filter((val) => !this.selectedProducts?.includes(val));
        //   this.selectedProducts = null;
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Products Deleted',
          life: 3000,
        });
      },
    });
  }

  initReviewApplication(application: Application) {
    this.reviewApplication.emit(application);
  }

  deleteProduct(application: Application) {
    this.confirmationService.confirm({
      message:
        'Are you sure you want to delete ' + application.applicant.name + '?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        //   this.products = this.products.filter((val) => val.id !== product.id);
        //   this.product = {};
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Product Deleted',
          life: 3000,
        });
      },
    });
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  saveProduct() {
    this.submitted = true;

    //   if (this.product.name?.trim()) {
    //       if (this.product.id) {
    //           this.products[this.findIndexById(this.product.id)] = this.product;
    //           this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
    //       } else {
    //           this.product.id = this.createId();
    //           this.product.image = 'product-placeholder.svg';
    //           this.products.push(this.product);
    //           this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
    //       }

    //       this.products = [...this.products];
    //       this.productDialog = false;
    //       this.product = {};
    //   }
  }

  findIndexById(id: string): number {
    let index = -1;
    for (let i = 0; i < this.applications.length; i++) {
      if (this.applications[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  }

  createId(): string {
    let id = '';
    var chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }

  getSeverity(status: string) {
    switch (status) {
      case 'created':
        return 'PENDIENTE REVISIÓN';
      case 'approved':
        return 'APROBADA';
      case 'responsed':
        return 'RESPUESTA ENVIADA';
      case 'responsed_rejected':
        return 'RESPUESTA RECHAZADA ENVIADA';
      case 'responsed_approved':
        return 'RESPUESTA APROBADA ENVIADA';
      case 'rejected':
        return 'RECHAZADA';
      case 'finished':
        return 'FINALIZADA';
      case 'payment_started':
        return 'EN PROCESO DE PAGO';
      case 'finished_approved':
        return 'FINALIZADO CON MATRICULA';
      case 'finished_declined':
        return 'FINALIZADO PAGO DECLINADO';
      case 'finished_error':
        return 'FINALIZADO ERROR PAGO';
      case 'finished_payment_rejected':
        return 'FINALIZADO PAGO RECHAZADO';
      default:
        return 'danger';
    }
  }

  getHumanTime(time: number) {
    return moment(time).format('DD/MM/yyyy hh:mm:ss a');
  }

  clear(table: Table) {
    table.clear();
  }

}
