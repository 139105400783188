<app-page-header
  [title]="'Check-in Alumnos'"
  [subtitle]="'Módulo de gestión de ingresos a clase de los alumnos'"
></app-page-header>
<div class="season-headquarter-selects">
  <div *ngIf="showHeadquarters" class="headquarter-select">
    <label for="headquarter">Seleccione una Sede*</label>
    <p-dropdown
      [style]="{ display: 'flex' }"
      [ngModel]="headquarterSelected"
      [options]="headquarters"
      optionLabel="name"
      placeholder="Seleccione una sede"
      [id]="'id'"
      (onChange)="onHeadquarterChange($event.value)"
    ></p-dropdown>
  </div>
  <div *ngIf="showSeasons" class="season-select">
    <label for="season">Seleccione un Ciclo*</label>
    <p-dropdown
      [style]="{ display: 'flex' }"
      [(ngModel)]="seasonSelected"
      [options]="seasons"
      optionLabel="name"
      placeholder="Seleccione un ciclo"
      [id]="'id'"
      (onChange)="onSeasonSelectChange($event.value)"
    ></p-dropdown>
  </div>
 
  <div *ngIf="seasonSelected" class="speeddial-tooltip-demo">
    <p-speedDial 
        [model]="items" 
        className="speeddial-right" 
        direction="up" 
        showIcon="pi pi-arrow-right-arrow-left" 
        hideIcon="pi pi-times" 

        />
</div>
</div>

<div *ngIf="showManualSearch" class="text-center">
  <div class="text-700 mb-1">
    Ingresa la información de la identificación del alumno.
  </div>
  <div class="search-inputs">
    <p-dropdown
      class="m-2 w-2"
      [options]="idTypes"
      [(ngModel)]="idTypeSelected"
      optionLabel="name"
      optionValue="value"
      placeholder="Seleccione tipo de identificación"
    ></p-dropdown>
    <p-inputNumber
      class="m-2"
      [(ngModel)]="idNumberSelected"
      mode="decimal"
      inputId="withoutgrouping"
      [useGrouping]="false"
      placeholder="numero de identificación del alumno"
      [allowEmpty]="true"
      (keydown.enter)="onManaualSearch()"
      autocomplete="off"
    >
    </p-inputNumber>
    <button
      type="button"
      pButton
      icon="pi pi-search"
      class="m-2 p-button-primary"
      (click)="onManaualSearch()"
    ></button>
  </div>
</div>

<div *ngIf="seasonSelected">
  <div *ngIf="showQRScanner" class="checkin-group">
    <div class="qr-scan">
      <label for="address">Dispositivo de captura de imagen*</label>
      <p-dropdown
        [style]="{ display: 'flex' }"
        [(ngModel)]="currentDevice"
        [options]="availableDevices"
        optionLabel="label"
        optionValue="deviceId"
        placeholder="Seleccione el dispositivo"
        [id]="'id'"
        (onChange)="onDeviceSelectChange($event.value)"
      ></p-dropdown>
      <zxing-scanner
        [enable]="scannerEnable"
        [device]="currentDevice"
        [delayBetweenScanSuccess]="1000"
        (scanSuccess)="onCodeResult($event)"
        [formats]="formatsEnabled"
        [tryHarder]="tryHarder"
        (permissionResponse)="onHasPermission($event)"
        (camerasFound)="onCamerasFound($event)"
        (torchCompatible)="onTorchCompatible($event)"
      ></zxing-scanner>
    </div>
  </div>

  <div *ngIf="showMemberMenu" class="member-resumen">
    <p-panel [toggleable]="true" toggler="header" [collapsed]="true">
      <ng-template pTemplate="header">
        <div class="flex align-items-center gap-2">
          <p-avatar
            size="xlarge"
            [image]="
              member.picture && member.picture.length > 0
                ? member.picture
                : 'https://images.prismic.io/lipatinpdn/45174b3e-e9e7-4672-95bc-c554c511f14e_Logo+Liga+de+Patinaje+de+Antioquia_Mesa+de+trabajo+1.png?auto=compress,format'
            "
            [styleClass]="'avatar'"
            shape="circle"
          />
          <span class="text-900 font-medium text-xl">{{
            member.name + " " + member.lastName
          }}</span>
          <span class="text-500 font-medium">{{
            member.idType + " " + member.idNumber
          }}</span>
        </div>
      </ng-template>
      <span class="p-text-secondary">Información Personal</span>
      <div>
        <span class="member-detail">
          <i class="pi pi-user"></i>
          {{ calculateYearsOld(member.birthDay) }} Años
        </span>
        <span>
          <i class="pi pi-user"></i>
          Fecha de Nacimiento: {{ member.birthDay | date }}
        </span>

        <span class="member-detail">
          <i class="pi pi-user"></i>
          Lugar de Nacimiento: {{ member.birthPlace || "No Registra" }}
        </span>

        <span class="member-detail">
          <i class="pi pi-user"></i>
          Género: {{ member.gender }}
        </span>
      </div>
      <p-divider></p-divider>
      <span class="p-text-secondary">Información de contacto</span>
      <div>
        <span class="member-detail">
          <i class="pi pi-user"></i>
          Email: {{ member.contactInformation.email }}
        </span>

        <span class="member-detail">
          <i class="pi pi-user"></i>
          Celular: {{ member.contactInformation.mobile }}
        </span>

        <span class="member-detail">
          <i class="pi pi-user"></i>
          Dirección: {{ member.contactInformation.address }}
          {{ member.contactInformation.neighborhood }}
        </span>
      </div>
      <p-divider></p-divider>
      <span class="p-text-secondary">Información Emergencias</span>
      <div>
        <span class="member-detail">
          <i class="pi pi-user"></i>
          Grupo Sanguíneo: {{ member.assistanceInformation.bloodType }}
        </span>
        <span class="member-detail">
          <i class="pi pi-user"></i>
          Contacto Emergencia:
          {{ member.assistanceInformation.emergencyContactName }}
          {{ member.assistanceInformation.emergencyContactNumber }}
        </span>
        <span class="member-detail">
          <i class="pi pi-user"></i>
          Eps/Aseguradora: {{ member.assistanceInformation.insurance }}
        </span>
      </div>
    </p-panel>
  </div>
</div>

<div *ngIf="showMemberships">
  <app-checkin-memberships
    [member]="member"
    [headquarter]="headquarterSelected"
    [season]="seasonSelected"
    (checkinFinished)="finishMemberCheckIn($event)"
  ></app-checkin-memberships>
</div>
