<p-panel [toggleable]="true"  [collapsed]="isCollapsed" expandIcon="pi pi-angle-double-up" collapseIcon="pi pi-angle-double-down" >
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <p-avatar
        size="xlarge"
        [image]="
          member.picture && member.picture.length > 0
            ? member.picture
            : 'https://images.prismic.io/lipatinpdn/45174b3e-e9e7-4672-95bc-c554c511f14e_Logo+Liga+de+Patinaje+de+Antioquia_Mesa+de+trabajo+1.png?auto=compress,format'
        "
        [styleClass]="'avatar'"
        shape="circle"
      />
      <span class="text-900 font-medium text-xl">{{
        member.name + " " + member.lastName
      }}</span>
      <span class="text-500 font-medium">| {{
        member.idType + " " + member.idNumber
      }}</span>
      <span class="text-500 font-medium">
        | {{ calculateYearsOld(member.birthDay) }} Años
      </span>
      <p-button icon="pi pi-pencil"  pTooltip="Editar Información del Alumno"
      tooltipPosition="top" (click)="initMemberEdit(member)"/>
    </div>
  </ng-template>


  <p-chip label="Información personal" />

  <div class="flex flex-wrap align-items-center justify-content-between gap-3">
    <div class="flex justify-content-between gap-2">
      <b class="lead">Edad: </b>
      <span>{{calculateYearsOld(member.birthDay) + 'Años'}}</span>

      <b class="lead">Fecha de Nacimiento: </b>
      <span>{{member.birthDay}}</span>

      <b class="lead">Lugar de Nacimiento: </b>
      <span>{{member.birthPlace || 'No Registra'}}</span>

      <b class="lead">Género: </b>
      <span>{{member.gender}}</span>
    </div>
  </div>
  <p-divider></p-divider>
  
  <p-chip label="Información de Contacto" />
  <div class="flex flex-wrap align-items-center justify-content-between gap-3">
    <div class="flex justify-content-between gap-2">
      <b class="lead">Email: </b>
      <span>{{member.contactInformation.email}}</span>

      <b class="lead">Teléfono: </b>
      <span>{{member.contactInformation.mobile}}</span>

      <b class="lead">Dirección: </b>
      <span>{{member.contactInformation.address +
        ' ' +
        member.contactInformation.neighborhood}}</span>
    </div>
  </div>
  <p-divider></p-divider>
  
  <p-chip label="Información de asistencia" />
  <div class="flex flex-wrap align-items-center justify-content-between gap-3">

    <div class="flex justify-content-between gap-2">

      <b class="lead">Grupo Sanguíneo: </b>
      <span>{{member.assistanceInformation.bloodType}}</span>

      <b class="lead">Contacto Emergencia: </b>
      <span>{{member.assistanceInformation.emergencyContactName + ' ' + member.assistanceInformation.emergencyContactNumber}}</span>

      <b class="lead">Eps: </b>
      <span>{{member.assistanceInformation.insurance}}</span>
      </div>
  </div>
</p-panel>

<div *ngIf="showMemberEditForm">
  <app-member-register [idNumber]="member.idNumber" [idType]="member.idType" [isUpdateMoment]="true" [memberToUpdate]="member" (memberUpdated)="refreshMemberUpdated($event)" (closeDialogEvent)="closeEditDialog()"></app-member-register> 
</div>
