import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-gestor-submenu',
  standalone: true,
  imports: [ImageModule, CommonModule,RouterLink, RouterLinkActive, RouterOutlet],
  templateUrl: './gestor-submenu.component.html',
  styleUrl: './gestor-submenu.component.scss'
})
export class GestorSubmenuComponent {
  menuItems = [
    {
      title: 'Solicitudes',
      description:'Gestiona la información de las sedes de la Escuela',
      class: 'pi pi-inbox text-4xl text-green-500',
      routerLink: 'solicitudes',
    },
    {
      title: 'Matriculas',
      description:'Gestiona los ciclos de matriculas y clases',
      class: 'pi pi-user-plus text-4xl text-green-500',
      routerLink: 'matriculas',
    },
    {
      title: 'Ciclos & Grupos',
      description:'Gestiona los horarios de clase disponibles de las sedes',
      class: 'pi pi-calendar-plus text-4xl text-green-500',
      routerLink: '/gestor/ciclos',
    },
    {
      title: 'Informes',
      class: 'pi pi-chart-bar text-4xl text-green-500',
      description:'Gestiona los cursos y sus respectivos niveles de formación que se ofrecen en la Escuela',
      routerLink: '/gestor/informes'
    },
    {
      title: 'Base de Datos Alumnos',
      class: 'pi pi-database text-4xl text-green-500',
      description:'Gestiona productos y servicios que se ofrecen en la Escuela',
      routerLink: '/gestor/db'
    }
  ]
}
