<app-page-header [title]="'Horarios'" [subtitle]="'Módulo de administración de horarios'"></app-page-header>
<app-crud-table [crudTable]="crudTableData" (itemEdit)="editItem($event)" (itemDelete)="deleteItem($event)" (itemAdd)="addItem()"></app-crud-table>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
<div *ngIf="showForm">
    <p-dialog
      [header]="dialogHeader"
      [(visible)]="showForm"
      [breakpoints]="{ '960px': '75vw' }"
      [style]="{ width: '80vw', height: '80vh', top: '2.5rem' }"
      [modal]="true"
      [draggable]="false"
      [resizable]="true"
    >
      <div class="card" [formGroup]="validateForm">
        <div class="flex flex-column gap-2">
          <!-- <label for="schId">Identificador</label>
          <p-inputMask
           pInputText
            mask="a*-99-99"
            formControlName="schId"
            placeholder="DD_HH_HH"
          ></p-inputMask>
  
          <small id="seasonId-small">Identificador del horario.</small>
   -->
          <label for="name">Dia(s) de clase</label>
          <input
            type="text"
            pInputText
            placeholder="Ingrese los dia(s) de clase"
            formControlName="days"
          />
          <small id="name-small">Dia(s) de la clase.</small>
  
          <label for="startTime">Hora Inicio</label>
          <p-calendar class="form-calendar-input" [timeOnly]="true" dataType="string"  [autoZIndex]="true" formControlName="startTime"  [showIcon]="true"></p-calendar>
          <small id="address-small">Hora de inicio de la clase</small>
  
          <label for="endTime">Hora Finalización</label>
          <p-calendar class="form-calendar-input" [timeOnly]="true" dataType="string"  [autoZIndex]="true" formControlName="endTime" [showIcon]="true"></p-calendar>
          <small id="address-small">Hora de finalización de la clases.</small>
  
          <label for="isActive">Activo</label>
          <p-selectButton
            [options]="stateOptions"
            formControlName="isActive"
            optionLabel="label"
            optionValue="value"
          ></p-selectButton>
  
      </div>
  
        <div class="flex mt-2 gap-2 justify-content-end">
          <p-button
            label="Cancelar"
            [outlined]="true"
            severity="secondary"
            [rounded]="true"
            (click)="showForm = false"
          ></p-button>
          <p-button
            *ngIf="isNewForm"
            type="submit"
            label="Guardar"
            [rounded]="true"
            [disabled]="validateForm.invalid"
            (click)="addNewItem()"
          ></p-button>
          <p-button
            *ngIf="isUpdateForm"
            type="submit"
            label="Actualizar"
            [rounded]="true"
            [disabled]="validateForm.invalid"
            (click)="editExistentItem()"
          ></p-button>
        </div>
      </div>
    </p-dialog>
  </div>
  <div *ngIf="showDependencies"> 
    <app-dependencies-list [dependencies]="dependenciesToDelete" (onCloseDependencies)="closeDependenceDialog"></app-dependencies-list>
    </div>