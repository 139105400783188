import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { LoadingService } from '../../../../services/loading.service';
import { GestionService } from '../../../../services/gestion.service';
import { Member } from '../../../../model/member';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { PageHeaderComponent } from '../../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../../shared/tables/crud-table/crud-table.component';
import { MemberCreateMembershipComponent } from "../member-create-membership/member-create-membership.component";
@Component({
    selector: 'app-member-edit-membership',
    standalone: true,
    providers: [MessageService, ConfirmationService],
    templateUrl: './member-edit-membership.component.html',
    styleUrl: './member-edit-membership.component.scss',
    imports: [
        PageHeaderComponent,
        CrudTableComponent,
        ConfirmDialogModule,
        ReactiveFormsModule,
        DialogModule,
        InputTextModule,
        CommonModule,
        InputSwitchModule,
        SelectButtonModule,
        MemberCreateMembershipComponent
    ]
})
export class MemberEditMembershipComponent implements OnInit{

  showEditMembershipForm:boolean = false;


  @Input()
  membership!:MembershipDetail

  @Input()
  member!:Member

  @Output()
  closeEditDialog:EventEmitter<any> = new EventEmitter();

  @Output()
  refreshMeberships:EventEmitter<any> = new EventEmitter();
  
  validateForm!: FormGroup;
  showForm: boolean = false;

  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];

  constructor(private loadingService:LoadingService, private gestionService:GestionService,private messageService: MessageService,
    private confirmationService: ConfirmationService, private fb: FormBuilder){

  }

  ngOnInit(): void {
    if(this.membership){
      this.showEditMembershipForm = true;
    }
  }

  closeEditModal(){
    this.closeEditDialog.emit();
  }
  updateMemberships(){
    this.refreshMeberships.emit();
  }

}
