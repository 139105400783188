<app-page-header [title]="'Productos & Servicios'" [subtitle]="'Módulo de administración de Productos y Servicios'"></app-page-header>

<app-crud-table
  [crudTable]="crudTableData"
  (itemEdit)="editItem($event)"
  (itemDelete)="deleteItem($event)"
  (itemAdd)="addItem()"
></app-crud-table>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<div *ngIf="showForm">
  <p-dialog
    [header]="dialogHeader"
    [(visible)]="showForm"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '80vw', height: '80vh', top: '2.5rem' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="true"
  >
    <div class="card" [formGroup]="validateForm">
      <div class="flex flex-column gap-2">
        <!-- <label for="seasonId">Identificador</label>
        <p-inputMask
         pInputText
          mask="aaa_999"
          formControlName="productServiceId"
          placeholder="Tipo_codigo"
        ></p-inputMask>
        <small id="seasonId-small">Identificador del Ciclo.</small> -->

        <label for="availableToAdministrativeGestion">Tipo</label>
        <p-selectButton
          [options]="productTypes"
          formControlName="type"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>

        <label for="name">Nombre</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese nombre para el ciclo"
          formControlName="name"
        />
        <small id="name-small">Nombre del producto o servicio.</small>

        <label for="startDate">Precio</label>
        <input type="number" pInputText placeholder="Ingrese nombre para el producto o servicio" formControlName="price"/>
        <small id="address-small">Fecha de inicio de clases</small>

        <label for="hasTax">Aplica Iva</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="hasTax"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>

        <div *ngIf="validateForm.get('hasTax')?.value" class="flex flex-column gap-2">
            <label for="taxValue">Porcentaje Iva</label>
            <input type="number" pInputText placeholder="Ingrese porcentaje de iva" formControlName="taxValue"/>
            <small id="taxValue-small">Porcentaje de iva aplicable</small>
    
        </div>
    
        <label for="description">Descripción del producto o servicio</label>
        <textarea rows="5" cols="30" pInputTextarea formControlName="description"></textarea>

        <label for="isActive">Activo</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="isActive"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>

    </div>

      <div class="flex mt-2 gap-2 justify-content-end">
        <p-button
          label="Cancelar"
          [outlined]="true"
          severity="secondary"
          [rounded]="true"
          (click)="showForm = false"
        ></p-button>
        <p-button
          *ngIf="isNewForm"
          type="submit"
          label="Guardar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="addNewItem()"
        ></p-button>
        <p-button
          *ngIf="isUpdateForm"
          type="submit"
          label="Actualizar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="editExistentItem()"
        ></p-button>
      </div>
    </div>
  </p-dialog>
</div>
