export enum AcademyAdministratorType{
    HEADQUARTERS = 'Headquarters',
    SEASONS = 'Seasons',
    SCHS = "Schs",
    COURSES_LEVELS = 'CoursesAndLevels',
    GROUPS = 'ClassGroups',
    PRODUCTS_SERVICES = "ProductAndServices",
    AGREEMENTS_DISCOUNTS ="AgreementsAndDiscounts",
    INSTRUCTORS = 'Instructors',
    MEMBERS = 'Members',
    MEMBERSHIPS = 'Memberships',
    MASSIVE_GROUPS = 'MassiveClassGroups'
}