import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ImageModule } from 'primeng/image';
@Component({
  selector: 'app-admin-submenu',
  standalone: true,
  imports: [ImageModule, CommonModule,RouterLink, RouterLinkActive,],
  templateUrl: './admin-submenu.component.html',
  styleUrl: './admin-submenu.component.scss'
})
export class AdminSubmenuComponent {
  menuItems = [
    {
      title: 'Grupos',
      description:'Gestiona la información de las sedes de la Escuela',
      class: 'pi pi-users text-4xl text-green-500',
      routerLink: 'grupos',
    },
    {
      title: 'Ciclos',
      description:'Gestiona los ciclos de matriculas y clases',
      class: 'pi pi-calendar-plus text-4xl text-green-500',
      routerLink: 'ciclos',
    },
    {
      title: 'Sedes',
      description:'Gestiona la información de las sedes de la Escuela',
      class: 'pi pi-building text-4xl text-green-500',
      routerLink: 'sedes',
    },

    {
      title: 'Horarios',
      description:'Gestiona los horarios de clase disponibles de las sedes',
      class: 'pi pi-clock text-4xl text-green-500',
      routerLink: 'horarios',
    },
    {
      title: 'Cursos & Niveles',
      class: 'pi pi-book text-4xl text-green-500',
      description:'Gestiona los cursos y sus respectivos niveles de formación que se ofrecen en la Escuela',
      routerLink: 'cursos-niveles'
    },
    {
      title: 'Productos & Servicios',
      class: 'pi pi-dollar text-4xl text-green-500',
      description:'Gestiona productos y servicios que se ofrecen en la Escuela',
      routerLink: 'productos-servicios'
    },
    {
      title: 'Descuentos & Convenios',
      class: 'pi pi-percentage text-4xl text-green-500',
      description:'Gestiona los descuentos por becas y convenios',
      routerLink: 'descuentos-convenios'
    },
    {
      title: 'Instructores & Colaboradores',
      class: 'pi pi-users text-4xl text-green-500',
      description:'Gestiona la información de los instructores y auxiliares de la Escuela',
      routerLink: 'instructores-colaboradores'
    },
  ]
}
