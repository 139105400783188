import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { CardModule } from 'primeng/card';
import { ImageModule } from 'primeng/image';
import { TabViewModule } from 'primeng/tabview';
import { ClassGroup, ClassGroupDetail } from '../../../../model/ClassGroup';
import { Course } from '../../../../model/Course';
import { Headquarters } from '../../../../model/Headquarter';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Instructor } from '../../../../model/Instructor';
import { Level } from '../../../../model/Level';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { Sch } from '../../../../model/Sch';
import { Season } from '../../../../model/Season';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { Member } from '../../../../model/member';
import { AdministrationService } from '../../../../services/administration.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { MemberCoursesComponent } from '../member-courses/member-courses.component';
import { MemberInformationComponent } from '../member-information/member-information.component';
import { MemberNewMembershipComponent } from "../member-new-membership/member-new-membership.component";
@Component({
    selector: 'app-member-menu',
    standalone: true,
    templateUrl: './member-menu.component.html',
    styleUrl: './member-menu.component.scss',
    imports: [
        ImageModule,
        CardModule,
        CommonModule,
        TabViewModule,
        MemberInformationComponent,
        MemberCoursesComponent,
        MemberNewMembershipComponent
    ]
})
export class MemberMenuComponent implements OnInit {
  @Input()
  member!: Member;

  memberships: Membership[] = [];
  membershipsDatail: MembershipDetail[] = [];

  showMemberInfo: boolean = false;
  activeIndex:number = 0;
  ngOnInit(): void {
    this.selectMemberships();
  }

  constructor(
    private loadingService: LoadingService,
    private gestionService: GestionService,
    private administrationService: AdministrationService
  ) {}

  async selectMemberships() {
    this.memberships = [];
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'membershipId',
              parameterName: '@Option1',
              parameterValue: this.member.memberId,
            },
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      this.memberships = res.response as Membership[];
      if (this.memberships.length > 0) {
        this.mapperMemberships();
      }else{
        this.showMemberInfo = true;
      }
      
      this.loadingService.hideLoading();
    });
  }

  async mapperMemberships() {
    this.membershipsDatail = [];
    for await (const membership of this.memberships) {
      const classGroup: ClassGroup = await this.getMembeshipGroup(membership);
      let classGroupDetail:ClassGroupDetail = {
        ...classGroup,
        sch:await this.getMembeshipSch(classGroup),
        instructor: await this.getInstructor(classGroup)
      };
      const course: Course = await this.getMembeshipCourse(classGroup);
      const membershipDetail: MembershipDetail = {
        classGroup: classGroupDetail,
        headquarter: await this.getMembeshipHeadquarter(classGroup),
        course: course,
        level: course.levels.find((x) => x.id === classGroup.levelId),
        season: await this.getMembeshipSeason(classGroup),
        id: membership.id,
        member: this.member,
        academyState: membership.academyState,
        administrativeData: membership.administrativeData,
        auditData: membership.auditData,
        membershipId: membership.membershipId,
        state: membership.state,
      };

      this.membershipsDatail.push(membershipDetail);
    }
    this.showMemberInfo = true;
  }

  async getMembeshipGroup(membership: Membership): Promise<ClassGroup> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.GROUPS,
      membership.classGroupId,
      'id'
    );
    return groupData[0] as ClassGroup;
  }

  
  async getInstructor(classGroup: ClassGroup): Promise<Instructor> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.INSTRUCTORS,
      classGroup.instructorId,
      'id'
    );
    return groupData[0] as Instructor;
  }

  async getMembeshipSch(classGroup: ClassGroup): Promise<Sch> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SCHS,
      classGroup.schId,
      'id'
    );
    return groupData[0] as Sch;
  }

  async getMembeshipHeadquarter(classGroup: ClassGroup): Promise<Headquarters> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.HEADQUARTERS,
      classGroup.headquarterId,
      'id'
    );
    return groupData[0] as Headquarters;
  }

  async getMembeshipCourse(classGroup: ClassGroup): Promise<Course> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.COURSES_LEVELS,
      classGroup.courseId,
      'id'
    );
    return groupData[0] as Course;
  }

  getMembeshipLevel(course: Course, levelId: string): any {
    return course.levels.find((levelFind: Level) => levelFind.id === levelId);
  }

  async getMembeshipSeason(classGroup: ClassGroup): Promise<Season> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SEASONS,
      classGroup.seasonId,
      'id'
    );
    return groupData[0] as Season;
  }

  async getEntity(
    academyAdministratorType: AcademyAdministratorType,
    value: any,
    property?: string
  ): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();

      let data: any[] = [];
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: property || 'id',
                parameterName: '@Option1',
                parameterValue: value,
              },
            ],
          },
        },
        type: academyAdministratorType,
      };
      try {
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.response.length > 0) {
              data = res.response as any[];
            }
            resolve(data);
            this.loadingService.hideLoading();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  changeTab(tabNumber:number){
    this.activeIndex = tabNumber;
  }

  async reloadMemberships(){
    this.showMemberInfo = false;
   await this.selectMemberships();
   this.activeIndex = 1;
  }

  memberUpdated(member:Member){
    this.member = member;
    this.reloadMemberships()
  }
}
