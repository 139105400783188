<div *ngIf="showNewMembership">
  <div *ngIf="showDialogInProgressMembership">
    <p-card
      header="Cursos Activos"
      [subheader]="member.name + ' presenta niveles sin calificar'"
      [style]="{
        width: '360px',
        margin: '0 auto',
        'text-align': 'center',
        padding: '1rem'
      }"
    >
      <ng-template pTemplate="header">
        <div class="flex justify-content-center m-2">
          <i class="pi pi-exclamation-circle" style="font-size: 4rem"></i>
        </div>
      </ng-template>

      <p [style]="{ 'text-align': 'justify' }">
        Debe asignar una calificación para nivel En Curso antes de realizar la
        matricula en un nuevo nivel para el mismo curso, vaya a la opción
        "Histórico de Cursos" para asignar una calificación
      </p>
      <ng-template pTemplate="footer">
        <p-button
          label="Entendido"
          icon="pi pi-check"
          (click)="closeDialogInProgressMembership()"
        ></p-button>
      </ng-template>
    </p-card>
  </div>

  <div *ngIf="showMembershipForm">
    <app-member-create-membership
      [isNewForm]="true"
      [isUpdateForm]="false"
      [membershipDetail]="membershipsDatail"
      [member]="member"
      (membershipUpdated)="updateMemberships()"
    ></app-member-create-membership>
  </div>
</div>
