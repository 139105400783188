import { Component, OnInit } from '@angular/core';
import { PageHeaderComponent } from "../../shared/page-header/page-header.component";
import { QueryOptionDto } from '../../../model/QueryOptionDto';
import { ApplicationService } from '../../../services/application.service';
import { HttpResponse } from '../../../model/HttpResponse';
import { Application } from '../../../model/Application';
import { SolicitudesTableComponent } from "./solicitudes-table/solicitudes-table.component";
import { CommonModule } from '@angular/common';
import { SolicitudReviewComponent } from "./solicitud-review/solicitud-review.component";

@Component({
    selector: 'app-solicitudes',
    standalone: true,
    templateUrl: './solicitudes.component.html',
    styleUrl: './solicitudes.component.scss',
    imports: [PageHeaderComponent, SolicitudesTableComponent, CommonModule, SolicitudReviewComponent]
})
export class SolicitudesComponent implements OnInit{

  applications:Application[] = [];
  showApplicationTable:boolean = true;
  showApplicationReview:boolean = false;
  applicationToReview:Application | any = {};
  constructor(private applicationService:ApplicationService){

  }
  ngOnInit(): void {
    //this.getApplications();
  }

  getApplications(){
    const queryOptions:QueryOptionDto = {
        options:{
          conditionals:[
            {
              conditionalConnector:'AND',
              evaluatedProperty:'process.processStage',
              logicOperator:'<>',
              parameterName:'@Canceled',
              parameterValue:'canceled'
            },
            {
              conditionalConnector:'AND',
              evaluatedProperty:'process.processStage',
              logicOperator:'<>',
              parameterName:'@Approved',
              parameterValue:'approved'
            },
            {
              conditionalConnector:'AND',
              evaluatedProperty:'process.processStage',
              logicOperator:'<>',
              parameterName:'@Rejected',
              parameterValue:'rejected'
            },
            {
              conditionalConnector:'AND',
              evaluatedProperty:'process.processStage',
              logicOperator:'<>',
              parameterName:'@Finished',
              parameterValue:'finished'
            },


            
          ]
        }
    };
    this.applicationService.getApplications(queryOptions).subscribe((res:HttpResponse) => {
        this.applications = res.response || [];
    })

  }

  reviewApplication(application:Application){
    this.showApplicationTable = false;
    this.showApplicationReview = true;
    this.applicationToReview = application;
  }

  quitOfRewiew(event:boolean){
    if(event){
      this.showApplicationTable = true;
      this.showApplicationReview = false;
      this.applicationToReview = {};
    }
  }
}
