<p-button
  label="Nueva Matricula"
  [rounded]="true"
  (click)="showDialogForm = true"
  *ngIf="isNewForm"
></p-button>
<div *ngIf="showForm">
  <p-dialog
    [header]="header"
    [(visible)]="showDialogForm"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '80vw', heigh: '80vh', top: '1rem' }"
    [modal]="true"
    [draggable]="true"
    [resizable]="true"
    (onHide)="cancelDialogForm()"
  >
    <div class="card" [formGroup]="validateForm">
      <div class="flex flex-column gap-2">
        <label for="name">Sede *</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="headquarter"
          [options]="headqueters"
          optionLabel="name"
          placeholder="Seleccione una sede"
          (onChange)="getAvailableGroups()"
        ></p-dropdown>
        <small id="name-small">Seleccione una sede.</small>

        <label for="name">Curso *</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="course"
          [options]="courses"
          optionLabel="name"
          placeholder="Seleccione un curso"
          (onChange)="getAvailableLevels()"
        ></p-dropdown>
        <small id="name-small">Seleccione un curso.</small>

        <label for="address">Nivel *</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="level"
          [options]="validateForm.get('course')?.value?.levels || levels"
          optionDisabled="disableToRegister"
          optionLabel="name"
          placeholder="Select un nivel"
          (onChange)="getAvailableGroups()"
        ></p-dropdown>
        <small id="address-small">Seleccione un nivel.</small>

        <label for="address">Ciclo *</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="season"
          [options]="seasons"
          optionLabel="name"
          placeholder="Seleccione un ciclo"
          (onChange)="getAvailableGroups()"
          [id]="'id'"
        ></p-dropdown>
        <small id="address-small">Seleccione un ciclo.</small>

        <label for="address">Grupo *</label>
        <p-dropdown
        *ngIf="isNewForm"
          [style]="{ display: 'flex' }"
          formControlName="classGroup"
          [options]="availableGroups"
          optionLabel="name"
          [optionDisabled]="'isComplete'"
          placeholder="Seleccione un grupo"
          [id]="'id'"
        ></p-dropdown>
        <p-dropdown
        *ngIf="isUpdateForm"
        [style]="{ display: 'flex' }"
        formControlName="classGroup"
        [options]="availableGroups"
        optionLabel="name"
        placeholder="Seleccione un grupo"
        [id]="'id'"
      ></p-dropdown>
        <small id="address-small">Seleccione un grupo.</small>

        <p-divider></p-divider>

        <div *ngIf="isNewForm">
          <label for="address">Productos y Servicios *</label>
          <p-multiSelect
            display="chip"
            [showClear]="true"
            [style]="{ display: 'flex' }"
            [options]="productsAndServices"
            formControlName="productsAndServices"
            optionLabel="name"
            renovación="Seleccione productos o servicios"
            (onChange)="
              calculateAmount(
                validateForm.value['productsAndServices'],
                validateForm.value['agreementsAndDiscounts']
              )
            "
          ></p-multiSelect>
          <small id="address-small">Seleccione un producto o servicio.</small>
          <br>
          <label for="address">Descuentos</label>
          <p-multiSelect
            display="chip"
            [showClear]="true"
            [style]="{ display: 'flex' }"
            [options]="agreementsAndDiscounts"
            formControlName="agreementsAndDiscounts"
            optionLabel="name"
            renovación="Seleccione descuentos o convenios"
            (onChange)="
              calculateAmount(
                validateForm.value['productsAndServices'],
                validateForm.value['agreementsAndDiscounts']
              )
            "
          ></p-multiSelect>
          <small id="address-small">Seleccione descuentos o convenios.</small>
          
          <p-divider></p-divider>
          <div class="flex flex-wrap gap-3 mt-2 ">
            <label for="address" class="text-900 font-medium text-xl">Método de Pago: </label>
            <div
              *ngFor="let type of paymentMetods"
              class="flex align-items-center"
            >
              <p-radioButton
                [inputId]="type.id"
                [value]="type.value"
                formControlName="paymentMethod"
              ></p-radioButton>
              <label [for]="type.id" class="ml-2">{{ type.value }}</label>
            </div>
          </div>
          <div class="flex flex-wrap gap-3 mt-2 text-900 font-medium text-xl" >
            <label for="address">Total a pagar: </label>
            <span >{{ totalAmout | currency }}</span>
          </div>
        </div>
        <div *ngIf="isUpdateForm">
          <label for="address">Calificación</label>
          <div class="flex flex-wrap gap-3">
            <div
              *ngFor="let type of academyQualifications"
              class="flex align-items-center"
            >
              <p-radioButton
                [inputId]="type.id"
                [value]="type.value"
                formControlName="academyQualification"
              ></p-radioButton>
              <label [for]="type.id" class="ml-2">{{ type.label }}</label>
            </div>
          </div>

        </div>
      </div>

      <div class="flex mt-2 gap-2 justify-content-end">
        <p-button
          label="Cancelar"
          [outlined]="true"
          severity="secondary"
          [rounded]="true"
          (click)="cancelDialogForm()"
        ></p-button>
        <p-button
          *ngIf="isNewForm"
          type="submit"
          label="Guardar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="addNewItem()"
        ></p-button>
        <p-button
          *ngIf="isUpdateForm"
          type="submit"
          label="Actualizar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="editExistentItem()"
        ></p-button>
      </div>
    </div>
  </p-dialog>
</div>

<div *ngIf="showResumeMembership">
  <p-dialog
    header="Resumen de la Transacción"
    [(visible)]="showResumeDialog"
    [closable]="true"
    (onHide)="membershipUpdated.emit()"
  >
    <app-member-resume-membership
      [membershipDetail]="membershipResumen"
      (endMembership)="membershipUpdated.emit()"
    ></app-member-resume-membership>
  </p-dialog>
</div>
<p-confirmDialog></p-confirmDialog>
<p-toast></p-toast>