<div *ngIf="showForm">
  <p-dialog
    [header]="header"
    [(visible)]="showDialogForm"
    [breakpoints]="{ '960px': '99vw' }"
    [style]="{ width: '90vw', heigh: '90vh', top: '1rem' }"
    [modal]="true"
    [draggable]="true"
    [resizable]="true"
    (onHide)="cancelDialogForm()"
  >
    <div class="card" *ngIf="showMemberships">
      <p-table
        #dt1
        [value]="membershipsDetail"
        dataKey="id"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="true"
        currentPageReportTemplate="Mostrando desde {first} hasta {last} de {totalRecords} registros"
        [globalFilterFields]="[
          'member.idNumber',
          'member.name',
          'member.lastName',
          'member.gender',
          'member.contactInformation.email',
          'member.contactInformation.mobile'
        ]"
        responsiveLayout="stack" [breakpoint]="'960px'"
      >
        <ng-template pTemplate="caption">
            <button
              type="button"
              pButton
              pRipple
              icon="pi pi-file-excel"
              (click)="exportExcel()"
              class="p-button-primary mr-2"
              pTooltip="Exportar Excel"
              tooltipPosition="bottom"
            ></button>

            <button
              pButton
              label="Limpiar Filtros"
              class="p-button-outlined"
              icon="pi pi-filter-slash"
              (click)="clear(dt1)"
            ></button>
            <span class="p-input-icon-left ml-auto">
              <i class="pi pi-search"></i>
              <input
                pInputText
                type="text"
                (input)="
                  dt1.filterGlobal($any($event.target).value, 'contains')
                "
                placeholder="Busqueda por palabras"
              />
            </span>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th style="min-width: 10rem">
              <div class="flex align-items-center">
                Id
                <p-columnFilter
                  type="text"
                  field="member.idNumber"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex align-items-center">
                Nombres
                <p-columnFilter
                  type="text"
                  field="member.name"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex align-items-center">
                Apellidos
                <p-columnFilter
                  type="text"
                  field="member.lastName"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>

            <th style="min-width: 5rem">
              <div class="flex align-items-center">
                Género
                <p-columnFilter
                  type="text"
                  field="member.gender"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
            <th style="min-width: 5rem">
              <div class="flex align-items-center">
                Edad
                <p-columnFilter
                  type="text"
                  field="member.birthday"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex align-items-center">
                Email
                <p-columnFilter
                  type="text"
                  field="member.contactInformation.email"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex align-items-center">
                Teléfono
                <p-columnFilter
                  type="text"
                  field="member.contactInformation.mobile"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex align-items-center">
                Estado
                <p-columnFilter
                  type="text"
                  field="academyState.state"
                  display="menu"
                ></p-columnFilter>
              </div>
            </th>
            <th style="min-width: 10rem">
              <div class="flex align-items-center">Calificación</div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-membership>
          <tr>
            <td>
              <span class="p-column-title font-bold">Id</span>
              {{ membership.member.idType }} {{ membership.member.idNumber }}
            </td>
            <td>
              <span class="p-column-title font-bold">Nombre</span>
              <span class="ml-1 vertical-align-middle">{{
                membership.member.name
              }}</span>
            </td>
            <td>
              <span class="p-column-title font-bold">Apellidos</span>
              <span class="ml-1 vertical-align-middle">{{
                membership.member.lastName
              }}</span>
            </td>
            <td>
              <span class="p-column-title font-bold">Género</span>
              <span class="ml-1 vertical-align-middle">{{
                getMemberGenere(membership.member.gender)
              }}</span>
            </td>
            <td>
              <span class="p-column-title font-bold">Edad</span>
              <span class="ml-1 vertical-align-middle"
                >{{ calculateYearsOld(membership.member.birthDay) }} Años</span
              >
            </td>
            <td>
              <span class="p-column-title font-bold">Email</span>
              <span class="ml-1 vertical-align-middle">{{
                membership.member.contactInformation.email
              }}</span>
            </td>
            <td>
              <span class="p-column-title font-bold">Teléfono</span>
              <span class="ml-1 vertical-align-middle">{{
                membership.member.contactInformation.mobile
              }}</span>
            </td>
            <td>
              <span class="p-column-title font-bold">Estado</span>
              <p-tag
                [value]="getStatus(membership.academyState)"
                [severity]="getSeverity(membership.academyState)"
              ></p-tag>
            </td>
            <td>
              <span class="p-column-title font-bold">Acciones</span>
              <button
                pButton
                pRipple
                icon="pi pi-check-square"
                class="p-button-rounded p-button-primary m-1"
                (click)="initQualificate(membership)"
              ></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No se encontraron alumnos para este grupo.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-dialog>
</div>
<div *ngIf="showQualificationDialog">
  <app-instructor-seasons-member-qualification
    (closeQualificationDialog)="qualificationDialogClosed()"
    (academyQualificationChanged)="changeAcademyQualification()"
    [membershipDetail]="membershipSelected"
  >
  </app-instructor-seasons-member-qualification>
</div>
