import { Component, Input } from '@angular/core';
import { DividerModule } from 'primeng/divider';
@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [DividerModule],
  templateUrl: './page-header.component.html',
  styleUrl: './page-header.component.scss'
})
export class PageHeaderComponent {


  @Input({required:true})
  title:string = '';

  @Input({required:true})
  subtitle:string = '';
  
  @Input()
  description:string = '';
}
