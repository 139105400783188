import { Injectable } from '@angular/core';
import { CallHttpService } from './call-http.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { QueryOptionDto } from '../model/QueryOptionDto';
import { Application } from '../model/Application';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private callHttpService: CallHttpService) { }

  getApplications(options:QueryOptionDto):Observable<any>{
    const body = options.options;
    return this.callHttpService.post(`${environment.academyApi.applications}/getApplications`,{options:body});
}

  updateApplication(application:Application){
    return this.callHttpService.post(`${environment.academyApi.applications}/updateApplication`,application);
  }

}
