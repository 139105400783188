<app-page-header [title]="'Solicitudes'" [subtitle]="'Módulo de gestión de solicitudes de matriculas'" ></app-page-header>

<div *ngIf="showApplicationTable">
    <app-solicitudes-table [applications]="applications" (reviewApplication)="reviewApplication($event)"></app-solicitudes-table>
</div>

<div *ngIf="showApplicationReview">
    <app-solicitud-review [application]="applicationToReview" (goToBackEvent)="quitOfRewiew($event)"></app-solicitud-review>
</div>

