import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';
import { PageHeaderComponent } from "../../shared/page-header/page-header.component";
import { MembershipsLegalizationsComponent } from "./memberships-legalizations/memberships-legalizations.component";
@Component({
    selector: 'app-informes',
    standalone: true,
    templateUrl: './informes.component.html',
    styleUrl: './informes.component.scss',
    imports: [PageHeaderComponent, TabMenuModule, CommonModule, MembershipsLegalizationsComponent]
})
export class InformesComponent implements OnInit {


    items: MenuItem[] | undefined;

    activeItem: MenuItem | undefined;
    showLegalization:boolean = true;
    ngOnInit() {
        this.items = [
            { label: 'Legalización', icon: 'pi pi-fw pi-dollar', id:'legalizacion'},
            { label: 'Matriculas', icon: 'pi pi-fw pi-user-plus', id:'matriculas',disabled:true },
            { label: 'Alumnos', icon: 'pi pi-fw pi-users', id:'alumnos',disabled:true },
            { label: 'Cursos y grupos', icon: 'pi pi-fw pi-sitemap',id:'cursos_grupos',disabled:true }
        ];

        this.activeItem = this.items[0];
    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event;
        switch (event.id) {
            case 'legalizacion':
                this.showLegalization =true;
                break;
        
            default:
                break;
        }
    }

}
