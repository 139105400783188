import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { v4 as uuidv4 } from 'uuid';
import { ClassGroup } from '../../../model/ClassGroup';
import { CrudTable } from '../../../model/CrudTable';
import { Dependence } from '../../../model/Dependencie';
import { HttpResponse } from '../../../model/HttpResponse';
import { Sch } from '../../../model/Sch';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { ValidationService } from '../../../services/validation.service';
import { DependenciesListComponent } from '../../shared/dependencies-list/dependencies-list.component';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
@Component({
  selector: 'app-horarios',
  standalone: true,
  templateUrl: './horarios.component.html',
  styleUrl: './horarios.component.scss',
  providers: [MessageService, ConfirmationService],
  imports: [
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    InputTextareaModule,
    CalendarModule,
    InputMaskModule,
    SelectButtonModule,
    InputSwitchModule,
    InputTextModule,
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    CommonModule,
    DialogModule,
    ReactiveFormsModule,
    DependenciesListComponent,
  ],
})
export class HorariosComponent implements OnInit {
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'No', value: false },
    { label: 'Si', value: true },
  ];
  showDependencies: boolean = false;
  dependenciesToDelete: Dependence[] = [];
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private validationServices: ValidationService
  ) {}
  ngOnInit(): void {
    this.selectSch();
  }

  async selectSch() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.SCHS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.buildCrudTable(res.response);
        this.loadingService.hideLoading();
      });
  }

  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [
        {
          label: 'Dia(s) de clase',
          sorted: true,
          sortItem: 'days',
        },
        {
          label: 'Hora Inicio',
          sorted: true,
          sortItem: 'startTime',
        },
        {
          label: 'Hora Fin',
          sorted: true,
          sortItem: 'endTime',
        },
        {
          label: 'Activo',
          sorted: true,
          sortItem: 'isActive',
        },
        {
          label: 'Acciones',
          sorted: false,
          sortItem: null,
        },
      ],
      items: ['days', 'startTime', 'endTime', 'isActive'],
    };
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: Sch) {
    this.getFormRenewal(item);
  }

  async deleteItem(item: Sch) {
    this.dependenciesToDelete = [];
    this.showDependencies = false;
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.DELETE,
      payload: item,
      type: AcademyAdministratorType.SCHS,
    };

    let dependencies = await this.validationServices.dependenciesToDelete(
      entity
    );
    if (dependencies.length === 0) {
      this.confirmationService.confirm({
        message: 'Estas seguro de borrar este registro ?',
        header: 'Confirmar',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.loadingService.showLoading();

          this.administrationService
            .sendRequest(entity)
            .subscribe((res: HttpResponse) => {
              if (res.code === 200) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'El registro se ha eliminado correctamente!',
                  life: 3000,
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail:
                    'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                  life: 3000,
                });
              }
              this.selectSch();
              this.loadingService.hideLoading();
            });
        },
      });
    } else {
      this.dependenciesToDelete = dependencies.map(
        (dependencie: ClassGroup) => {
          const dependenciToList: Dependence = {
            id: dependencie.id,
            type: AcademyAdministratorType.GROUPS,
            name: dependencie.classGroupId,
          };
          return dependenciToList;
        }
      );
      this.showDependencies = true;
    }
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      days: [null, [Validators.required]],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      isActive: [true, [Validators.required]]
    });
    this.dialogHeader = 'Nuevo Horario';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormRenewal(item: Sch) {
    this.validateForm = this.fb.group({
      id: [item.id, [Validators.required]],
      days: [item.days, [Validators.required]],
      startTime: [item.startTime, [Validators.required]],
      endTime: [item.endTime, [Validators.required]],
      isActive: [item.isActive, [Validators.required]],
      schId: [item.schId]
    });

    this.dialogHeader = `Editar ${item.schId}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
  }

  closeDependenceDialog() {
    this.showDependencies = false;
  }

  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.SCHS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.selectSch();
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.SCHS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectSch();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): Sch {
    let item: Sch = {
      days: form['days'].toUpperCase(),
      startTime: form['startTime'],
      endTime: form['endTime'],
      schId: form['schId'] || uuidv4(),
      isActive: form['isActive'],
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }
}
