import { Component } from '@angular/core';
import { LoadingService } from '../../../services/loading.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading',
  standalone: true,
  imports: [ProgressSpinnerModule,CommonModule],
  templateUrl: './loading.component.html',
  styleUrl: './loading.component.scss'
})
export class LoadingComponent {
  isLoading = false;
  constructor(private loadingService: LoadingService) { 
    this.loadingService.isLoading.subscribe(data =>{        
      this.isLoading = data;
    })
  }
}
