import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ClassGroup } from '../../../model/ClassGroup';
import { CrudTable } from '../../../model/CrudTable';
import { Dependence } from '../../../model/Dependencie';
import { HttpResponse } from '../../../model/HttpResponse';
import { Instructor } from '../../../model/Instructor';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { GenderType } from '../../../model/genderType';
import { IdType } from '../../../model/idType';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { ValidationService } from '../../../services/validation.service';
import { DependenciesListComponent } from "../../shared/dependencies-list/dependencies-list.component";
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';

@Component({
    selector: 'app-instructores-colaboradores',
    standalone: true,
    templateUrl: './instructores-colaboradores.component.html',
    styleUrl: './instructores-colaboradores.component.scss',
    providers: [MessageService, ConfirmationService],
    imports: [
        PageHeaderComponent,
        CrudTableComponent,
        ConfirmDialogModule,
        ReactiveFormsModule,
        DialogModule,
        InputTextModule,
        CommonModule,
        InputSwitchModule,
        SelectButtonModule,
        DropdownModule,
        InputNumberModule,
        DependenciesListComponent
    ]
})
export class InstructoresColaboradoresComponent implements OnInit {
  headquarters: Instructor[] = [];

  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];

  genderTypes = [
    { name: 'Femenino', value: GenderType.FEMENINO },
    { name: 'Masculino', value: GenderType.MASCULINO },
    { name: 'No Binario', value: GenderType.NO_BINARIO },
  ];

  idTypes = [
    { name: 'Cédula', value: IdType.CEDULA },
    { name: 'Pasaporte', value: IdType.PASAPORTE },
    { name: 'Cédula de Extranjería', value: IdType.CEDULA_EXTRANGERIA },
    {
      name: 'Documento de Identidad Extranjero',
      value: IdType.IDENTIDAD_EXTRANGERA,
    },
  ];

  showDependencies: boolean = false;
  dependenciesToDelete: Dependence[] = [];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private administrationService: AdministrationService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private validationServices: ValidationService
  ) {}
  ngOnInit(): void {
    this.selectInstructors();
  }

  async selectInstructors() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.INSTRUCTORS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.buildCrudTable(res.response);
        this.loadingService.hideLoading();
      });
  }

  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [
        {
          label: 'Tipo Identificación',
          sorted: true,
          sortItem: 'idType',
        },
        {
          label: 'Identificación',
          sorted: true,
          sortItem: 'idNumber',
        },
        {
          label: 'Nombre',
          sorted: true,
          sortItem: 'name',
        },
        {
          label: 'Apellido',
          sorted: true,
          sortItem: 'lastName',
        },
        {
          label: 'Email',
          sorted: true,
          sortItem: 'email',
        },
        {
          label: 'Teléfono',
          sorted: true,
          sortItem: 'mobile',
        },
        {
          label: 'Activa',
          sorted: true,
          sortItem: 'isActive',
        },
        {
          label: 'Acciones',
          sorted: false,
          sortItem: null,
        },
      ],
      items: [
        'idType',
        'idNumber',
        'name',
        'lastName',
        'email',
        'mobile',
        'isActive',
      ],
    };
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: Instructor) {
    this.getFormRenewal(item);
  }

  async deleteItem(item: Instructor) {
    this.dependenciesToDelete = [];
    this.showDependencies = false;
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.DELETE,
      payload: item,
      type: AcademyAdministratorType.INSTRUCTORS,
    };
    let dependencies = await this.validationServices.dependenciesToDelete(
      entity
    );
    if (dependencies.length === 0) {
    this.confirmationService.confirm({
      message: 'Estas seguro de borrar este registro ?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
       
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha eliminado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectInstructors();
            this.loadingService.hideLoading();
          });
      },
    });
  }else {
      this.dependenciesToDelete = dependencies.map(
        (dependencie: ClassGroup) => {
          const dependenciToList: Dependence = {
            id: dependencie.id,
            type: AcademyAdministratorType.GROUPS,
            name: dependencie.classGroupId,
          };
          return dependenciToList;
        }
      );
      this.showDependencies = true;
    }
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      idType: [null, [Validators.required]],
      idNumber: [
        null,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
        ],
      ],
      name: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [
        null,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
        ],
      ],
      isActive: [true, [Validators.required]],
    });
    this.dialogHeader = 'Nueva Instructor';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormRenewal(item: Instructor) {
    this.validateForm = this.fb.group({
      id: [item.id],
      instructorId: [item.instructorId],
      idType: [item.idType, [Validators.required]],
      idNumber: [
        item.idNumber,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
        ],
      ],
      name: [item.name, [Validators.required]],
      lastName: [item.lastName, [Validators.required]],
      email: [item.email, [Validators.required, Validators.email]],
      mobile: [
        item.mobile,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
        ],
      ],
      isActive: [item.isActive, [Validators.required]],
    });
    this.dialogHeader = `Editar ${item.name}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
  }

  
  closeDependenceDialog() {
    this.showDependencies = false;
  }

  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.INSTRUCTORS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.selectInstructors();
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.INSTRUCTORS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectInstructors();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): Instructor {
    let item: Instructor = {
      idType: form['idType'],
      idNumber: form['idNumber'],
      name: form['name'].toUpperCase(),
      lastName: form['lastName'].toUpperCase(),
      email: form['email'],
      mobile: form['mobile'],
      isActive: form['isActive'],
      instructorId: `${form['idType']}${form['idNumber']}`,
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }
}
