
<div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="mb-1 gap-1">
        <ng-template pTemplate="left">
            <p-selectButton [options]="viewOptions" [(ngModel)]="viewSelected" optionLabel="label" optionValue="value" (onChange)="getDataOfView()"></p-selectButton>
        </ng-template>
        <ng-template pTemplate="right">

        </ng-template>
    </p-toolbar>

    <p-table
        #dt
        [value]="applications"
        [rows]="5"
        [paginator]="true"
        [globalFilterFields]="['type','applicant.name','applicant.lastName','applicant.idType','applicant.idNumber','applicant.contactInformation.email','process.processStage','created_at']"
        responsiveLayout="stack" [breakpoint]="'960px'"
        [(selection)]="selectedApplications"
        [rowHover]="true"
        dataKey="ApplicationId"
        currentPageReportTemplate="Registros del {first} al {last} de {totalRecords} registros"
        [showCurrentPageReport]="true"
    > <ng-template pTemplate="caption">
        <button
        pButton
        label="Limpiar Filtros"
        class="p-button-outlined"
        icon="pi pi-filter-slash"
        (click)="clear(dt)"
      ></button>

      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          (input)="dt.filterGlobal($any($event.target).value, 'contains')"
          placeholder="Búsqueda por palabras"
        />
      </span>
    </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th id="tipoSolicitud" class="table__header-title">Tipo Solicitud 

                    <p-columnFilter
                    type="text"
                    field="type"
                    display="menu"
                    [showMatchModes]="false"
                    matchMode="contains"
                    [hideOnClear]="true"
                  ></p-columnFilter>
                </th>
                <th id="nombre" class="table__header-title">Nombre
                    <p-columnFilter
                    type="text"
                    field="applicant.name"
                    display="menu"
                    [showMatchModes]="false"
                    matchMode="contains"
                    [hideOnClear]="true"
                  ></p-columnFilter>
                </th>
                <th id="apellido" pSortableColumn="apellido" class="table__header-title">Apellido

                    <p-columnFilter
                    type="text"
                    field="applicant.lastName"
                    display="menu"
                    [showMatchModes]="false"
                    matchMode="contains"
                    [hideOnClear]="true"
                  ></p-columnFilter>
                </th>
                <th id="tipoIdentificacion" pSortableColumn="tipoIdentificacion" class="table__header-title">Tipo Identificación</th>
                <th id="numeroIdentificacion" pSortableColumn="numeroIdentificacion" class="table__header-title">Numero Identificación

                    <p-columnFilter
                    type="text"
                    field="applicant.idNumber"
                    display="menu"
                    
                    [showMatchModes]="false"
                    matchMode="contains"
                    [hideOnClear]="true"
                  ></p-columnFilter>
                </th>
                <th id="email" pSortableColumn="email" class="table__header-title">Email                  
                    <p-columnFilter
                    type="text"
                    field="applicant.contactInformation.email"
                    display="menu"
                    [showMatchModes]="false"
                    matchMode="contains"
                    [hideOnClear]="true"
                  ></p-columnFilter>
                </th>
                <th id="status" pSortableColumn="process.processStage" class="table__header-title">Estado<p-sortIcon field="process.processStage" class="table__header-sortIcon"></p-sortIcon>    
                <th id="fechaSolicitud" pSortableColumn="created_at" class="table__header-title">Fecha Solicitud <p-sortIcon field="created_at" class="table__header-sortIcon"></p-sortIcon></th>
                <th id="actions" pSortableColumn="actions" class="table__header-title">Acciones</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-application let-columns="columns">
            <tr>
                <td><span class="p-column-title font-bold">Tipo Solicitud</span> {{ application?.type === 'nuevo' ? 'Matricula Nueva' : 'Renovación de Matricula' }}</td>
                <td><span class="p-column-title font-bold">Nombres</span>{{application?.applicant?.name}}</td>
                <td><span class="p-column-title font-bold">Apellidos</span>{{application?.applicant?.lastName}}</td>
                <td><span class="p-column-title font-bold">Tipo Id</span>{{application?.applicant?.idType}}</td>
                <td><span class="p-column-title font-bold">Id</span>{{application?.applicant?.idNumber}}</td>
                <td><span class="p-column-title font-bold">Email</span>{{application?.applicant?.contactInformation?.email}}</td>
                <td><span class="p-column-title font-bold">Estado</span>{{getSeverity(application.process.processStage)}}</td>
                <td><span class="p-column-title font-bold">Fecha Solicitud</span>{{getHumanTime(application?.created_at)}}</td>
                <td>
                    <span class="p-column-title font-bold">Acciones</span>
                    <button pButton pRipple icon="pi pi-eye" class="p-button-rounded p-button-primary m-1" (click)="initReviewApplication(application)"></button>
                    <!-- <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning m-1" (click)="deleteProduct(application)"></button> -->
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="summary">
            <div class="flex align-items-center justify-content-between">Total de solicitudes {{ applications ? applications.length : 0 }}.</div>
        </ng-template>
    </p-table>
</div>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>