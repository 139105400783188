import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginFailedComponent } from './pages/error/login-failed/login-failed.component';
import { LoginComponent } from './pages/login/login.component';
import { AdminRoleGuard } from './guards/admin-role.guard';
import { AdminSubmenuComponent } from './components/administrator/admin-submenu/admin-submenu.component';
import { GestionRoleGuard } from './guards/gestion-role.guard';
import { GestorSubmenuComponent } from './components/gestor/gestor-submenu/gestor-submenu.component';
import { SolicitudesComponent } from './components/gestor/solicitudes/solicitudes.component';
import { MatriculasComponent } from './components/gestor/matriculas/matriculas.component';
import { CiclosComponent } from './components/gestor/ciclos/ciclos.component';
import { InformesComponent } from './components/gestor/informes/informes.component';
import { DbComponent } from './components/gestor/db/db.component';
import { SedesComponent } from './components/administrator/sedes/sedes.component';
import { AdminCiclosComponent } from './components/administrator/ciclos/ciclos.component';
import { CursosNivelesComponent } from './components/administrator/cursos-niveles/cursos-niveles.component';
import { HorariosComponent } from './components/administrator/horarios/horarios.component';
import { DescuentosConveniosComponent } from './components/administrator/descuentos-convenios/descuentos-convenios.component';
import { InstructoresColaboradoresComponent } from './components/administrator/instructores-colaboradores/instructores-colaboradores.component';
import { ProductosServiciosComponent } from './components/administrator/productos-servicios/productos-servicios.component';
import { CheckerHomeComponent } from './components/checker/checker-home/checker-home.component';
import { GroupsComponent } from './components/administrator/groups/groups.component';
import { instructorRoleGuard } from './guards/instructor-role.guard';
import { InstructorSubmenuComponent } from './components/instructor/instructor-submenu/instructor-submenu.component';
import { InstructorSeasonsComponent } from './components/instructor/instructor-seasons/instructor-seasons.component';
import { MemberCheckinComponent } from './components/checker/member-checkin/member-checkin.component';
import { FindCheckerComponent } from './components/checker/find-checker/find-checker.component';
import { checkerRoleGuard } from './guards/checker-role.guard';

export const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },
  {
    path: 'admin',
    component: AdminSubmenuComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/grupos',
    component: GroupsComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/sedes',
    component: SedesComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/ciclos',
    component: AdminCiclosComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/cursos-niveles',
    component: CursosNivelesComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/horarios',
    component: HorariosComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/descuentos-convenios',
    component: DescuentosConveniosComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/instructores-colaboradores',
    component: InstructoresColaboradoresComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'admin/productos-servicios',
    component: ProductosServiciosComponent,
    canActivate: [MsalGuard, AdminRoleGuard],
  },
  {
    path: 'gestor',
    component: GestorSubmenuComponent,
    canActivate: [MsalGuard, GestionRoleGuard],
  },
  {
    path: 'gestor/solicitudes',
    component: SolicitudesComponent,
    canActivate: [MsalGuard, GestionRoleGuard],
  },
  {
    path: 'gestor/matriculas',
    component: MatriculasComponent,
    canActivate: [MsalGuard, GestionRoleGuard],
  },
  {
    path: 'gestor/ciclos',
    component: CiclosComponent,
    canActivate: [MsalGuard, GestionRoleGuard],
  },
  {
    path: 'gestor/informes',
    component: InformesComponent,
    canActivate: [MsalGuard, GestionRoleGuard],
  },
  {
    path: 'gestor/db',
    component: DbComponent,
    canActivate: [MsalGuard, GestionRoleGuard],
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: 'instructor',
    component: InstructorSubmenuComponent,
    canActivate: [MsalGuard, instructorRoleGuard],
  },
  {
    path: 'instructor/ciclos',
    component: InstructorSeasonsComponent,
    canActivate: [MsalGuard, instructorRoleGuard],
  },
  {
    path:'checker',
    component:CheckerHomeComponent,
    canActivate: [MsalGuard, checkerRoleGuard],
  },
  {
    path:'checker/checkin',
    component:MemberCheckinComponent,
    canActivate: [MsalGuard, checkerRoleGuard],
  },
  {
    path:'checker/checkerFind',
    component:FindCheckerComponent,
    canActivate: [MsalGuard, checkerRoleGuard]
  },
  { path: '**', component: NotFoundComponent },
];
