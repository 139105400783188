import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CallHttpService {

  constructor(private http: HttpClient) { }

  createDefaultHeaders(): HttpHeaders{
    const headers = new HttpHeaders({
      'Content-Type':'application/json',
      'Accept':'application/json',
      'subscription-key':environment.apiManagementConfig.academyApi
    });
    return headers;
  }


   post(url:string, data:any, customHeaders?:HttpHeaders): Observable<any>{
    const options = {
      headers:customHeaders || this.createDefaultHeaders()
    }
    return this.http.post(url,data,options);
  }

 get(url:string,customHeaders?:HttpHeaders): Observable<any>{
    const options = {
      headers:customHeaders || this.createDefaultHeaders()
    }
    return this.http.get(url,options);
  }
}
