import { Component } from '@angular/core';

@Component({
  selector: 'app-find-checker',
  standalone: true,
  imports: [],
  templateUrl: './find-checker.component.html',
  styleUrl: './find-checker.component.scss'
})
export class FindCheckerComponent {

}
