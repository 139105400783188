<div *ngIf="showForm">
  <p-dialog
    [header]="dialogHeader"
    [(visible)]="showForm"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '80vw', height: '80vh', top: '2.5rem' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [closable]="true"
    [dismissableMask]="true"
    (visibleChange)="closeDialog()"
  >
    <div class="card" [formGroup]="validateForm">
      <div class="flex flex-column gap-2">

        <label for="address">Tipo de identificación *</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="idType"
          [options]="idTypes"
          optionLabel="name"
          optionValue="value"
          placeholder="Seleccione el tipo de identificación"
          [id]="'id'"
        ></p-dropdown>

        <label for="address">Número de identificación *</label>
        <p-inputNumber
          formControlName="idNumber"
          mode="decimal"
          inputId="withoutgrouping"
          [useGrouping]="false"
          placeholder="número de identificación"
          [allowEmpty]="true"
          autocomplete="off"
        >
        </p-inputNumber>


        <label for="name">Nombre(s) *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese nombre "
          formControlName="name"
        />

        <label for="lastName">Apellido(s) *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el apellido"
          formControlName="lastName"
        />

        <label for="gender">Género *</label>
        <p-dropdown
          [style]="{ display: 'flex' }"
          formControlName="gender"
          [options]="genderTypes"
          optionLabel="name"
          optionValue="value"
          placeholder="Seleccione el género"
          [id]="'id'"
        ></p-dropdown>

        <label for="mobile">Email *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el email"
          formControlName="email"
        />

        <label for="mobile">Teléfono *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el teléfono de contacto"
          formControlName="mobile"
        />

        <label for="address">Dirección *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese la dirección"
          formControlName="address"
        />

        <label for="birthDay">Fecha de Nacimiento *</label>
        <p-calendar
          class="form-calendar-input"
          [touchUI]="true"
          [autoZIndex]="false"
          formControlName="birthDay"
          dateFormat="dd-mm-yy"
          [showIcon]="true"
          
        ></p-calendar>

        <label for="insurance">Aseguradora(EPS) *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el nombre de la aseguradora o EPS"
          formControlName="insurance"
        />

        <label for="bloodType">Grupo Sanguíneo *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el nombre de la aseguradora o EPS"
          formControlName="bloodType"
        />

        <label for="emergencyContactName">Nombre contacto emergencia *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el nombre del contacto de emergencia"
          formControlName="emergencyContactName"
        />

        <label for="emergencyContactNumber">Teléfono contacto de emergencia *</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el teléfono del contacto de emergencia"
          formControlName="emergencyContactNumber"
        />
      </div>

      <div class="flex mt-2 gap-2 justify-content-end">
        <p-button
          label="Cancelar"
          [outlined]="true"
          severity="secondary"
          [rounded]="true"
          (click)="closeDialog()"
        ></p-button>
        <p-button
          *ngIf="isNewForm"
          type="submit"
          label="Guardar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="addNewItem()"
        ></p-button>
        <p-button
          *ngIf="isUpdateForm"
          type="submit"
          label="Actualizar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="editExistentItem()"
        ></p-button>
      </div>
    </div>
  </p-dialog>
</div>
<p-confirmDialog
  [style]="{ width: '450px', 'z-index': '2000 !important' }"
  [autoZIndex]="false"
></p-confirmDialog>
