import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InplaceModule } from 'primeng/inplace';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { HttpResponse } from '../../../model/HttpResponse';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { IdType } from '../../../model/idType';
import { Member } from '../../../model/member';
import { GestionService } from '../../../services/gestion.service';
import { LoadingService } from '../../../services/loading.service';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { MemberMenuComponent } from "./member-menu/member-menu.component";
import { MemberRegisterComponent } from "./member-register/member-register.component";
@Component({
    selector: 'app-matriculas',
    standalone: true,
    templateUrl: './matriculas.component.html',
    styleUrl: './matriculas.component.scss',
    providers: [MessageService, ConfirmationService],
    imports: [
        CommonModule,
        DividerModule,
        InplaceModule,
        AutoCompleteModule,
        ButtonModule,
        InputGroupAddonModule,
        InputGroupModule,
        InputNumberModule,
        PageHeaderComponent,
        FormsModule,
        DropdownModule,
        InputTextModule,
        CardModule,
        ToastModule,
        MemberMenuComponent,
        MemberRegisterComponent
    ]
})
export class MatriculasComponent implements OnInit {
  idTypes = [
    { name: 'Registro Civil', value: IdType.REGISTRO_CIVIL },
    { name: 'Tarjeta de identidad', value: IdType.TARJETA_IDENTIDAD },
    { name: 'Cédula', value: IdType.CEDULA },
    { name: 'Pasaporte', value: IdType.PASAPORTE },
    { name: 'Cédula de Extranjería', value: IdType.CEDULA_EXTRANGERIA },
    {
      name: 'Documento de Identidad Extranjero',
      value: IdType.IDENTIDAD_EXTRANGERA,
    },
  ];

  idTypeSelected: IdType = IdType.EMPTY;
  idNumberSelected!: any;

  memberSelected!: Member;
  searchNewMember: boolean = true;
  showNoFindedMember:boolean = false;
  showMemberMenu:boolean = false;
  showMemberRegister:boolean = false;
  selectetIdType(event: any) {

  }

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private gestionService: GestionService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {

  }

  async searchMemberById() {
    this.showNoFindedMember = false;
    this.showMemberRegister = false;
    if (
      this.idTypeSelected != undefined &&
      this.idTypeSelected !== IdType.EMPTY &&
      this.idNumberSelected != undefined
    ) {
      this.loadingService.showLoading();
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: 'idType',
                parameterName: '@Id',
                parameterValue: this.idTypeSelected,
              },
              {
                conditionalConnector: 'AND',
                logicOperator: '=',
                evaluatedProperty: 'idNumber',
                parameterName: '@Option1',
                parameterValue: `${this.idNumberSelected}`,
              },
            ],
          },
        },
        type: AcademyAdministratorType.MEMBERS,
      };
      this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
        if(res.response.length > 0){
            this.memberSelected = res.response[0];
            this.showNoFindedMember = false;
            this.idNumberSelected = null;
            this.idTypeSelected = IdType.EMPTY;
            this.searchNewMember = false;
            this.showMemberMenu = true;
            
        }else{
            this.showNoFindedMember = true;
            this.showMemberMenu = false;
        }
        this.loadingService.hideLoading();
      });
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Campos Incompletos',
        detail: 'Ingresa todos los campos requeridos',
      });
    }
  }

  newMemberSearch(){
    this.searchNewMember=true;
    this.showNoFindedMember = false;
    this.showMemberMenu = false;
    this.showMemberRegister = false;
  }


  initMemberRegister(){
    this.searchNewMember = false;
    this.showNoFindedMember = false;
    this.showMemberMenu = false;
    this.showMemberRegister = true;
  }

  refreshMemberCreated(member:Member){
    this.idNumberSelected = member.idNumber;
    this.idTypeSelected = member.idType; 
    this.searchNewMember = true;
    this.showNoFindedMember = false;
    this.showMemberMenu = false;
    this.showMemberRegister = false;
    this.searchMemberById();
  }
}
