<app-page-header [title]="'Base de Datos Alumnos'" [subtitle]="'Módulo de consulta a base de datos'"></app-page-header>
<div *ngIf="showForm">

      <div class="card" *ngIf="showMemberships">
        <p-table
          #dt1
          [value]="members"
          dataKey="id"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loading"
          [paginator]="true"
          currentPageReportTemplate="Mostrando desde {first} hasta {last} de {totalRecords} registros"
          [globalFilterFields]="[
            'idNumber',
            'name',
            'lastName',
            'gender',
            'contactInformation.email',
            'contactInformation.mobile'
          ]"
           responsiveLayout="stack" [breakpoint]="'960px'"
        >
          <ng-template pTemplate="caption">
              <button
                type="button"
                pButton
                pRipple
                icon="pi pi-file-excel"
                (click)="exportExcel()"
                class="p-button-primary mr-2"
                pTooltip="Exportar Excel"
                tooltipPosition="bottom"
              ></button>
  
              <button
                pButton
                label="Limpiar Filtros"
                class="p-button-outlined"
                icon="pi pi-filter-slash"
                (click)="clear(dt1)"
              ></button>
              <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="
                    dt1.filterGlobal($any($event.target).value, 'contains')
                  "
                  placeholder="Busqueda por palabras"
                />
              </span>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Id
                  <p-columnFilter
                    type="text"
                    field="idNumber"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Nombres
                  <p-columnFilter
                    type="text"
                    field="name"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Apellidos
                  <p-columnFilter
                    type="text"
                    field="lastName"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
  
              <th style="min-width: 5rem">
                <div class="flex align-items-center">
                  Género
                  <p-columnFilter
                    type="text"
                    field="gender"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 5rem">
                <div class="flex align-items-center">
                  Edad
                  <p-columnFilter
                    type="text"
                    field="birthday"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Email
                  <p-columnFilter
                    type="text"
                    field="contactInformation.email"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
              <th style="min-width: 10rem">
                <div class="flex align-items-center">
                  Teléfono
                  <p-columnFilter
                    type="text"
                    field="contactInformation.mobile"
                    display="menu"
                  ></p-columnFilter>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-member let-columns="columns">
            <tr>
              <td>
                <span class="p-column-title font-bold">Id</span>
                {{ member.idType }} {{ member.idNumber }}
              </td>
              <td>
                <span class="p-column-title font-bold">Nombres</span>
                <span class="ml-1 vertical-align-middle">{{
                  member.name
                }}</span>
              </td>
              <td>
                <span class="p-column-title font-bold">Apellidos</span>
                <span class="ml-1 vertical-align-middle">{{
                  member.lastName
                }}</span>
              </td>
              <td>
                <span class="p-column-title font-bold">Género</span>
                <span class="ml-1 vertical-align-middle">{{
                  getMemberGenere(member.gender)
                }}</span>
              </td>
              <td>
                <span class="p-column-title font-bold">Edad</span>
                <span class="ml-1 vertical-align-middle"
                  >{{ calculateYearsOld(member.birthDay) }} Años</span
                >
              </td>
              <td>
                <span class="p-column-title font-bold">Email</span>
                <span class="ml-1 vertical-align-middle">{{
                  member.contactInformation.email
                }}</span>
              </td>
              <td>
                <span class="p-column-title font-bold">Teléfono</span>
                <span class="ml-1 vertical-align-middle">{{
                  member.contactInformation.mobile
                }}</span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">No se encontraron alumnos.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
  </div>
  