import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MenubarModule } from 'primeng/menubar';
import { ImageModule } from 'primeng/image';
import { environment } from '../../../../environments/environment';
import { InputTextModule } from 'primeng/inputtext';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MsalService } from '@azure/msal-angular';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
@Component({
  selector: 'app-main-menu',
  standalone: true,
  imports: [
    MenubarModule,
    ImageModule,
    InputTextModule,
    SplitButtonModule,
    CommonModule,
    ButtonModule
  ],
  templateUrl: './main-menu.component.html',
  styleUrl: './main-menu.component.scss',
})
export class MainMenuComponent implements OnInit {
  userLoggedOptions: any[] = [];

  items?: any;
  menuRol: MenuItem[] = [];

  @Input({ required: true })
  userRoles: any[] = [];

  @Input({required:true})
  isLogged:boolean = false;

  userName: string = '';


  @Output()
  loginRequest: EventEmitter<boolean> = new EventEmitter();

  constructor(private msalService: MsalService) {}

  ngOnInit(): void {

    if(this.isLogged){
      this.buildMenu();
      this.loadMenuByUserRole();
      this.userName =
        this.msalService.instance.getActiveAccount()?.idTokenClaims?.name || '';
    }
  }

  buildMenu() {
    this.items = {
      [environment.roles.gestionRole]: {
        label: 'Gestión',
        icon: 'pi pi-fw pi-id-card',
        routerLink: '/gestor',
        items: [
          {
            label: 'Solicitudes',
            icon: 'pi pi-fw pi-inbox',
            routerLink: '/gestor/solicitudes',
          },
          {
            label: 'Matriculas',
            icon: 'pi pi-fw pi-user-plus',
            routerLink: '/gestor/matriculas',
          },
          {
            separator: true,
          },
          {
            label: 'Ciclos & Grupos',
            icon: 'pi pi-fw pi-calendar-plus',
            routerLink: '/gestor/ciclos',
          },
          {
            separator: true,
          },
          {
            label: 'Informes',
            icon: 'pi pi-fw pi-chart-bar',
            routerLink: '/gestor/informes',
          },
          {
            label: 'Base de Datos Alumnos',
            icon: 'pi pi-database pi-align-justify',
            routerLink: '/gestor/db',
          },
        ],
      },

      [environment.roles.adminRole]: {
        label: 'Administración',
        icon: 'pi pi-fw pi-sitemap',
        routerLink: '/admin',
        items: [
          {
            label: 'Grupos',
            icon: 'pi pi-fw pi-users',
            routerLink: 'admin/grupos',
          },
          {
            separator: true,
          },
          {
            label: 'Ciclos',
            icon: 'pi pi-fw pi-calendar-plus',
            routerLink: 'admin/ciclos',
          },
          {
            label: 'Sedes',
            icon: 'pi pi-fw pi-building',
            routerLink: '/admin/sedes',
          },

          {
            label: 'Horarios',
            icon: 'pi pi-fw pi-clock',
            routerLink: 'admin/horarios',
          },
          {
            separator: true,
          },
          {
            label: 'Cursos & Niveles',
            icon: 'pi pi-fw  pi-book',
            routerLink: 'admin/cursos-niveles',
          },
          {
            separator: true,
          },
          {
            label: 'Productos & Servicios',
            icon: 'pi pi-fw pi-dollar',
            routerLink: 'admin/productos-servicios',
          },
          {
            label: 'Descuentos & Convenios',
            icon: 'pi pi-fw pi-percentage',
            routerLink: 'admin/descuentos-convenios',
          },
          {
            separator: true,
          },
          {
            label: 'Instructores & Colaboradores',
            icon: 'pi pi-fw pi-users',
            routerLink: 'admin/instructores-colaboradores',
          },
        ],

        
        router: {
          path: '/admin',
        },
      },

      [environment.roles.instructorRole]: {
        label: 'Instructor',
        icon: 'pi pi-fw pi-stopwatch',
        routerLink: '/instructor',
        items: [
          {
            label: 'Ciclos & Grupos',
            icon: 'pi pi-fw pi-calendar-plus',
            routerLink: 'instructor/ciclos',
          }
        ],
        router: {
          path: '/instructor',
        },
      },

      [environment.roles.checkerRole]: {
        label: 'Gestión de Accesos',
        icon: 'pi pi-fw pi-sign-in',
        routerLink: 'checker',
        items: [
          {
            label: 'Check-in Alumnos',
            icon: 'pi pi-fw pi-qrcode',
            routerLink: 'checker/checkin',
          },
          // {
          //   label: 'Consulta de Alumnos',
          //   icon: 'pi pi-fw pi-search',
          //   routerLink: 'checker/checkerFind',
          // }
        ],
        router: {
          path: '/checker',
        },
      },
    };
    this.userLoggedOptions = [
      {
        label: 'Cerrar Sesión',
        icon: 'pi pi-fw pi-power-off',
        command: () => {
          this.logout();
        },
      },
    ];


  }

  loadMenuByUserRole() {
    this.menuRol.push({
      label: 'Inicio',
      icon: 'pi pi-fw pi-home',
      routerLink: '/home',
    })
    for (let rol of this.userRoles) {
      this.menuRol?.push(this.items[rol]);
    }
  }
  loguin(event: any) {
    this.loginRequest.emit(true);
  }

  logout(popup?: boolean) {
    if (popup) {
      this.msalService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.msalService.logoutRedirect();
    }
  }
}
