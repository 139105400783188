<div class="card" *ngIf="showGroupsTable">
  <p-table
    #dt1
    [value]="availableGroups"
    dataKey="id"
    [rows]="10"
    [showCurrentPageReport]="true"
    [rowsPerPageOptions]="[10, 25, 50]"
    [loading]="loading"
    [paginator]="true"
    currentPageReportTemplate="Mostrando desde {first} hasta {last} de {totalRecords} registros"
    [globalFilterFields]="[
      'headquarter.name',
      'course.name',
      'name',
      'level.name',
      'state.membersCount'
    ]"
     responsiveLayout="stack" [breakpoint]="'960px'"
  >
    <ng-template pTemplate="caption">

        <button
          pButton
          label="Limpiar Filtros"
          class="p-button-outlined"
          icon="pi pi-filter-slash"
          (click)="clear(dt1)"
        ></button>
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="dt1.filterGlobal($any($event.target).value, 'contains')"
            placeholder="Busqueda por palabras"
          />
        </span>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th style="min-width: 10rem">
          <div class="flex align-items-center">
            Sede
            <p-columnFilter
              field="headquarter.name"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Sede</span>
                </div>
              </ng-template>
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="headqueters"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionValue="name"
                  optionLabel="name"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>
        </th>
        <th style="min-width: 10rem">
          <div class="flex align-items-center">
            Curso
            <p-columnFilter
              field="course.name"
              matchMode="in"
              display="menu"
              [showMatchModes]="false"
              [showOperator]="false"
              [showAddButton]="false"
            >
              <ng-template pTemplate="header">
                <div class="px-3 pt-3 pb-0">
                  <span class="font-bold">Curso</span>
                </div>
              </ng-template>
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-multiSelect
                  [ngModel]="value"
                  [options]="courses"
                  placeholder="Any"
                  (onChange)="filter($event.value)"
                  optionValue="name"
                  optionLabel="name"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="inline-block vertical-align-middle">
                      <span class="ml-1 mt-1">{{ option.name }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </div>
        </th>
        <th style="min-width: 10rem">
          <div class="flex align-items-center">
            Nombre
            <p-columnFilter
              type="text"
              field="sch.days"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 10rem">
          <div class="flex align-items-center">
            Nivel
            <p-columnFilter
              type="text"
              field="level.name"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 5rem">
          <div class="flex align-items-center">
            Cant. Inscritos
            <p-columnFilter
              type="text"
              field="state.membersCount"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>
        <th style="min-width: 5rem">
          <div class="flex align-items-center">
            Instructor
            <p-columnFilter
              type="text"
              field="instructor.name"
              display="menu"
            ></p-columnFilter>
          </div>
        </th>

        <th style="min-width: 5rem">
          <div class="flex align-items-center">Alumnos</div>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-group let-columns="columns">
      <tr>
        <td>
          <span class="p-column-title font-bold">Sede</span>
          <span class="ml-1 vertical-align-middle">
            {{ group.headquarter.name }}</span
          >
        </td>
        <td>
          <span class="p-column-title font-bold">Curso</span>
          <span class="ml-1 vertical-align-middle">
            {{ group.course.name }}</span
          >
        </td>

        <td>
          <span class="p-column-title font-bold">Horario</span>
          <span class="ml-1 vertical-align-middle">
            {{ group.sch.days }} {{ group.sch.startTime }} -
            {{ group.sch.endTime }}</span
          >
        </td>
        <td>
          <span class="p-column-title font-bold">Nivel</span>
          <span class="ml-1 vertical-align-middle">
            {{ group.level.name }}</span
          >
        </td>
        <td>
          <span class="p-column-title font-bold">Cant. Inscritos</span>
          <span class="ml-1 vertical-align-middle">
            {{ group.state.membersCount }}</span
          >
        </td>
        <td>
          <span class="p-column-title font-bold">Instructor</span>
          <span class="ml-1 vertical-align-middle">
            {{ group.instructor.name}}</span
          >
        </td>
        <td>
          <span class="p-column-title font-bold">Acciones</span>
          <button
            pButton
            pRipple
            icon="pi pi-users"
            class="p-button-rounded p-button-primary m-1"
            (click)="showMembers(group)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">No se encontraron grupos para este ciclo.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<div *ngIf="showMembersDialog">

  <app-groups-seasons-members
    [groupSelected]="groupSelected"
    (closeEditDialog)="closeMembersDialog()"
  ></app-groups-seasons-members>
</div>
