export const environment = {
	production: true,
    clientUrl:'https://academylab.lipatinantioquia.com.co',
    msalConfig: {
        auth: {
            clientId: '16cbf548-fe96-4320-bc9c-93aed7f3caf3',
            authority: 'https://login.microsoftonline.com/4c23a29a-4620-4d97-88e0-0670df67d7ad',
            redirectUri:'https://academylab.lipatinantioquia.com.co',
            redirectLogoutUri:'https://academylab.lipatinantioquia.com.co/login'
        }
    },
    apiConfig: {
        scopes: ['user.read'],
        uri: 'https://graph.microsoft.com/v1.0/me'
    },
    roles:{
        adminRole:'ACADEMY_ADMINISTRATOR',
        gestionRole:'ACADEMY_GESTOR',
        instructorRole:'ACADEMY_INSTRUCTOR',
        checkerRole:'ACADEMY_CHECKER'
    },
    academyApi:{
        applications:'https://apim-lipatin-lab-001.azure-api.net/applications',
        documents:'https://apim-lipatin-lab-001.azure-api.net/docs',
        registration:'https://apim-lipatin-lab-001.azure-api.net/registration',
        administration:'https://apim-lipatin-lab-001.azure-api.net/administrator',
        gestion:'https://apim-lipatin-lab-001.azure-api.net/membership'
    },
    academyPortals:{
        applications:'https://digitallab.lipatinantioquia.com.co'
    },
    apiManagementConfig:{
        academyApi: '06a7e8478e204c849f45d1ad754c7063'
    }
};