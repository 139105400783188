import { Component, Input } from '@angular/core';
import { MembershipDetail } from '../../../model/Membership';
import { LOGO_PRINCIPAL_ENCODE } from '../../../model/const/const';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ButtonModule } from 'primeng/button';
import { DocumentsService } from '../../../services/documents.service';
import { File } from '../../../model/File';
import { HttpResponse } from '../../../model/HttpResponse';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-pdf',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './pdf.component.html',
  styleUrl: './pdf.component.scss'
})
export class PdfComponent {
  
  @Input()
  membershipDetail!: MembershipDetail;


  constructor(private documentsService:DocumentsService){

  }

  ngOnInit(): void {
    this.saveTransactionDocumentSupport();
  }

  createPDF() {
 
    const itemsDefinition = this.GetPaymentDetailForPrint(this.membershipDetail);
    let pdfDefinition: any = {
      content: [
        {
          columns: [
            {
              image: LOGO_PRINCIPAL_ENCODE,
              width: 150,
            },
            [
              {
                text: 'Comprobante de Transacción',
                color: '#333333',
                width: '*',
                fontSize: 28,
                bold: true,
                alignment: 'right',
                margin: [0, 0, 0, 15],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'Id',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 12,
                        alignment: 'right',
                      },
                      {
                        text: `${this.membershipDetail.membershipId}`,
                        bold: false,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 190,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Fecha Transacción',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 12,
                        alignment: 'right',
                      },
                      {
                        text: `${moment(this.membershipDetail.auditData.created_at).format(
                          'DD/MM/YYYY, HH:mm:ss'
                        )}`,
                        bold: true,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Estado',
                        color: '#aaaaab',
                        bold: true,
                        fontSize: 12,
                        alignment: 'right',
                        width: '*',
                      },
                      {
                        text: `APROBADO`,
                        bold: true,
                        fontSize: 14,
                        alignment: 'right',
                        color: 'green',
                        width: 100,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        {
          columns: [
            {
              text: 'Alumno',
              color: '#aaaaab',
              bold: true,
              fontSize: 14,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            {
              text: 'Curso',
              color: '#aaaaab',
              bold: true,
              fontSize: 14,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
          ],
        },
        {
          columns: [
            {
              text: `${this.membershipDetail.member.name} ${this.membershipDetail.member.lastName} \n ${this.membershipDetail.member.idType} ${this.membershipDetail.member.idNumber} \n Fecha Nacimiento ${this.membershipDetail.member.birthDay}`,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: `${this.membershipDetail.level?.name} \n ${this.membershipDetail.course.name}`,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: 'Agenda',
              color: '#aaaaab',
              bold: true,
              margin: [0, 7, 0, 3],
            },
            {
              text: 'Ciclo',
              color: '#aaaaab',
              bold: true,
              margin: [0, 7, 0, 3],
            },
          ],
        },
        {
          columns: [
            {
              text: `${this.membershipDetail.classGroup.name?.split('|')[0]} \n ${this.membershipDetail.headquarter.name}`,
              style: 'invoiceBillingAddress',
            },
            {
              text: `${this.membershipDetail.season.name}`,
              style: 'invoiceBillingAddress',
            },
          ],
        },
        '\n\n',
        {
          width: '100%',
          alignment: 'center',
          text: 'Información del pago',
          bold: true,
          margin: [0, 10, 0, 10],
          fontSize: 12,
        },
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              if (i === 1 || i === 0) {
                return '#bfdde8';
              }
              return '#eaeaea';
            },
            vLineColor: function (i: any, node: any) {
              return '#eaeaea';
            },
            hLineStyle: function (i: any, node: any) {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i: any, node: any) {
              return 10;
            },
            paddingRight: function (i: any, node: any) {
              return 10;
            },
            paddingTop: function (i: any, node: any) {
              return 2;
            },
            paddingBottom: function (i: any, node: any) {
              return 2;
            },
            fillColor: function (rowIndex: any, node: any, columnIndex: any) {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 80],
            body: itemsDefinition
          },
        },
        '\n',
        '\n\n',
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              return '#eaeaea';
            },
            vLineColor: function (i: any, node: any) {
              return '#eaeaea';
            },
            hLineStyle: function (i: any, node: any) {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i: any, node: any) {
              return 10;
            },
            paddingRight: function (i: any, node: any) {
              return 10;
            },
            paddingTop: function (i: any, node: any) {
              return 3;
            },
            paddingBottom: function (i: any, node: any) {
              return 3;
            },
            fillColor: function (rowIndex: any, node: any, columnIndex: any) {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Subtotal',
                  border: [false, true, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  border: [false, true, false, true],
                  text: `$${(this.membershipDetail.administrativeData.paymentInfo?.paymentData.amount || 0)} `,
                  alignment: 'right',
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'Descuentos',
                  border: [false, false, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  text: `%${this.membershipDetail.administrativeData.paymentInfo?.agreementsDiscount[0]?.discount || 0}`,
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'Total Pago',
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 5],
                },
                {
                  text: `$${this.membershipDetail.administrativeData.paymentInfo?.paymentData.amount}`,
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
        },
        '\n\n',
        {
          text: 'Comentarios',
          style: 'notesTitle',
        },
        {
          text: `Matriculado por: ${this.membershipDetail.auditData.creator}`,
          style: 'notesText',
        },
      ],
      styles: {
        notesTitle: {
          fontSize: 10,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        columnGap: 20,
        //font: 'Quicksand',
      },
    };

    const pdf = pdfMake.createPdf(pdfDefinition);
    pdf.open();
    pdf.download(`Lipatin_Soporte_Transacción_${this.membershipDetail.member.name}_${this.membershipDetail.member.lastName}`);
  }

  GetPaymentDetailForPrint(membershipDetail:MembershipDetail){
    let detailEntries:any[] = [];
    if(membershipDetail && membershipDetail.administrativeData.paymentInfo){
      detailEntries.push(
        [
          {
            text: 'Concepto',
            fillColor: '#8dbf2b',
            color:'#fff',
            border: [false, true, false, true],
            margin: [0, 5, 0, 5],
            textTransform: 'uppercase',
          },
          {
            text: 'Valor Unitario',
            color:'#fff',
            border: [false, true, false, true],
            alignment: 'right',
            fillColor: '#8dbf2b',
            margin: [0, 5, 0, 5],
            textTransform: 'uppercase',
          },
        ],
      );
      
      for(let item of membershipDetail.administrativeData.paymentInfo.productsAndServices){
        const intemEntry = [{
          text: `${item.description}`,
          border: [false, false, false, true],
          margin: [0, 5, 0, 5],
          alignment: 'left',
        },
        {
          text: `$${item.price}`,
          border: [false, false, false, true],
          fillColor: '#f5f5f5',
          alignment: 'right',
          margin: [0, 5, 0, 5],
        }];

        detailEntries.push(intemEntry);
      }
    }
    return detailEntries;

  }

  saveTransactionDocumentSupport(){
    const itemsDefinition = this.GetPaymentDetailForPrint(this.membershipDetail);
    let pdfDefinition: any = {
      content: [
        {
          columns: [
            {
              image: LOGO_PRINCIPAL_ENCODE,
              width: 150,
            },
            [
              {
                text: 'Comprobante de Transacción',
                color: '#333333',
                width: '*',
                fontSize: 28,
                bold: true,
                alignment: 'right',
                margin: [0, 0, 0, 15],
              },
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'Id',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 12,
                        alignment: 'right',
                      },
                      {
                        text: `${this.membershipDetail.membershipId}`,
                        bold: false,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 190,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Fecha Transacción',
                        color: '#aaaaab',
                        bold: true,
                        width: '*',
                        fontSize: 12,
                        alignment: 'right',
                      },
                      {
                        text: `${moment(this.membershipDetail.auditData.created_at).format(
                          'DD/MM/YYYY, HH:mm:ss'
                        )}`,
                        bold: true,
                        color: '#333333',
                        fontSize: 10,
                        alignment: 'right',
                        width: 100,
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'Estado',
                        color: '#aaaaab',
                        bold: true,
                        fontSize: 12,
                        alignment: 'right',
                        width: '*',
                      },
                      {
                        text: `APROBADO`,
                        bold: true,
                        fontSize: 14,
                        alignment: 'right',
                        color: 'green',
                        width: 100,
                      },
                    ],
                  },
                ],
              },
            ],
          ],
        },
        {
          columns: [
            {
              text: 'Alumno',
              color: '#aaaaab',
              bold: true,
              fontSize: 14,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
            {
              text: 'Curso',
              color: '#aaaaab',
              bold: true,
              fontSize: 14,
              alignment: 'left',
              margin: [0, 20, 0, 5],
            },
          ],
        },
        {
          columns: [
            {
              text: `${this.membershipDetail.member.name} ${this.membershipDetail.member.lastName} \n ${this.membershipDetail.member.idType} ${this.membershipDetail.member.idNumber} \n Fecha Nacimiento ${this.membershipDetail.member.birthDay}`,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
            {
              text: `${this.membershipDetail.level?.name} \n ${this.membershipDetail.course.name}`,
              bold: true,
              color: '#333333',
              alignment: 'left',
            },
          ],
        },
        {
          columns: [
            {
              text: 'Agenda',
              color: '#aaaaab',
              bold: true,
              margin: [0, 7, 0, 3],
            },
            {
              text: 'Ciclo',
              color: '#aaaaab',
              bold: true,
              margin: [0, 7, 0, 3],
            },
          ],
        },
        {
          columns: [
            {
              text: `${this.membershipDetail.classGroup.name?.split('|')[0]} \n ${this.membershipDetail.headquarter.name}`,
              style: 'invoiceBillingAddress',
            },
            {
              text: `${this.membershipDetail.season.name}`,
              style: 'invoiceBillingAddress',
            },
          ],
        },
        '\n\n',
        {
          width: '100%',
          alignment: 'center',
          text: 'Información del pago',
          bold: true,
          margin: [0, 10, 0, 10],
          fontSize: 12,
        },
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              if (i === 1 || i === 0) {
                return '#bfdde8';
              }
              return '#eaeaea';
            },
            vLineColor: function (i: any, node: any) {
              return '#eaeaea';
            },
            hLineStyle: function (i: any, node: any) {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i: any, node: any) {
              return 10;
            },
            paddingRight: function (i: any, node: any) {
              return 10;
            },
            paddingTop: function (i: any, node: any) {
              return 2;
            },
            paddingBottom: function (i: any, node: any) {
              return 2;
            },
            fillColor: function (rowIndex: any, node: any, columnIndex: any) {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 80],
            body: itemsDefinition
          },
        },
        '\n',
        '\n\n',
        {
          layout: {
            defaultBorder: false,
            hLineWidth: function (i: any, node: any) {
              return 1;
            },
            vLineWidth: function (i: any, node: any) {
              return 1;
            },
            hLineColor: function (i: any, node: any) {
              return '#eaeaea';
            },
            vLineColor: function (i: any, node: any) {
              return '#eaeaea';
            },
            hLineStyle: function (i: any, node: any) {
              // if (i === 0 || i === node.table.body.length) {
              return null;
              //}
            },
            // vLineStyle: function (i, node) { return {dash: { length: 10, space: 4 }}; },
            paddingLeft: function (i: any, node: any) {
              return 10;
            },
            paddingRight: function (i: any, node: any) {
              return 10;
            },
            paddingTop: function (i: any, node: any) {
              return 3;
            },
            paddingBottom: function (i: any, node: any) {
              return 3;
            },
            fillColor: function (rowIndex: any, node: any, columnIndex: any) {
              return '#fff';
            },
          },
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              [
                {
                  text: 'Subtotal',
                  border: [false, true, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  border: [false, true, false, true],
                  text: `$${(this.membershipDetail.administrativeData.paymentInfo?.paymentData.amount || 0)} `,
                  alignment: 'right',
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'Descuentos',
                  border: [false, false, false, true],
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
                {
                  text: `%${this.membershipDetail.administrativeData.paymentInfo?.agreementsDiscount[0]?.discount || 0}`,
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  alignment: 'right',
                  margin: [0, 5, 0, 5],
                },
              ],
              [
                {
                  text: 'Total Pago',
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  margin: [0, 5, 0, 5],
                },
                {
                  text: `$${this.membershipDetail.administrativeData.paymentInfo?.paymentData.amount}`,
                  bold: true,
                  fontSize: 20,
                  alignment: 'right',
                  border: [false, false, false, true],
                  fillColor: '#f5f5f5',
                  margin: [0, 5, 0, 5],
                },
              ],
            ],
          },
        },
        '\n\n',
        {
          text: 'Comentarios',
          style: 'notesTitle',
        },
        {
          text: `Matriculado por: ${this.membershipDetail.auditData.creator}`,
          style: 'notesText',
        },
      ],
      styles: {
        notesTitle: {
          fontSize: 10,
          bold: true,
          margin: [0, 50, 0, 3],
        },
        notesText: {
          fontSize: 10,
        },
      },
      defaultStyle: {
        columnGap: 20,
        //font: 'Quicksand',
      },
    };

    const pdf = pdfMake.createPdf(pdfDefinition);

    pdf.getBase64((res)=>{
      this.uploadDocument(res);
    })

  }

  uploadDocument(base64:string){
    const file:File = {
      id:this.membershipDetail.administrativeData.transactionDocId,
      filestream:base64,
      fileId:this.membershipDetail.administrativeData.transactionDocId
    }
    this.documentsService.uploadTransactionSupport(file).subscribe((res:HttpResponse)=>{
       //TODO: Agregar mensaje de guardado del documento de transacción exitoso
    })
  }

}
