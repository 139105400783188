import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { HttpResponse } from '../../../../model/HttpResponse';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { FAVICON_LIPATI, FOTO_GUILLO } from '../../../../model/const/const';
import { GenderType } from '../../../../model/genderType';
import { IdType } from '../../../../model/idType';
import { Member } from '../../../../model/member';
import { AdministrationService } from '../../../../services/administration.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { PageHeaderComponent } from '../../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../../shared/tables/crud-table/crud-table.component';

@Component({
  selector: 'app-member-register',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  imports: [
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    CalendarModule,
    DropdownModule,
    InputNumberModule,
    ToastModule
  ],
  templateUrl: './member-register.component.html',
  styleUrl: './member-register.component.scss',
})
export class MemberRegisterComponent implements OnInit {
  @Input()
  idType!: IdType;

  @Input()
  idNumber!: string;

  @Input()
  isUpdateMoment: boolean = false;

  @Input()
  memberToUpdate!: Member;


  @Output()
  memberCreated:EventEmitter<any> = new EventEmitter();

  @Output()
  memberUpdated:EventEmitter<any> = new EventEmitter();

  @Output()
  closeDialogEvent:EventEmitter<any> = new EventEmitter();
  validateForm!: FormGroup;
  showForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];

  genderTypes = [
    { name: 'Femenino', value: GenderType.FEMENINO },
    { name: 'Masculino', value: GenderType.MASCULINO },
    { name: 'No Binario', value: GenderType.NO_BINARIO },
  ];

  
  idTypes = [
    { name: 'Registro Civil', value: IdType.REGISTRO_CIVIL },
    { name: 'Tarjeta de identidad', value: IdType.TARJETA_IDENTIDAD },
    { name: 'Cédula', value: IdType.CEDULA },
    { name: 'Pasaporte', value: IdType.PASAPORTE },
    { name: 'Cédula de Extranjería', value: IdType.CEDULA_EXTRANGERIA },
    {
      name: 'Documento de Identidad Extranjero',
      value: IdType.IDENTIDAD_EXTRANGERA,
    },
  ];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private administrationService: AdministrationService,
    private gestionService: GestionService,
    private loadingService: LoadingService,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {

    if(!this.isUpdateMoment){
      this.getFormNew();
      this.showForm = true;
    }else{
      this.getFormEdit(this.memberToUpdate);
      this.showForm = true;
    }
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      idType: [this.idType, [Validators.required]],
      idNumber: [
        this.idNumber,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
        ],
      ],
      name: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [
        null,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
        ],
      ],
      address: [null, [Validators.required]],
      birthDay: [null, [Validators.required]],
      birthPlace: [null],
      picture: [FAVICON_LIPATI, [Validators.required]],
      gender: [null, [Validators.required]],
      telephone: [null],
      city: [null],
      neighborhood: [null],
      bloodType: [null, [Validators.maxLength(3), Validators.minLength(2)]],
      emergencyContactName: [null, [Validators.required]],
      emergencyContactNumber: [
        null,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
        ],
      ],
      insurance: [null, [Validators.required]],
    });
    this.dialogHeader = 'Registro de Nuevo Alumno';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormEdit(item: Member) {
    this.validateForm = this.fb.group({
      id: [item.id],
      memberId: [`${item.idType}${item.idNumber}`],
      isGeneratedLicense: [item.digitalLicense!.generated || false],
      licenseId: [item.digitalLicense!.documentId],
      picture: [item.picture || FOTO_GUILLO],
      idType: [item.idType, [Validators.required]],
      idNumber: [
        item.idNumber,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(10),
        ],
      ],
      name: [item.name, [Validators.required]],
      lastName: [item.lastName, [Validators.required]],
      email: [item.contactInformation.email, [Validators.required, Validators.email]],
      mobile: [
        item.contactInformation.mobile,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
        ],
      ],
      address: [item.contactInformation.address, [Validators.required]],
      birthDay: [new Date(item.birthDay), [Validators.required]],
      birthPlace: [item.birthPlace],
      gender: [item.gender, [Validators.required]],
      telephone: [item.contactInformation.telephone],
      city: [item.contactInformation.city],
      neighborhood: [item.contactInformation.neighborhood],
      bloodType: [item.assistanceInformation.bloodType, [Validators.maxLength(3), Validators.minLength(2)]],
      emergencyContactName: [item.assistanceInformation.emergencyContactName, [Validators.required]],
      emergencyContactNumber: [
        item.assistanceInformation.emergencyContactNumber,
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(10),
        ],
      ],
      insurance: [item.assistanceInformation.insurance, [Validators.required]],
    });
    this.dialogHeader = `Actualizar información de ${item.name}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
    this.closeDialogEvent.emit();
  }

  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.MEMBERS,
      };
      this.gestionService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });

            this.memberCreated.emit(item);
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.MEMBERS,
        };
        this.gestionService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
              this.memberUpdated.emit(item);
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error actualizando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): Member {
    let item: Member = {
      name: form['name'].toUpperCase(),
      lastName: form['lastName'].toUpperCase(),
      idType: form['idType'],
      idNumber: `${form['idNumber']}`,
      birthDay: moment(form['birthDay']).format('YYYY-MM-DD'),
      birthPlace: form['birthPlace'],
      gender: form['gender'],
      picture:form['picture'],
      contactInformation: {
        email: form['email'],
        mobile: form['mobile'],
        telephone: form['mobile'],
        address: form['address']?.toUpperCase(),
        neighborhood: form['neighborhood'],
        city: form['city'],
      },

      assistanceInformation: {
        emergencyContactName: form['emergencyContactName'].toUpperCase(),
        emergencyContactNumber: form['emergencyContactNumber'],
        insurance: form['insurance'],
        bloodType: form['bloodType'],
      },
      memberId: `${form['idType']}${form['idNumber']}`,
      digitalLicense: {
        generated: form['isGeneratedLicense'] ? form['isGeneratedLicense'] : false,
        documentId: form['licenseId'] ? form['licenseId'] : '',
        isMigratedMember:false
      },
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }
}
