import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CommonModule } from '@angular/common';
import { CrudTable } from '../../../../model/CrudTable';

@Component({
  selector: 'app-crud-table',
  standalone: true,
  imports: [TableModule,ToolbarModule,ToastModule,ButtonModule,SelectButtonModule, CommonModule],
  templateUrl: './crud-table.component.html',
  styleUrl: './crud-table.component.scss'
})
export class CrudTableComponent {

  @Input()
  crudTable!:CrudTable;
  
  @Output()
  itemEdit:EventEmitter<any> = new EventEmitter();
  
  @Output()
  itemDelete:EventEmitter<any> = new EventEmitter();

  
  @Output()
  itemAdd:EventEmitter<any> = new EventEmitter();


  initEdit(item: any) {
    this.itemEdit.emit(item);
  }

  initDelete(item: any) {
    this.itemDelete.emit(item);
  }

  addItem(){
    this.itemAdd.emit();
  }

}
