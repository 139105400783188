import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';

@Component({
  selector: 'app-checker-home',
  standalone: true,
  providers:[MessageService],
  imports: [ DropdownModule,CommonModule,PanelModule,AvatarModule,AvatarGroupModule,ButtonModule,MenuModule,DividerModule,ZXingScannerModule],
  templateUrl: './checker-home.component.html',
  styleUrl: './checker-home.component.scss'
})
export class CheckerHomeComponent{

  menuItems = [
    {
      title: 'Check-in Alumnos',
      description:'Gestiona el ingreso a clases de los alumnos',
      class: 'pi pi-qrcode text-4xl text-green-500',
      routerLink: 'checkin',
    },
    // {
    //   title: 'Consulta Alumnos',
    //   description:'Consulta la información básica de los alumnos por el tipo y numero de identificación',
    //   class: 'pi pi-search text-4xl text-green-500',
    //   routerLink: 'checkerFind',
    // }
  ]
}
