<app-page-header
  [title]="'Sedes'"
  [subtitle]="'Módulo de administración de sedes'"
></app-page-header>
<app-crud-table
  [crudTable]="crudTableData"
  (itemEdit)="editItem($event)"
  (itemDelete)="deleteItem($event)"
  (itemAdd)="addItem()"
></app-crud-table>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>

<div *ngIf="showForm">
  <p-dialog
    [header]="dialogHeader"
    [(visible)]="showForm"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '80vw', height: '80vh', top: '2.5rem' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
  >
    <div class="card" [formGroup]="validateForm">
      <div class="flex flex-column gap-2">
        <label for="name">Nombre</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese nombre para la sede"
          formControlName="name"
        />
        <small id="name-small">Nombre de la Sede.</small>

        <label for="address">Dirección</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese la dirección de la sede"
          formControlName="address"
        />
        <small id="address-small">Dirección de la Sede.</small>

        <label for="telephone">Teléfono</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese el teléfono de la sede"
          formControlName="telephone"
        />
        <small id="address-small">Teléfono de la Sede.</small>

        <label for="telephone">Estado</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="isActive"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
        <small id="address-small">Estado de la Sede.</small>
      </div>

      <div class="flex mt-2 gap-2 justify-content-end">
        <p-button
          label="Cancelar"
          [outlined]="true"
          severity="secondary"
          [rounded]="true"
          (click)="showForm = false"
        ></p-button>
        <p-button
          *ngIf="isNewForm"
          type="submit"
          label="Guardar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="addNewItem()"
        ></p-button>
        <p-button
          *ngIf="isUpdateForm"
          type="submit"
          label="Actualizar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="editExistentItem()"
        ></p-button>
      </div>
    </div>
  </p-dialog>
</div>
<div *ngIf="showDependencies">
  <app-dependencies-list
    [dependencies]="dependenciesToDelete"
    (onCloseDependencies)="(closeDependenceDialog)"
  ></app-dependencies-list>
</div>
