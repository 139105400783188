<p-card class="member-preview col-12" *ngIf="showMemberInfo">
  <div class="">
    <app-member-information [member]="member" (memberUpdated)="memberUpdated($event)"></app-member-information>
  </div>

  <ng-template pTemplate="footer">
    <p-tabView styleClass="tabview-custom" [(activeIndex)]="activeIndex">
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-id-card mr-2"></i>
          <span>Matriculas & Renovaciones</span>
        </ng-template>
        <app-member-new-membership  [member]="member"
        [membershipsDatail]="membershipsDatail" (setNewStatus)="changeTab($event)" (refreshMeberships)="reloadMemberships()"></app-member-new-membership>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <i class="pi pi-book mr-2"></i>
          <span>Historial de Cursos</span>
        </ng-template>
        <app-member-courses
          [member]="member"
          [membershipsDatail]="membershipsDatail"
          (membershipIsUpdated)="reloadMemberships()"
        ></app-member-courses>
      </p-tabPanel>

    </p-tabView>
  </ng-template>
</p-card>
