import { Discount } from "./administrator/AgreementsAndDiscounts/Discount";
import { Product } from "./administrator/ProductAndServices/Product";

export interface AdministrativeData {
    applicationId:string,
    paymentInfo?:{
        productsAndServices:Product[],
        agreementsDiscount:Discount[],
        paymentData:{
            paymentMethod:PaymentMetod,
            amount:number
        }
    },
    legalizationInfo:Legalization,
    transactionDocId:string
}

export enum PaymentMetod{
    TRANSFERENCIA_BANCARIA = 'TRANSFERENCIA BANCARIA',
    CONSINGNACION_BANCARIA = 'CONSIGNACIÓN',
    TARJETA_DEBITO = 'TARJETA DÉBITO',
    TARJETA_CREDITO = 'TARJETA CRÉDITO',
    PAGO_EN_LINEA = 'PAGO EN LINEA'
}

export interface Legalization{
    isLegalized:boolean;
    legalizedType:LegalizationType
    leagalized_at:string,
    legalized_by:string,
    receiptOfPayment:string
}

export enum LegalizationType{
    DIGITAL = 'DIGITAL',
    MANUAL = 'MANUAL'
}