import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { LegalizationType } from '../../../../model/AdministrationData';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Membership } from '../../../../model/Membership';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';

@Component({
  selector: 'app-form-legalization',
  standalone: true,
  imports: [
    InputMaskModule,
    CommonModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    ToastModule,
    RadioButtonModule,
    DropdownModule,
  ],
  providers: [MessageService, ConfirmationService],
  templateUrl: './form-legalization.component.html',
  styleUrl: './form-legalization.component.scss',
})
export class FormLegalizationComponent implements OnInit {
  @Input()
  membership!: Membership;

  @Output()
  transactionLegalized:EventEmitter<any> = new EventEmitter();

  @Output()
  closeEditDialog:EventEmitter<any> = new EventEmitter();

  validateForm!: FormGroup;
  userName:string = '';
  showForm:boolean = false;
  showInputDocumentType:boolean = false;
  legalizationDocumentType: any[] = [
    {
      id: 1,
      value: 'POS',
      label: 'POS',
    },
    {
      id: 2,
      value: 'FACTURA_ELECTRONICA',
      label: 'FACTURA ELECTRONICA',
    }
  ];

  maskDocument!:string;
  placeholderDocument!:string;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private confirmationService: ConfirmationService,
    private gestionService: GestionService,
    private messageService:MessageService,
    private msalService: MsalService
  ) {}

  ngOnInit(): void {
    this.userName =
    this.msalService.instance.getActiveAccount()?.idTokenClaims?.name || '';
    this.buildForm();
    if(this.membership.administrativeData.paymentInfo?.agreementsDiscount && this.membership.administrativeData.paymentInfo?.agreementsDiscount.length > 0){
      this.legalizationDocumentType.push(    {
        id: 3,
        value: 'CONVENIO_DESCUENTO',
        label: 'CONVENIO O DESCUENTO NO FACTURADO'
      })
    }
  }

  buildForm() {
    this.validateForm = this.fb.group({
      receiptOfPayment: [null, [Validators.required]],
      legalizationDocumentType:[null,[Validators.required]]
    });

    this.showForm = true;
  }


  cancelDialogForm(){
    this.showForm = false;
    this.validateForm.reset();
   
    this.closeEditDialog.emit();
  }

  markAsLegalized() {
    if(this.validateForm.valid){
      let membership:Membership = this.membership;
      this.confirmationService.confirm({
        message: `¿Confirma legalizar la matricula ${membership.id} con el número de factura ${this.validateForm.get('receiptOfPayment')?.value}?`,
        header: 'Confirmar',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.loadingService.showLoading();
          membership.administrativeData.legalizationInfo!.isLegalized = true;
          membership.administrativeData.legalizationInfo!.leagalized_at =
            moment().format('YYYY-MM-DD HH:mm:ss');
          membership.administrativeData.legalizationInfo!.legalizedType =
            LegalizationType.MANUAL;
          membership.administrativeData.legalizationInfo!.legalized_by =
            this.userName;
          membership.administrativeData.legalizationInfo.receiptOfPayment = this.validateForm.get('receiptOfPayment')!.value;
  
          const entity: AdministratorEntity = {
            operation: AcademyAdministratorOperation.UPDATE,
            payload: membership,
            type: AcademyAdministratorType.MEMBERSHIPS,
          };
          this.gestionService
            .sendRequest(entity)
            .subscribe((res: HttpResponse) => {
              if (res.code === 200) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'La transacción se ha legalizado correctamente!',
                  life: 3000,
                });
                this.transactionLegalized.emit(membership);
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail:
                    'Ocurrió un error registrando la legalización, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                  life: 3000,
                });
              }
              this.loadingService.hideLoading();
            });
        },
      });
    }else{
      this.messageService.add({
        severity:'warn',
        detail:'Complete todos los campos obligatorios'
      })
    }

  }

  onChangeDocumentType(documentType:any){
    switch (documentType.value) {
      case "POS":
        
        break;
    
      default:
        break;
    }
  }
}
