import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Membership, MembershipDetail } from '../../../model/Membership';
import { DialogModule } from 'primeng/dialog';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AcademyQualification } from '../../../model/AcademyState';
import { LoadingService } from '../../../services/loading.service';
import { GestionService } from '../../../services/gestion.service';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { HttpResponse } from '../../../model/HttpResponse';
import moment from 'moment';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-instructor-seasons-member-qualification',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  imports: [   DialogModule,CommonModule,ButtonModule,ConfirmDialogModule],
  templateUrl: './instructor-seasons-member-qualification.component.html',
  styleUrl: './instructor-seasons-member-qualification.component.scss'
})
export class InstructorSeasonsMemberQualificationComponent implements OnInit{


  @Input()
  membershipDetail!:MembershipDetail;

  @Output()
  closeQualificationDialog: EventEmitter<any> = new EventEmitter();


  @Output()
  academyQualificationChanged: EventEmitter<any> = new EventEmitter();

  header: string = '';
  showForm: boolean = true;
  showDialogForm: boolean = true;
  userMail:string = '';
  constructor(private confirmationService: ConfirmationService, private messageService: MessageService, private loadingService:LoadingService, private gestionService:GestionService,  private msalService: MsalService ){
    
  }
  ngOnInit(): void {
   this.header = `${this.membershipDetail.member.name} ${this.membershipDetail.member.lastName} | ${this.membershipDetail.level!.name} | ${this.membershipDetail.course.name}`
   this.userMail = this.msalService.instance.getActiveAccount()?.idTokenClaims?.preferred_username || '';
  }


  // TODO: establecer Confirms para estado de aprobación del curso por parte del instructor
  approved(event: Event){
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `¿Confirma que el concepto para ${this.membershipDetail.member.name} ${this.membershipDetail.member.lastName} es APROBADO?`,
      header: 'Confirmación de Calificación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.updateMembership(AcademyQualification.APPROVED);
      },
      reject: () => {
         this.cancelDialogForm();
      }
  });
  }

  rejected(event: Event){
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `¿Confirma que el concepto para ${this.membershipDetail.member.name} ${this.membershipDetail.member.lastName} es NO APROBADO?`,
      header: 'Confirmación de Calificación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.updateMembership(AcademyQualification.FAILED);
      },
      reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
  });
  }

  inProgress(event: Event){
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: `¿Confirma que desea mantener en curso a ${this.membershipDetail.member.name} ${this.membershipDetail.member.lastName}? tenga en cuenta que si el alumno presenta un nivel pendiente por calificar, no podrá realizar la matricula en un siguiente nivel.`,
      header: 'Confirmación de Calificación',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
          this.updateMembership(AcademyQualification.IN_PROGRESS);
      },
      reject: () => {
          this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
      }
  });
  }

  cancelDialogForm() {
    this.showDialogForm = false;
    this.closeQualificationDialog.emit();
  }


  async updateMembership(academyQualification:AcademyQualification){
        this.loadingService.showLoading();
        const item = await this.buildAcademyItem(this.membershipDetail,academyQualification);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.MEMBERSHIPS,
        };
        this.gestionService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.cancelDialogForm();
            this.showForm = false;
            this.academyQualificationChanged.emit();
            this.loadingService.hideLoading();
          });
  }


  async buildAcademyItem(membershipDetail: MembershipDetail, academyQualification:AcademyQualification): Promise<Membership> {
    let item: Membership = {
      ...membershipDetail,
      academyState: {
        state: academyQualification,
        attendances: membershipDetail.academyState.attendances
      },
      memberId: membershipDetail.member.memberId,
      headquarterId: membershipDetail.classGroup.headquarterId,
      courseId: membershipDetail.classGroup.courseId,
      levelId: membershipDetail.classGroup.levelId,
      seasonId: membershipDetail.classGroup.seasonId,
      classGroupId: membershipDetail.classGroup.id!,
      auditData:{
        ...membershipDetail.auditData,
        updated_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        updater: this.userMail
      }
    };
    return item;
  }




}
