export interface AcademyState{
    state:AcademyQualification;
    attendances:Attendance[]
}

export interface Attendance{
    date:string;
    registerBy:string;
    headquarterId:string;
}

export enum AcademyQualification{
    PENDING = 'pending',
    IN_PROGRESS = 'InProgress',
    APPROVED = 'approved',
    FAILED = 'failed',
    CANCELED = 'canceled',
    NOT_REGISTERED = 'not_registered'
}