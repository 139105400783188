import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import FileSaver from 'file-saver';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Table, TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { catchError, throwError } from 'rxjs';
import { ClassGroup, ClassGroupDetail } from '../../../model/ClassGroup';
import { Course } from '../../../model/Course';
import { CrudTable } from '../../../model/CrudTable';
import { Headquarters } from '../../../model/Headquarter';
import { HttpResponse } from '../../../model/HttpResponse';
import { Instructor } from '../../../model/Instructor';
import { Level } from '../../../model/Level';
import { Sch } from '../../../model/Sch';
import { Season } from '../../../model/Season';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';

@Component({
  selector: 'app-groups',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  imports: [
    FormsModule,
    CommonModule,
    DropdownModule,
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    MultiSelectModule,
    TableModule,ToolbarModule,ToastModule,ButtonModule,  DropdownModule,InputNumberModule
  ], 
  templateUrl: './groups.component.html',
  styleUrl: './groups.component.scss',
})
export class GroupsComponent implements OnInit {
  headqueters: Headquarters[] = [];
  seasons: Season[] = [];
  courses: Course[] = [];
  levels: Level[] = [];
  groups: ClassGroup[] = [];
  recurrentGroupsAvailables: ClassGroup[] = [];
  instructors:Instructor[] = [];
  schs:Sch[] = [];
  availableGroups: ClassGroupDetail[] = [];
  showTable:boolean = false;
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];
  header: string = '';
  viewSelected: any = '';
  seasonSelected!:Season;
  seasonsToClone:Season[] = [];
  recurrentSeasonSelected!:Season;
  recurrentSelectionModalVisible: boolean = false;
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private administrationService: AdministrationService,
    private loadingService: LoadingService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
   this.setInitialSeasons();
  }

  async setInitialSeasons(){
    this.seasons = await this.getSeasons();
  }

  onSeasonSelected(season:Season){
    
    this.seasonSelected = season;
    this.getSeasonsToClone();
    this.selectClassGroups();
  }
  async selectClassGroups() {
    this.groups = [];
    this.loadingService.showLoading();
    this.showTable = false;
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'seasonId',
              parameterName: '@Id',
              parameterValue: this.seasonSelected.id,
            }
          ],
        },
      },
      type: AcademyAdministratorType.GROUPS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe(async (res: HttpResponse) => {
        if (res.response.length > 0) {
          this.groups = res.response;
          this.getDetailOfGroups(res.response);
        }else{
          await this.buildMasterOptions();
          this.availableGroups = [];
          this.buildCrudTable(this.availableGroups);
          this.showTable = true;
          this.loadingService.hideLoading();
        }
  
      });
  }

  async buildMasterOptions() {
    this.instructors = await this.getInstructors();
    this.headqueters = await this.getHeadquarter();
    this.courses = await this.getCourses();
    this.seasons = await this.getSeasons();
    this.schs = (await this.getSchs()).map(sch => {
      
       return this.getSchHumanLabel(sch);
    });
  }

  async getInstructors(): Promise<Instructor[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.INSTRUCTORS,
      true,
      'isActive'
    );
    return groupData as Instructor[];
  }


  async getHeadquarter(): Promise<Headquarters[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.HEADQUARTERS,
      true,
      'isActive'
    );
    return groupData as Headquarters[];
  }

  async getCourses(): Promise<Course[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.COURSES_LEVELS,
      true,
      'isActive'
    );
    return groupData as Course[];
  }

  async getSeasons(): Promise<Season[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SEASONS,
      true,
      'availableToAdministrativeGestion'
    );
    return groupData as Season[];
  }

  async getSchs(): Promise<Sch[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SCHS,
      true,
      'isActive'
    );
    return groupData as Sch[];
  }

  async getDetailOfGroups(generalGroups: ClassGroup[]) {
    this.availableGroups = [];
    await this.buildMasterOptions();
    for await (let group of generalGroups) {
      let groupDetail = this.getSchDetailCahed(group.schId) || await this.getSchDetail(group.schId);
      let classGroupDetail: ClassGroupDetail = {
        ...group,
        sch: groupDetail,
        headquarter: this.getHeadquarterDetail(group.headquarterId),
        course: this.getCourseDetail(group.courseId),
        level: this.getLevelDetail(group.levelId),
        season: this.getSeasonDetail(group.seasonId),
        name: `${group.classGroupId} - ${groupDetail.days} ${groupDetail.startTime} - ${groupDetail.endTime} | Inscritos: ${group.state.membersCount} de ${group.maxMembers}`,
        isComplete: group.state.membersCount < group.maxMembers ? false : true,
        instructor: this.getInstructorDetail(group.instructorId!)
      };
      if (!this.availableGroups.some((x) => x.id === classGroupDetail.id)) {
        this.availableGroups.push(classGroupDetail);
      }
    }
    this.buildCrudTable(this.availableGroups);
    this.showTable = true;
    this.loadingService.hideLoading();
  }

  getAvailableLevels() {
    this.validateForm.get('level')?.setValue(null);
    let availableLevels = this.validateForm
      .get('course')
      ?.value['levels'].map((level: Level) => {
        return level;
      });
    this.levels = availableLevels;
  }

  async getSchDetail(id: string): Promise<Sch> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SCHS,
      id,
      'id'
    );
    return groupData[0] as Sch;
  }

  getSchDetailCahed(id: string): Sch | undefined {
    return this.schs.find((x:Sch) => x.id === id);
  }

  async getEntity(
    academyAdministratorType: AcademyAdministratorType,
    value: any,
    property?: string
  ): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();

      let data: any[] = [];
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: property || 'id',
                parameterName: '@Option1',
                parameterValue: value,
              },
            ],
          },
        },
        type: academyAdministratorType,
      };
      try {
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.response.length > 0) {
              data = res.response as any[];
            }
            resolve(data);
            this.loadingService.hideLoading();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getHeadquarterDetail(id: string): any {
    return this.headqueters.find((x) => x.id === id);
  }
  getCourseDetail(id: string): any {
    return this.courses.find((x) => x.id === id);
  }

  getLevelDetail(id: string): any {
    let curso = this.courses.find(curso => curso.levels.some(level => level.id === id));
    let level = curso?.levels.find(level => level.id === id);

    return level;
  }

  
  getSeasonDetail(id: string): any {
    return this.seasons.find((x) => x.id === id);
  }

    
  getInstructorDetail(id: string): any {
    return this.instructors.find((x) => x.id === id);
  }


  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [
        {
          label: 'id',
          sorted: true,
          sortItem: 'classGroupId',
        },
        {
          label: 'Sede',
          sorted: true,
          sortItem: 'headquarter.name',
        },
        {
          label: 'Curso',
          sorted: true,
          sortItem: 'course.name',
        },
        {
          label: 'Nivel',
          sorted: true,
          sortItem: 'level.name',
        },
        {
          label: 'Horario',
          sorted: true,
          sortItem: 'level.name',
        },
        {
          label: 'Ciclo',
          sorted: true,
          sortItem: 'season.name',
        },
        {
          label: 'Cap. Max',
          sorted: true,
          sortItem: 'maxMembers',
        },
        {
          label: 'Inscritos',
          sorted: true,
          sortItem: 'state.membersCount',
        },
        {
          label: 'Recurrente',
          sorted: true,
          sortItem: 'isRecurrent',
        },
        {
          label: 'Activa',
          sorted: true,
          sortItem: 'state.isActive',
        },
        {
          label: 'Instructor',
          sorted: true,
          sortItem: 'instructor',
        },
        {
          label: 'Acciones',
          sorted: false,
          sortItem: null,
        },
      ],
      items: ['classGroupId','headquarter.name', 'course.name', 'level.name', 'sch','season.name','maxMembers','state','isActive','instructor.name'],
    };
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: ClassGroupDetail) {
     this.getFormRenewal(item);
  }

  deleteItem(item: ClassGroup) {
    this.confirmationService.confirm({
      message: 'Estas seguro de borrar este registro ?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.DELETE,
          payload: item,
          type: AcademyAdministratorType.GROUPS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha eliminado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectClassGroups();
            this.showForm = false;
          });
      },
    });
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      headquarter: [null, [Validators.required]],
      course: [null, [Validators.required]],
      level: [null, [Validators.required]],
      season: [this.seasonSelected, [Validators.required]],
      sch:[null,[Validators.required]],
      maxMembers:[0,[Validators.required]],
      isActive: [true,[Validators.required]],
      isRecurrent:[true,[Validators.required]],
      classCount:[0,[Validators.required]],
      instructor:[null, [Validators.required]],
      membersCount:[0,[Validators.required]],
      sessionsNumber:[0,[Validators.required]]
    });
    this.dialogHeader = 'Nuevo Grupo';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormRenewal(item: ClassGroupDetail) {
    this.validateForm = this.fb.group({
      id:[item.id,[Validators.required]],
      headquarter: [item.headquarter, [Validators.required]],
      course: [item.course, [Validators.required]],
      level: [item.level,[Validators.required]],
      season: [item.season, [Validators.required]],
      sch:[this.getSchHumanLabel(item.sch),[Validators.required]],
      maxMembers:[item.maxMembers,[Validators.required]],
      isActive: [item.state.isActive,[Validators.required]],
      isRecurrent:[item.isRecurrent,[Validators.required]],
      classCount:[item.state.classCount,[Validators.required]],
      instructor:[item.instructor, [Validators.required]],
      membersCount:[item.state.membersCount,[Validators.required]],
      classGroupId:[item.classGroupId,[Validators.required]],
      classRegister:[item.state.classRegister],
      sessionsNumber:[item.state.sessionsNumber || item.level?.clases]
    });
    this.dialogHeader = `Editar ${item.classGroupId}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
  }

  getSchHumanLabel(sch:Sch):Sch{
    sch.days = `${sch.days} ${sch.startTime} - ${sch.endTime}`;
    return sch;
  }

  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.GROUPS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.selectClassGroups();
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.GROUPS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectClassGroups();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): ClassGroup {
    let item: ClassGroup = {
      headquarterId: form['headquarter']['id'],
      classGroupId: form['classGroupId'] ? form['classGroupId'] : `GRUPO_${new Date().getTime()}` ,
      courseId: form['course']['id'],
      levelId: form['level']['id'],
      seasonId: form['season']['id'],
      schId:form['sch']['id'],
      isRecurrent:form['isRecurrent'],
      maxMembers: form['maxMembers'],
      state:{
        classCount: form['classCount'],
        isActive:form['isActive'],
        membersCount:form['membersCount'],
        classRegister:form['classRegister'] || [],
        sessionsNumber:form['sessionsNumber']
      },
      instructorId:form['instructor']['id']
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }


  exportExcel() {
    import('xlsx').then((xlsx) => {
      let dataToPrint: [] = this.getDataToPrint();
      const worksheet = xlsx.utils.json_to_sheet(dataToPrint);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, this.header);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  
  getDataToPrint(): any {
    switch (this.viewSelected) {
      case 'pendigLegalize':
        this.header = `Reporte de Matriculas Pendientes por Legalizar ${moment().format(
          'YYYY-MM-DD HH:mm:ss'
        )}`;
        const dataToPrintPending = this.groups.map(
          (membership: ClassGroup) => {
            let memberDetailed = {
              // Alumno: membership.memberId,
              // 'Método pago':
              //   membership.administrativeData.paymentInfo?.paymentData
              //     .paymentMethod,
              // Valor:
              //   membership.administrativeData.paymentInfo?.paymentData.amount,
              // 'Fecha Matricula': membership.auditData.created_at,
              // 'Matriculado por': membership.auditData.creator,
              // Estado:
              //   membership.administrativeData.legalizationInfo?.isLegalized ||
              //   false,
            };

            return memberDetailed;
          }
        );

        return dataToPrintPending;
      case 'legalized':
        this.header = `Reporte de Matriculas Legalizadas ${moment().format(
          'YYYY-MM-DD HH:mm:ss'
        )}`;
        const dataToPrintLegalized = this.groups.map(
          (membership: ClassGroup) => {
            let memberDetailed = {
              // Alumno: membership.memberId,
              // 'Método pago':
              //   membership.administrativeData.paymentInfo?.paymentData
              //     .paymentMethod,
              // Valor:
              //   membership.administrativeData.paymentInfo?.paymentData.amount,
              // 'Fecha Matricula': membership.auditData.created_at,
              // 'Matriculado por': membership.auditData.creator,
              // Estado:
              //   membership.administrativeData.legalizationInfo?.isLegalized ||
              //   false,
              // 'Fecha Legalización':
              //   membership.administrativeData.legalizationInfo?.leagalized_at,
              // Legalizó:
              //   membership.administrativeData.legalizationInfo?.legalized_by,
              // Factura:
              //   membership.administrativeData.legalizationInfo
              //     ?.receiptOfPayment,
            };

            return memberDetailed;
          }
        );

        return dataToPrintLegalized;
    }
  }

  clear(table: Table) {
    table.clear();
  }

  selectRecurrentOrigin(){
    this.recurrentSelectionModalVisible = true;
  }
  createReccurentGroups(){
    this.confirmationService.confirm({
      message: `¿Estas seguro de clonar todos los grupos marcados como recurrentes del ciclo ${this.recurrentSeasonSelected.name}  en el ciclo ${this.seasonSelected.name}?`,
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.recurrentSelectionModalVisible = false;
        this.loadingService.showLoading();
        const item = {
          originSeason:this.recurrentSeasonSelected,
          targetSeason:this.seasonSelected
        };
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.CREATE,
          payload: item,
          type: AcademyAdministratorType.MASSIVE_GROUPS,
        };
        this.administrationService
          .sendRequest(entity)
          .pipe(catchError(this.handleError.bind(this)))
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Los registros crearon correctamente!',
                life: 3000,
              });
            }
            this.selectClassGroups();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
      closeOnEscape:true,
      reject:()=>{
        this.recurrentSelectionModalVisible = false;
      }
    });
  }
  getSeasonsToClone(){
    this.seasonsToClone =  this.seasons.filter((season:Season) => season.id !== this.seasonSelected.id);
  }


  handleError(error: HttpErrorResponse) {
    this.loadingService.hideLoading();
    if (error.status === 422) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Alerta',
        detail:
          'Ocurrió un error procesando la operación, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
        life: 3000,
      });
      console.error('An error occurred:', error.error);
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          'Ocurrió un error creando los registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
        life: 3000,
      });
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      () => new Error('Something bad happened; please try again later.')
    );
  }

}
