
<p-dialog header="Eliminación no permitida" [(visible)]="visible" [style]="{ width: '70vw', height: '400px' }" [closeOnEscape]="false" (onHide)="closeDepenedenceDialog()">

    <div>
        <p>
            No puede eliminar este item directamente debido a que presenta las siguientes dependencias, si desea eliminar este item definitivamente debe eliminar las dependencias primero.
        </p>
    </div>

    <p-orderList [value]="dependencies" [listStyle]="{ height: '25rem' }" header="Dependencias" [controlsPosition]="'right'">
        <ng-template let-product pTemplate="item">
            <div class="flex flex-wrap p-2 align-items-center gap-3">
                <div class="flex-1 flex flex-column gap-2">
                    <span class="font-bold">Id: {{ product.id }}</span>
                    <div class="flex align-items-center gap-2">
                        <i class="pi pi-tag text-sm"></i>
                        <span>Nombre: {{ product.name }}</span>
                    </div>
                </div>
                <span class="font-bold text-900">{{getType(product.type) }}</span>
            </div>
        </ng-template>
    </p-orderList>
    
</p-dialog>
