import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';

export const AdminRoleGuard: CanActivateFn = (route, state) => {
  const _msalService = inject(MsalService);
  let userRoles:string[] = _msalService.instance.getActiveAccount()?.idTokenClaims?.roles || [];
  return userRoles.includes(environment.roles.adminRole);
};
