import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SelectButtonModule } from 'primeng/selectbutton';
import { v4 as uuidv4 } from 'uuid';
import { CrudTable } from '../../../model/CrudTable';
import { HttpResponse } from '../../../model/HttpResponse';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { Product } from '../../../model/administrator/ProductAndServices/Product';
import { ProductType } from '../../../model/enum/ProductType';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { PageHeaderComponent } from "../../shared/page-header/page-header.component";
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
@Component({
    selector: 'app-productos-servicios',
    standalone: true,
    templateUrl: './productos-servicios.component.html',
    styleUrl: './productos-servicios.component.scss',
    providers: [MessageService, ConfirmationService],
    imports: [InputTextareaModule,CalendarModule,InputMaskModule,SelectButtonModule,InputSwitchModule,InputTextModule,PageHeaderComponent, CrudTableComponent,ConfirmDialogModule,CommonModule,DialogModule,ReactiveFormsModule]
})
export class ProductosServiciosComponent implements OnInit{
    crudTableData: CrudTable = {
        data: [],
        dataKey: '',
        headers: [],
        items: [],
      };
    
      validateForm!: FormGroup;
      showForm: boolean = false;
      isNewForm: boolean = false;
      isUpdateForm: boolean = false;
      dialogHeader?: string;
      stateOptions: any[] = [
        { label: 'No', value: false },
        { label: 'Si', value: true },
      ];

      productTypes: any[] = [
        { label: 'PRODUCTO', value: ProductType.PRODUCT },
        { label: 'SERVICIO', value: ProductType.SERVICE },
      ];
      constructor(
        private fb: FormBuilder,
        private loadingService: LoadingService,
        private administrationService: AdministrationService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        
      ) {}
      ngOnInit(): void {
        this.selectProductAndServices();
      }
    
      async selectProductAndServices() {
        this.loadingService.showLoading();
        let entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.SELECT,
          payload: {
            options: null,
          },
          type: AcademyAdministratorType.PRODUCTS_SERVICES,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            this.buildCrudTable(res.response);
            this.loadingService.hideLoading();
          });
      }
    
      buildCrudTable(data: any[]) {
        this.crudTableData = {
          data: data,
          dataKey: 'id',
          headers: [
            {
              label: 'Tipo',
              sorted: true,
              sortItem: 'type',
            },
            {
              label: 'Nombre',
              sorted: true,
              sortItem: 'name',
            },
            {
              label: 'Precio',
              sorted: true,
              sortItem: 'price',
            },
            {
              label: 'Aplica Iva',
              sorted: true,
              sortItem: 'hasTax',
            },
            {
                label: 'Iva',
                sorted: true,
                sortItem: 'taxValue',
              },
              {
                label: 'Descripción',
                sorted: true,
                sortItem: 'description',
              },
              {
                label: 'Activo',
                sorted: true,
                sortItem: 'isActive',
              },
            {
              label: 'Acciones',
              sorted: false,
              sortItem: null,
            },
          ],
          items: ['type','name', 'price','hasTax','taxValue','description','isActive'],
        };
      }
    
      addItem() {
        this.getFormNew();
      }
    
      editItem(item: Product) {
        this.getFormRenewal(item);
      }
    
      deleteItem(item: Product) {
        this.confirmationService.confirm({
          message: 'Estas seguro de borrar este registro ?',
          header: 'Confirmar',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.loadingService.showLoading();
            const entity: AdministratorEntity = {
              operation: AcademyAdministratorOperation.DELETE,
              payload: item,
              type: AcademyAdministratorType.PRODUCTS_SERVICES,
            };
            this.administrationService
              .sendRequest(entity)
              .subscribe((res: HttpResponse) => {
                if (res.code === 200) {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'OK',
                    detail: 'El registro se ha eliminado correctamente!',
                    life: 3000,
                  });
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail:
                      'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                    life: 3000,
                  });
                }
                this.selectProductAndServices();
                this.loadingService.hideLoading();
              });
          },
        });
      }
    
      getFormNew() {
        this.validateForm = this.fb.group({
          name: [null, [Validators.required]],
          type: [null,[Validators.required]],
          price: [null, [Validators.required]],
          hasTax: [null,[Validators.required]],
          taxValue:[0],
          description: [null, [Validators.required]],
          isActive: [true, [Validators.required]]
        });
        this.dialogHeader = 'Nuevo Producto o Servicio';
        this.isNewForm = true;
        this.isUpdateForm = false;
        this.showForm = true;
      }
    
      getFormRenewal(item: Product) {
        this.validateForm = this.fb.group({
          id: [item.id, [Validators.required]],
          name: [item.name, [Validators.required]],
          type: [item.type, [Validators.required]],
          price: [item.price, [Validators.required]],
          hasTax: [item.hasTax,[Validators.required]],
          taxValue:[item.taxValue,[Validators.required]],
          description: [item.description, [Validators.required]],
          isActive: [item.isActive, [Validators.required]],
          productServiceId: [item.productServiceId],
        });
        this.dialogHeader = `Editar ${item.name}`;
        this.isNewForm = false;
        this.isUpdateForm = true;
        this.showForm = true;
      }
    
      closeDialog() {
        this.showForm = false;
      }
    
      addNewItem() {
        if (this.validateForm.valid) {
          this.loadingService.showLoading();
          const item = this.buildAcademyItem(this.validateForm.value);
          const entity: AdministratorEntity = {
            operation: AcademyAdministratorOperation.CREATE,
            payload: item,
            type: AcademyAdministratorType.PRODUCTS_SERVICES,
          };
          this.administrationService
            .sendRequest(entity)
            .subscribe((res: HttpResponse) => {
              if (res.code === 200) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'El registro se ha creado correctamente!',
                  life: 3000,
                });
              } else {
                this.messageService.add({
                  severity: 'error',
                  summary: 'Error',
                  detail:
                    'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                  life: 3000,
                });
              }
              this.selectProductAndServices();
              this.showForm = false;
              this.loadingService.hideLoading();
            });
        } else {
          this.messageService.add({
            severity: 'warning',
            summary: 'Verificar',
            detail:
              'Verifique que todos los campos obligatorios del formulario estén completos',
            life: 3000,
          });
        }
      }
    
      editExistentItem() {
        this.confirmationService.confirm({
          message: '¿Estas seguro de actualizar este registro?',
          header: 'Confirmar',
          acceptLabel: 'Si',
          rejectLabel: 'No',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.loadingService.showLoading();
            const item = this.buildAcademyItem(this.validateForm.value);
            const entity: AdministratorEntity = {
              operation: AcademyAdministratorOperation.UPDATE,
              payload: item,
              type: AcademyAdministratorType.PRODUCTS_SERVICES,
            };
            this.administrationService
              .sendRequest(entity)
              .subscribe((res: HttpResponse) => {
                if (res.code === 200) {
                  this.messageService.add({
                    severity: 'success',
                    summary: 'OK',
                    detail: 'El registro se ha actualizado correctamente!',
                    life: 3000,
                  });
                } else {
                  this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail:
                      'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                    life: 3000,
                  });
                }
                this.selectProductAndServices();
                this.showForm = false;
                this.loadingService.hideLoading();
              });
          },
        });
      }
    
      buildAcademyItem(form: any): Product {
        let item: Product = {
          name: form['name'].toUpperCase(),
          type: form['type'],
          productServiceId: form['productServiceId'] || uuidv4(),
          price: form['price'],
          hasTax: form['hasTax'],
          taxValue:form['taxValue'],
          description: form['description'],
          isActive: form['isActive']
        };
    
        if (form['id']) {
          item.id = form['id'];
        }
    
        return item;
      }
}
