<div *ngIf="showForm">
    <p-dialog
      [header]="header"
      [(visible)]="showDialogForm"
      [modal]="true"
      [draggable]="true"
      [resizable]="true"
      (onHide)="cancelDialogForm()"

    >

    <span class="p-buttonset btn-qualification-container">
        <p-button class="btn-qualification" label="Aprobado" icon="pi pi-check" severity="primary" (click)="approved($event)"></p-button>
        <p-button class="btn-qualification" label="No Aprobado" icon="pi pi-times" severity="danger" (click)="rejected($event)"></p-button>
        <p-button class="btn-qualification" label="En Curso" icon="pi pi-reply" severity="info" (click)="inProgress($event)"></p-button>
    </span>

    </p-dialog>
  </div>
  <p-confirmDialog></p-confirmDialog>