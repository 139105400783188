import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-instructor-submenu',
  standalone: true,
  imports: [ImageModule, CommonModule,RouterLink, RouterLinkActive, RouterOutlet],
  templateUrl: './instructor-submenu.component.html',
  styleUrl: './instructor-submenu.component.scss'
})
export class InstructorSubmenuComponent {
  menuItems = [

    {
      title: 'Ciclos & Grupos',
      description:'Gestiona los grupos que te fueron  asignados en los diferentes ciclos',
      class: 'pi pi-calendar-plus text-4xl text-green-500',
      routerLink: '/instructor/ciclos',
    }
  ]
}
