import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { OrderListModule } from 'primeng/orderlist';
import { Dependence } from '../../../model/Dependencie';
@Component({
  selector: 'app-dependencies-list',
  standalone: true,
  imports: [DialogModule,OrderListModule],
  templateUrl: './dependencies-list.component.html',
  styleUrl: './dependencies-list.component.scss'
})
export class DependenciesListComponent implements OnInit{

  visible:boolean = false;

  @Input()
  dependencies!:Dependence[]

  @Output()
  onCloseDependencies: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.visible = true;
  }


  getType(type:string):string{
    switch (type) {
      case 'SEASON':
        return 'Ciclo'
      default:
        return 'Grupo'
    }
  }

  closeDepenedenceDialog(){
    this.visible = false;
    this.onCloseDependencies.emit()
  }
  
}
