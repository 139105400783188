import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MembershipDetail } from '../../../../model/Membership';
import { PdfComponent } from "../../../shared/pdf/pdf.component";
import { ButtonModule } from 'primeng/button';
@Component({
    selector: 'app-member-resume-membership',
    standalone: true,
    templateUrl: './member-resume-membership.component.html',
    styleUrl: './member-resume-membership.component.scss',
    imports: [PdfComponent,ButtonModule]
})
export class MemberResumeMembershipComponent implements OnInit{

  @Input()
  membershipDetail!:MembershipDetail;

  @Output()
  endMembership: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    
  }

  finishMembership(){
    this.endMembership.emit();
  }



}
