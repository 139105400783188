import { Injectable } from '@angular/core';
import { HttpResponse } from '../model/HttpResponse';
import { AcademyAdministratorOperation } from '../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../model/administrator/AdministratorEntity';
import { AdministrationService } from './administration.service';
import { CallHttpService } from './call-http.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(
    private callHttpService: CallHttpService,
    private loadingService: LoadingService,
    private administrationService: AdministrationService
  ) {}

  dependenciesToDelete(administratorEntity: AdministratorEntity): Promise<any> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();
      let data: any[] = [];
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty:
                  this.getPropertyToEvaluate(administratorEntity),
                parameterName: '@Option1',
                parameterValue: administratorEntity.payload.id,
              },
            ],
          },
        },
        type: AcademyAdministratorType.GROUPS,
      };
      try {
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.response.length > 0) {
              data = res.response as any[];
            }
            resolve(data);
            this.loadingService.hideLoading();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  private getPropertyToEvaluate(
    administratorEntity: AdministratorEntity
  ): string {
    let propertyString = 'id';
    switch (administratorEntity.type) {
      case AcademyAdministratorType.SCHS:
        propertyString = 'schId';
        break;
      case AcademyAdministratorType.SEASONS:
        propertyString = 'seasonId';
        break;
      case AcademyAdministratorType.HEADQUARTERS:
        propertyString = 'headquarterId';
        break;
      case AcademyAdministratorType.COURSES_LEVELS:
        propertyString = 'courseId';
        break;
      case AcademyAdministratorType.INSTRUCTORS:
        propertyString = 'instructorId';
        break;

      default:
        break;
    }

    return propertyString;
  }
}
