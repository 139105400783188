import { Injectable } from '@angular/core';
import { CallHttpService } from './call-http.service';
import { AdministratorEntity } from '../model/administrator/AdministratorEntity';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ValidationService } from './validation.service';
import { AcademyAdministratorOperation } from '../model/administrator/AcademyAdministratorOperation';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(private callHttpService: CallHttpService) { }
  sendRequest(entity:AdministratorEntity):Observable<any>{
    let service = entity.type;
    return this.callHttpService.post(`${environment.academyApi.administration}/${service}`,entity);
  }

}
