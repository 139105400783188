<app-page-header [title]="'Ciclos & Grupos'" [subtitle]="'Módulo de gestión de ciclos y grupos'"></app-page-header>

<div class="card" *ngIf="showSeasons">
    <p-table
        #dt1
        [value]="seasons"
        dataKey="id"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loading"
        [paginator]="true"
        currentPageReportTemplate="Mostrando desde {first} hasta {last} de {totalRecords} registros"
        [globalFilterFields]="['name', 'startDate', 'endDate']"
        responsiveLayout="stack" [breakpoint]="'960px'"
        >
        <ng-template pTemplate="caption">
                <button pButton label="Limpiar Filtros" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt1.filterGlobal($any($event.target).value, 'contains')" placeholder="Busqueda por palabras" />
                </span>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="min-width:15rem">
                    <div class="flex align-items-center">
                        Nombre
                        <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                    </div>
                </th>
                <th style="min-width:15rem">
                    <div class="flex align-items-center">
                        Fecha Inicio
                        <p-columnFilter field="starDate" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">Agent Picker</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="seasons" placeholder="Any" (onChange)="filter($event.value)" optionLabel="startDate">
                                    <ng-template let-option pTemplate="item">
                                        <div class="inline-block vertical-align-middle">
                                            <!-- <img [alt]="option.label" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ option.image }}" width="24" class="vertical-align-middle" /> -->
                                            <span class="ml-1 mt-1">{{ option.name }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th style="min-width:15rem">
                    <div class="flex align-items-center">
                        Fecha Fin
                        <p-columnFilter field="endDate" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                            <ng-template pTemplate="header">
                                <div class="px-3 pt-3 pb-0">
                                    <span class="font-bold">Agent Picker</span>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="seasons" placeholder="Any" (onChange)="filter($event.value)" optionLabel="endDate">
                                    <ng-template let-option pTemplate="item">
                                        <div class="inline-block vertical-align-middle">
                                            <!-- <img [alt]="option.label" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ option.image }}" width="24" class="vertical-align-middle" /> -->
                                            <span class="ml-1 mt-1">{{ option.name }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th style="min-width:15rem">
                    <div class="flex align-items-center">
                       Grupos
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-season let-columns="columns">
            <tr>
                <td>
                    <span class="p-column-title font-bold">Nombre</span>
                    <span class="ml-1 vertical-align-middle">{{ season.name }}</span>
                </td>
                <td>
                    <span class="p-column-title font-bold">Fecha Inicio</span>
                    <span class="ml-1 vertical-align-middle">{{ season.startDate }}</span>
                </td>
                <td>
                    <span class="p-column-title font-bold">Fecha FIn</span>
                    <span class="ml-1 vertical-align-middle">{{ season.endDate }}</span>
                </td>
                <td>
                    <span class="p-column-title font-bold">Acciones</span>
                    <button pButton pRipple icon="pi pi-sitemap" class="p-button-rounded p-button-primary m-1" (click)="showGroups(season)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">No se encontraron ciclos.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<div *ngIf="showSeasonGroups">
    <p-button icon="pi pi-arrow-circle-left"  label="Volver a ciclos" [text]="true" severity="primary" (click)="returnAllSeasons()"></p-button>
    <div class="surface-section px-4 py-4 md:px-6 lg:px-8 text-center">
            <div class="mb-3 font-bold text-xl">
                <span class="text-900">Grupos, </span>
                <span class="text-primary-600">{{seasonSelected.name}} | {{seasonSelected.startDate}} / {{seasonSelected.endDate}}</span>
            </div>
            <div class="text-700 mb-2">Información de los grupos pertenecientes al ciclo seleccionado.</div>
  
        </div>
    <app-groups-seasons [seasonSelected]="seasonSelected"></app-groups-seasons>
</div>