export enum ProcessStatus{   
    CREATED = 'created',
    REVIEW = "review",
    APPROVED= "approved",
    REJECTED = "rejected",
    CANCELLED = "cancelled",
    VALIDATED = "validated",
    RESPONSED = "responsed",
    REGISTRED = "registred",
    PAID = "paid",
    FINISHED = "finished",
    QUEUED ="queued"
}