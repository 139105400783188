<app-page-header
  [title]="'Cursos & Niveles'"
  [subtitle]="'Módulo de administración de cursos y niveles'"
></app-page-header>
<app-crud-table
  [crudTable]="crudTableData"
  (itemEdit)="editItem($event)"
  (itemDelete)="deleteItem($event)"
  (itemAdd)="addItem()"
></app-crud-table>
<p-confirmDialog
  [style]="{ width: '450px', 'z-index': '2000 !important' }"
  [autoZIndex]="false"
></p-confirmDialog>
<div *ngIf="showForm">
  <p-dialog
    [header]="dialogHeader"
    [(visible)]="showForm"
    [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '80vw', height: '80vh', top: '2.5rem' }"
    [modal]="true"
    [draggable]="false"
    [resizable]="true"
  >
    <div class="card" [formGroup]="validateForm">
      <div class="flex flex-column gap-2">
        <!-- <label for="seasonId">Identificador</label>
        <p-inputMask
          pInputText
          mask="CURSO_***_**"
          formControlName="coursesLevelId"
          placeholder="CURSO_ESC_AD"
        ></p-inputMask>
        <small id="seasonId-small">Identificador del curso.</small> -->

        <label for="name">Nombre</label>
        <input
          type="text"
          pInputText
          placeholder="Ingrese nombre para el curso"
          formControlName="name"
        />
        <small id="name-small">Nombre del curso.</small>

        <!-- Agregar lista de niveles y add button -->
        <p-orderList
          [responsive]="true"
          [value]="validateForm.get('levels')?.value"
          [listStyle]="{ 'max-height': '30rem' }"
          (onReorder)="reorderLevels()"
          [dragdrop]="true"
          [controlsPosition]="'right'"
        >
          <ng-template let-level pTemplate="item">
            <div class="flex flex-wrap p-2 align-items-center gap-3">
              <p-button
                [rounded]="true"
                styleClass="w-2rem h-2rem p-0"
                [outlined]="false"
                [label]="level.levelOrder"
              ></p-button>
              <div class="flex-1 flex flex-column gap-2">
                <span class="font-bold">{{ level.name }}</span>
                <div class="flex align-items-center gap-2">
                  <i class="pi pi-flag text-sm"></i>
                  <span>{{ level.objective }}</span> |
                  <i class="pi pi-clock text-sm"></i>
                  <span>{{ level.clases }} Clases</span> |
                  <i class="pi pi-power-off text-sm"></i>
                  <span>{{ level.isActive ? "Activo" : "Inactivo" }}</span> |
                  <i class="pi pi-verified text-sm"></i>
                  <span>{{ level.opcional ? "Opcional" : "Obligatorio" }}</span>
                </div>
              </div>
              <button
                pButton
                pRipple
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary m-1"
                (click)="initEditlevel(level)"
              ></button>
              <button
                pButton
                pRipple
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger m-1"
                (click)="initDeleteLevel(level)"
              ></button>
            </div>
          </ng-template>
          <ng-template pTemplate="header" class="bg-white">
            <p-menubar [model]="items">
              <ng-template pTemplate="start">
                <h3>Niveles</h3>
              </ng-template>
              <ng-template pTemplate="end">
                <p-button
                  label="Agregar nivel"
                  icon="pi pi-plus"
                  iconPos="left"
                  (click)="initAddLevel()"
                ></p-button>
              </ng-template>
            </p-menubar>
          </ng-template>
        </p-orderList>
        <label for="description">Descripción del curso</label>
        <textarea
          rows="5"
          cols="30"
          pInputTextarea
          formControlName="description"
        ></textarea>

        <label for="associatedProductsNew"
          >Productos y servicios asociados a la Matricula</label
        >
        <p-multiSelect
          class="multiselect-custom-virtual-scroll"
          [virtualScroll]="true"
          [virtualScrollItemSize]="43"
          [options]="productAndServices"
          formControlName="associatedProductsNew"
          optionLabel="name"
          renovación="Productos o servicios en la matricula"
        ></p-multiSelect>

        <label for="associatedProductsNew"
          >Productos y servicios asociados a la Renovación</label
        >
        <p-multiSelect
          class="multiselect-custom-virtual-scroll"
          [virtualScroll]="true"
          [virtualScrollItemSize]="43"
          [options]="productAndServices"
          formControlName="associatedProductsRenewal"
          optionLabel="name"
          renovación="Productos o servicios en la renovacion"
        ></p-multiSelect>

        <label for="isActive">Activo</label>
        <p-selectButton
          [options]="stateOptions"
          formControlName="isActive"
          optionLabel="label"
          optionValue="value"
        ></p-selectButton>
      </div>

      <div class="flex mt-2 gap-2 justify-content-end">
        <p-button
          label="Cancelar"
          [outlined]="true"
          severity="secondary"
          [rounded]="true"
          (click)="cancelEditItem()"
        ></p-button>
        <p-button
          *ngIf="isNewForm"
          type="submit"
          label="Guardar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="addNewItem()"
        ></p-button>
        <p-button
          *ngIf="isUpdateForm"
          type="submit"
          label="Actualizar"
          [rounded]="true"
          [disabled]="validateForm.invalid"
          (click)="editExistentItem()"
        ></p-button>
      </div>
    </div>
  </p-dialog>
</div>
<div *ngIf="showLevelForm" class="nivel__form-nivel">
  <p-dialog [(visible)]="showLevelForm">
    <ng-template pTemplate="headless">
      <div
        [formGroup]="levelValidateForm"
        class="flex flex-column px-8 py-5 gap-2"
        style="
          border-radius: 12px;
          background-image: radial-gradient(
            circle at left top,
            var(--primary-400),
            var(--primary-700)
          );
        "
      >
        <div class="inline-flex flex-column gap-2">
          <label for="lavelName" class="text-primary-50 font-semibold"
            >Nombre Nivel</label
          >
          <input
            pInputText
            id="lavelName"
            formControlName="name"
            class="bg-white-alpha-20 border-none p-3 text-primary-50"
          />
        </div>

        <div class="inline-flex flex-column gap-2">
          <label for="hoursOfLevel" class="text-primary-50 font-semibold"
            >Número Clases</label
          >
          <p-inputNumber inputId="hoursOfLevel" formControlName="clases">
          </p-inputNumber>
        </div>
        <div class="inline-flex flex-column gap-2">
          <label class="text-primary-50 font-semibold"
            >Objetivo del nivel</label
          >
          <textarea
            rows="5"
            cols="30"
            pInputTextarea
            formControlName="objective"
          ></textarea>
        </div>

        <div class="inline-flex flex-column gap-2">
          <label class="text-primary-50 font-semibold">Activo</label>

          <div class="card flex justify-content-center">
            <p-selectButton
              [options]="stateOptions"
              formControlName="isActive"
              optionLabel="label"
              optionValue="value"
            ></p-selectButton>
          </div>
        </div>

        <!-- <div class="inline-flex flex-column gap-2">
          <label class="text-primary-50 font-semibold">Opcional</label>

          <div class="card flex justify-content-center">
            <p-selectButton
              [options]="stateOptions"
              formControlName="isOptional"
              optionLabel="label"
              optionValue="value"
            ></p-selectButton>
          </div>
        </div> -->

        <div class="flex align-items-center gap-2">
          <p-button
            label="Cancelar"
            (click)="closeLevelialog()"
            styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
            class="w-full"
          ></p-button>
          <p-button
            *ngIf="isNewLevel"
            type="submit"
            label="Agregar"
            (click)="addLevel()"
            styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
            class="w-full"
          ></p-button>
          <p-button
            *ngIf="isEditLevel"
            type="submit"
            label="Actualizar"
            (click)="editLevel()"
            styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
            class="w-full"
          ></p-button>
        </div>
      </div>
    </ng-template>
    <p-toast></p-toast>
  </p-dialog>
</div>
<div *ngIf="showDependencies"> 
  <app-dependencies-list [dependencies]="dependenciesToDelete" (onCloseDependencies)="closeDependenceDialog"></app-dependencies-list>
  </div>