import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { Member } from '../../../../model/member';

import { CommonModule } from '@angular/common';
import moment from 'moment';
import { ChipModule } from 'primeng/chip';
import { MemberRegisterComponent } from '../member-register/member-register.component';
@Component({
  selector: 'app-member-information',
  standalone: true,
  imports: [PanelModule,AvatarModule,AvatarGroupModule,ButtonModule,MenuModule,DividerModule,ChipModule, CommonModule, MemberRegisterComponent],
  templateUrl: './member-information.component.html',
  styleUrl: './member-information.component.scss'
})
export class MemberInformationComponent implements OnInit{

  items: MenuItem[] | undefined;
  isCollapsed:boolean = true;

  @Input()
  member!:Member;
  showMemberEditForm:boolean = false;

  @Output()
  memberUpdated:EventEmitter<Member> = new EventEmitter<Member>();

  ngOnInit(): void {
    this.items = [
      {
          label: 'Editar',
          icon: 'pi pi-fw pi-pencil',
      },
  ];
  }

  calculateYearsOld(birthDay: string) {
    return moment().diff(birthDay, 'years');
  }

  initMemberEdit(member:Member){
    this.showMemberEditForm = true;
  }

  refreshMemberUpdated(member:Member){
    this.member = member;
    this.showMemberEditForm = false;
    this.memberUpdated.emit(member);
  }

  closeEditDialog(){
    this.showMemberEditForm = false;
  }

}
