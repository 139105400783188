
<div class="surface-section px-4 py-4 md:px-6 lg:px-8 text-center">
  <div class="mb-3 font-bold text-3xl">
      <span class="text-900">Menú, </span>
      <span class="text-primary-600">Gestión de Accesos</span>
  </div>
  <div class="text-700 mb-6">Selecciona una opción del menú para comenzar.</div>
  <div class="grid justify-content-center">
      <div [routerLink]="option.routerLink" class="col-12 md:col-4 mb-4 px-5 item-menu-home" *ngFor="let option of menuItems" >
              <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                  <i [class]="option.class"></i>
              </span>
          <div class="text-900 text-xl mb-3 font-medium">{{option.title}}</div>
          <span class="text-700 line-height-3">{{option.description}}</span>
      </div>

  </div>
</div>