<p-menubar [model]="menuRol" styleClass="mainBar" >
    <ng-template pTemplate="start">
        <img src="https://images.prismic.io/lipatinpdn/45174b3e-e9e7-4672-95bc-c554c511f14e_Logo+Liga+de+Patinaje+de+Antioquia_Mesa+de+trabajo+1.png?auto=compress,format" height="60" class="mr-2" />
    </ng-template>
    <ng-template pTemplate="end">
        <div *ngIf="isLogged">
            <p-splitButton  [label]="userName" [model]="this.userLoggedOptions"   styleClass="p-button-text p-button-primary mr-2 mb-2"></p-splitButton>
        </div>
        <div *ngIf="!isLogged">
            <p-button label="Iniciar Sesión" (click)="loguin($event)" [rounded]="true" severity="success"></p-button>
        </div>

    </ng-template>
</p-menubar>