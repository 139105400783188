import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Table, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { ClassGroup, ClassGroupDetail, ClassRegister } from '../../../model/ClassGroup';
import { Course } from '../../../model/Course';
import { CrudTable } from '../../../model/CrudTable';
import { Headquarters } from '../../../model/Headquarter';
import { HttpResponse } from '../../../model/HttpResponse';
import { Instructor } from '../../../model/Instructor';
import { Level } from '../../../model/Level';
import { Sch } from '../../../model/Sch';
import { Season } from '../../../model/Season';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../services/administration.service';
import { GestionService } from '../../../services/gestion.service';
import { LoadingService } from '../../../services/loading.service';
import { GroupsSeasonsMembersComponent } from '../../gestor/ciclos/groups-seasons-members/groups-seasons-members.component';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
import { InstructorSeasonsGroupsAttendencesComponent } from "../instructor-seasons-groups-attendences/instructor-seasons-groups-attendences.component";
import { InstructorSeasonsMembersComponent } from "../instructor-seasons-members/instructor-seasons-members.component";
@Component({
    selector: 'app-instructor-seasons-groups',
    standalone: true,
    providers: [MessageService, ConfirmationService],
    templateUrl: './instructor-seasons-groups.component.html',
    styleUrl: './instructor-seasons-groups.component.scss',
    imports: [
        PageHeaderComponent,
        CrudTableComponent,
        ConfirmDialogModule,
        ReactiveFormsModule,
        DialogModule,
        InputTextModule,
        CommonModule,
        InputSwitchModule,
        SelectButtonModule,
        TableModule,
        MultiSelectModule,
        FormsModule,
        ButtonModule,
        GroupsSeasonsMembersComponent,
        InstructorSeasonsMembersComponent,
        TooltipModule,
        InstructorSeasonsGroupsAttendencesComponent
    ]
})
export class InstructorSeasonsGroupsComponent implements OnInit{
  groups: ClassGroup[] = [];

  @Input()
  seasonSelected!: Season;

  groupSelected!:ClassGroupDetail;
  loading: boolean = false;
  headqueters: Headquarters[] = [];
  seasons: Season[] = [];
  courses: Course[] = [];
  levels: Level[] = [];
  schs: Sch[] = [];
  instructors:Instructor[] = [];
  availableGroups: ClassGroupDetail[] = [];
  showTable: boolean = false;
  showGroupsTable:boolean = false;
  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  showMembersDialog:boolean = false;
  showAttendencesDialog:boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  userMail:string = '';
  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private administrationService: AdministrationService,
    private gestionService: GestionService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private msalService: MsalService
  ) {}
  ngOnInit(): void {
    this.selectClassGroups();
    console.log("User Mail => ",this.msalService.instance.getActiveAccount()?.idTokenClaims);
    this.userMail = this.msalService.instance.getActiveAccount()?.idTokenClaims?.preferred_username || '';
}
  

  async selectClassGroups() {
    this.loadingService.showLoading();
    this.showTable = false;
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'seasonId',
              parameterName: '@Option1',
              parameterValue: this.seasonSelected.id,
            }
          ],
        },
      },
      type: AcademyAdministratorType.GROUPS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe(async (res: HttpResponse) => {
        if (res.response.length > 0) {
          this.groups = res.response;
          this.getDetailOfGroups(res.response);
        } else {
          const masterOptions = await this.buildMasterOptions();
          this.showTable = true;
          this.loadingService.hideLoading();
        }
      });
  }

  async buildMasterOptions() {
    this.instructors = await this.getInstructors();
    this.headqueters = await this.getHeadquarter();
    this.courses = await this.getCourses();
    this.seasons = await this.getSeasons();
    this.schs = (await this.getSchs()).map((sch) => {
      return this.getSchHumanLabel(sch);
    });
  }

  async getHeadquarter(): Promise<Headquarters[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.HEADQUARTERS,
      true,
      'isActive'
    );
    return groupData as Headquarters[];
  }

  async getCourses(): Promise<Course[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.COURSES_LEVELS,
      true,
      'isActive'
    );
    return groupData as Course[];
  }

  async getSeasons(): Promise<Season[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SEASONS,
      true,
      'availableToAdministrativeGestion'
    );
    return groupData as Season[];
  }

  async getSchs(): Promise<Sch[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SCHS,
      true,
      'isActive'
    );
    return groupData as Sch[];
  }

  async getInstructors(): Promise<Instructor[]> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.INSTRUCTORS,
      true,
      'isActive'
    );
    return groupData as Instructor[];
  }

  async getDetailOfGroups(generalGroups: ClassGroup[]) {
    this.availableGroups = [];
    await this.buildMasterOptions();
    for await (let group of generalGroups) {
      let groupDetail = await this.getSchDetail(group.schId);
      let classGroupDetail: ClassGroupDetail = {
        ...group,
        sch: groupDetail,
        headquarter: this.getHeadquarterDetail(group.headquarterId),
        course: this.getCourseDetail(group.courseId),
        level: this.getLevelDetail(group.levelId),
        season: this.getSeasonDetail(group.seasonId),
        name: `${group.classGroupId} - ${groupDetail.days} ${groupDetail.startTime} - ${groupDetail.endTime} | Inscritos: ${group.state.membersCount} de ${group.maxMembers}`,
        isComplete: group.state.membersCount < group.maxMembers ? false : true,
        instructor: this.getInstructorDetail(group.instructorId!) || 'Sin Definir' 
      };
      if (!this.availableGroups.some((x) => x.id === classGroupDetail.id) && classGroupDetail.instructor?.email === this.userMail) {
        this.availableGroups.push(classGroupDetail);
      }
    }
    this.showGroupsTable = true;
    this.loadingService.hideLoading();
  }

  getAvailableLevels() {
    this.validateForm.get('level')?.setValue(null);
    let availableLevels = this.validateForm
      .get('course')
      ?.value['levels'].map((level: Level) => {
        return level;
      });
    this.levels = availableLevels;
  }

  async getSchDetail(id: string): Promise<Sch> {
    const groupData = await this.getEntity(
      AcademyAdministratorType.SCHS,
      id,
      'id'
    );
    return groupData[0] as Sch;
  }

  getInstructorDetail(id: string): any {
    return this.instructors.find((x) => x.id === id);
  }

  async getEntity(
    academyAdministratorType: AcademyAdministratorType,
    value: any,
    property?: string
  ): Promise<any[]> {
    return new Promise<any[]>((resolve, reject) => {
      this.loadingService.showLoading();

      let data: any[] = [];
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: property || 'id',
                parameterName: '@Option1',
                parameterValue: value,
              },
            ],
          },
        },
        type: academyAdministratorType,
      };
      try {
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.response.length > 0) {
              data = res.response as any[];
            }
            resolve(data);
            this.loadingService.hideLoading();
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  getHeadquarterDetail(id: string): any {
    return this.headqueters.find((x) => x.id === id);
  }
  getCourseDetail(id: string): any {
    return this.courses.find((x) => x.id === id);
  }

  getLevelDetail(id: string): any {
    let curso = this.courses.find((curso) =>
      curso.levels.some((level) => level.id === id)
    );
    let level = curso?.levels.find((level) => level.id === id);

    return level;
  }

  getSeasonDetail(id: string): any {
    return this.seasons.find((x) => x.id === id);
  }

  closeDialog() {
    this.showForm = false;
  }

  getSchHumanLabel(sch: Sch): Sch {
    sch.days = `${sch.days} ${sch.startTime} - ${sch.endTime}`;
    return sch;
  }


  buildAcademyItem(form: any): ClassGroup {
    let item: ClassGroup = {
      headquarterId: form['headquarter']['id'],
      classGroupId: form['classGroupId']
        ? form['classGroupId']
        : `GRUPO_${new Date().getTime()}`,
      courseId: form['course']['id'],
      levelId: form['level']['id'],
      seasonId: form['season']['id'],
      schId: form['sch']['id'],
      isRecurrent: form['isRecurrent'],
      maxMembers: form['maxMembers'],
      state: {
        classCount: form['classCount'],
        isActive: form['isActive'],
        membersCount: form['membersCount'],
      },
      instructorId: null,
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }

  clear(table: Table) {
    table.clear();
  }

  showMembers(classGroup: ClassGroupDetail) {

    this.groupSelected = classGroup;
    this.showMembersDialog = true;
    this.showGroupsTable = false;
  }

  closeMembersDialog(){
    this.showMembersDialog = false;
    this.showGroupsTable = true;

  }

  closeAttendenceDialog(){
    this.showGroupsTable = true;
    this.showAttendencesDialog = false;
  }

  registerClass(classGroup: ClassGroupDetail){

    if (this.validateCheckinonSameDay(classGroup)) {
      this.confirmationService.confirm({
        message: `Ya se realizó un registro de inicio de clase el dia de hoy para ${classGroup.course?.name} ${classGroup.level?.name} del horario ${classGroup.sch.days} ${classGroup.sch.startTime} - ${classGroup.sch.endTime}`,
        header: 'Confirmación Inicio de Clase',
        acceptLabel: 'Entendido',
        rejectVisible:false,
        rejectLabel: 'No',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          // await this.updateClassGroup(classGroup);
        },
      });
    }else{
      this.confirmationService.confirm({
        message: `¿Confirma que se iniciará una nueva clase para el grupo ${classGroup.course?.name} ${classGroup.level?.name} del horario ${classGroup.sch.days} ${classGroup.sch.startTime} - ${classGroup.sch.endTime}?`,
        header: 'Confirmación Inicio de Clase',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Si',
        rejectLabel: 'No',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: async () => {
          await this.updateClassGroup(classGroup);
        },
        reject: () => {
          
        }
    });
    }

  }

  validateCheckinonSameDay(classGroup: ClassGroupDetail): boolean {
    let hasOtherRegisterOnSameDay = false;
    const registers =  classGroup.state.classRegister || [];
    for (let register of registers) {
      // console.log("Moment Register=> ",moment(register.date));
      
      const difference = moment(register.date).diff(
        moment().format('YYYY-MM-DD HH:mm:ss'),
        'hours'
      );
      // console.log("Moment Now => ", moment().format('YYYY-MM-DD HH:mm:ss'));
      
      // console.log("hours Difference =>", Math.abs(difference));
      
      if(Math.abs(difference) < 12){
        hasOtherRegisterOnSameDay = true;
       }
    }

    return hasOtherRegisterOnSameDay;
  }

  showAttendeces(classGroup: ClassGroupDetail){

    this.groupSelected = classGroup;
    this.showGroupsTable = false;
    this.showAttendencesDialog = true;
  }

  async updateClassGroup(classGroupDetail:ClassGroupDetail){
    this.loadingService.showLoading();
    const item = this.buildClassGroupFormDetail(classGroupDetail);
    item.state.classCount =  item.state.classCount + 1; 
    const newClassRegister:ClassRegister = {
          date:moment().format('YYYY-MM-DD HH:mm:ss'),
          registerBy:this.userMail
    }
    item.state.classRegister?.push(newClassRegister);
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.UPDATE,
      payload: item,
      type: AcademyAdministratorType.GROUPS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        if (res.code === 200) {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'El registro se ha actualizado correctamente!',
            life: 3000,
          });
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail:
              'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
            life: 3000,
          });
        }
        this.selectClassGroups();
        this.showForm = false;
        this.loadingService.hideLoading();
      });
  }

  buildClassGroupFormDetail(classGroupDetail:ClassGroupDetail):ClassGroup{
    let item: ClassGroup = {
      ...classGroupDetail,
      state:{
        ...classGroupDetail.state,
        classRegister:classGroupDetail.state.classRegister || [],
      }
    };

    return item;
  }
}
