<div class="flex align-items-center gap-4">
  <p class="text-500 font-small">Id Matricula: <br />{{ membership.id }}</p>
  <p class="text-500 font-small">Usuario: <br />{{ membership.memberId }}</p>
  <p class="text-500 font-small">
    Fecha Transacción: <br />{{ membership.auditData.created_at }}
  </p>
</div>
<div *ngIf="showForm" [formGroup]="validateForm">
  <label for="address">Tipo de Facturación*</label>
  <p-dropdown
    [style]="{ display: 'flex' }"
    formControlName="legalizationDocumentType"
    [options]="legalizationDocumentType"
    optionLabel="label"
    placeholder="Seleccione tipo de factura"
    [id]="'id'"
  ></p-dropdown>

  <div class="flex flex-column gap-2 mt-2">
    <label for="name">Factura*</label>
    <p-inputMask
      *ngIf="
        validateForm.get('legalizationDocumentType')?.value?.value === 'POS'
      "
      [style]="{ display: 'flex' }"
      mask="POSL99999"
      formControlName="receiptOfPayment"
      placeholder="POSLXXXXX"
    ></p-inputMask>
    <p-inputMask
      *ngIf="
        validateForm.get('legalizationDocumentType')?.value?.value ===
        'FACTURA_ELECTRONICA'
      "
      [style]="{ display: 'flex' }"
      mask="LPFE9999"
      formControlName="receiptOfPayment"
      placeholder="LPFEXXXX"
    ></p-inputMask>
    <input
      *ngIf="
        validateForm.get('legalizationDocumentType')?.value?.value ===
        'CONVENIO_DESCUENTO'
      "
      [style]="{ display: 'flex', height: '2rem', fontSize: '1rem' }"
      type="text"
      pInputText
      placeholder="número recibo pago en convenio o nombre del autorizador del descuento"
      formControlName="receiptOfPayment"
    />
  </div>

  <div class="flex mt-2 gap-2 justify-content-end">
    <p-button
      label="Cancelar"
      [outlined]="true"
      severity="secondary"
      [rounded]="true"
      (click)="cancelDialogForm()"
    ></p-button>
    <p-button
      type="submit"
      label="Legalizar"
      [rounded]="true"
      [disabled]="!validateForm.hasError"
      (click)="markAsLegalized()"
    ></p-button>
  </div>
</div>

<p-toast></p-toast>
<p-confirmDialog></p-confirmDialog>
