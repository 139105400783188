import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Member } from '../../../../model/member';
import { MembershipDetail } from '../../../../model/Membership';
import { AcademyQualification, AcademyState } from '../../../../model/AcademyState';
import { CommonModule } from '@angular/common';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { MemberCreateMembershipComponent } from "../member-create-membership/member-create-membership.component";
@Component({
    selector: 'app-member-new-membership',
    standalone: true,
    templateUrl: './member-new-membership.component.html',
    styleUrl: './member-new-membership.component.scss',
    imports: [CommonModule, CardModule, ButtonModule, MemberCreateMembershipComponent]
})
export class MemberNewMembershipComponent implements OnInit{
  constructor(
    ) {}
  
    @Input()
    member!: Member;
  
  
    @Input()
    membershipsDatail: MembershipDetail[] = [];

    @Output()
    setNewStatus:EventEmitter<any> = new EventEmitter();
  
    @Output()
    refreshMeberships:EventEmitter<any> = new EventEmitter();

    showNewMembership: boolean = false;
    showMembershipForm:boolean = false;

    inProgressMembership:MembershipDetail[] = [];
    showDialogInProgressMembership:boolean = false;
    ngOnInit(): void {

      this.validateInProgressMembership();
 
      this.showNewMembership = true;
    }

    validateInProgressMembership():boolean{
      this.showDialogInProgressMembership = false;
      let canNewRegister = true;
      for(let membership of this.membershipsDatail){
        if(membership.academyState.state === AcademyQualification.IN_PROGRESS || membership.academyState.state === AcademyQualification.PENDING){
          this.inProgressMembership.push(membership);
        }
      }
      if(this.inProgressMembership.length > 0){
        canNewRegister = false;
        this.showDialogInProgressMembership = true;
      }else{
        this.showMembershipForm = true;
      }
      return canNewRegister;
    }

    updateMemberships(){
      this.refreshMeberships.emit();
    }


    getSeverity(status: AcademyState): string {
      switch (status.state) {
        case AcademyQualification.IN_PROGRESS:
          return 'info';
          case  AcademyQualification.APPROVED:
          return 'success';
          case AcademyQualification.FAILED:
          return 'danger';
          case AcademyQualification.PENDING:
          return 'warning';
        default:
          return 'warning';
      }
    }
  
    getStatus(status: AcademyState): string {
      switch (status.state) {
        case AcademyQualification.IN_PROGRESS:
          return 'En curso';
        case  AcademyQualification.APPROVED:
          return 'Aprobado';
        case AcademyQualification.FAILED:
          return 'No Aprobado';
          case AcademyQualification.PENDING:
            return 'Sin Iniciar';
        default:
          return status.state;
      }
    }

    closeDialogInProgressMembership(){
      this.showDialogInProgressMembership = false;
      this.showMembershipForm = true;
    }
}
