import { Injectable } from '@angular/core';
import { CallHttpService } from './call-http.service';
import { environment } from '../../environments/environment';
import { HttpResponse } from '../model/HttpResponse';
import { Observable } from 'rxjs';
import { File } from "../model/File";
@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(private callHttpService:CallHttpService) { }
  
    getDocument(id:string):Observable<any>{
        return this.callHttpService
          .post(`${environment.academyApi.documents}/getDocument`,{fileId:id});
      }

      uploadCarnet(file:File):Observable<string>{
        return  this.callHttpService
        .post(`${environment.academyApi.documents}/carnetUpload`,file)

      }

      uploadTransactionSupport(file:File):Observable<any>{
        return  this.callHttpService
        .post(`${environment.academyApi.documents}/UploadTransactionSupport`,file)

      }
}
