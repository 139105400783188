import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CrudTable } from '../../../model/CrudTable';
import { Dependence } from '../../../model/Dependencie';
import { Headquarters } from '../../../model/Headquarter';
import { HttpResponse } from '../../../model/HttpResponse';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { AdministrationService } from '../../../services/administration.service';
import { LoadingService } from '../../../services/loading.service';
import { ValidationService } from '../../../services/validation.service';
import { DependenciesListComponent } from "../../shared/dependencies-list/dependencies-list.component";
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';
@Component({
    selector: 'app-sedes',
    standalone: true,
    templateUrl: './sedes.component.html',
    styleUrl: './sedes.component.scss',
    providers: [MessageService, ConfirmationService],
    imports: [
        PageHeaderComponent,
        CrudTableComponent,
        ConfirmDialogModule,
        ReactiveFormsModule,
        DialogModule,
        InputTextModule,
        CommonModule,
        InputSwitchModule,
        SelectButtonModule,
        DependenciesListComponent
    ]
})
export class SedesComponent implements OnInit {
  headquarters: Headquarters[] = [];

  crudTableData: CrudTable = {
    data: [],
    dataKey: '',
    headers: [],
    items: [],
  };

  validateForm!: FormGroup;
  showForm: boolean = false;
  isNewForm: boolean = false;
  isUpdateForm: boolean = false;
  dialogHeader?: string;
  stateOptions: any[] = [
    { label: 'Inactiva', value: false },
    { label: 'Activa', value: true },
  ];
  showDependencies: boolean = false;
  dependenciesToDelete: Dependence[] = [];
  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private administrationService: AdministrationService,
    private loadingService: LoadingService,
    private fb: FormBuilder,
    private validationServices: ValidationService
  ) {}
  ngOnInit(): void {
    this.selectHeadquarters();
  }

  async selectHeadquarters() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: null,
      },
      type: AcademyAdministratorType.HEADQUARTERS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        this.buildCrudTable(res.response);
        this.loadingService.hideLoading();
      });
  }

  buildCrudTable(data: any[]) {
    this.crudTableData = {
      data: data,
      dataKey: 'id',
      headers: [{
        label:'Nombre',
        sorted:true,
        sortItem:'name'
      },{
        label:'Dirección',
        sorted:true,
        sortItem:'address'
      },
      {
        label:'Teléfono',
        sorted:true,
        sortItem:'telephone'
      },
      {
        label:'Activa',
        sorted:true,
        sortItem:'isActive'
      },
      {
        label:'Acciones',
        sorted:false,
        sortItem:null
      },
    ],
      items: ['name', 'address', 'telephone', 'isActive'],
    };
  }

  addItem() {
    this.getFormNew();
  }

  editItem(item: Headquarters) {
    this.getFormRenewal(item);
  }

  async deleteItem(item: Headquarters) {
    this.dependenciesToDelete = [];
    this.showDependencies = false;
    const entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.DELETE,
      payload: item,
      type: AcademyAdministratorType.HEADQUARTERS,
    };
    let dependencies = await this.validationServices.dependenciesToDelete(
      entity
    );
    if (dependencies.length === 0) {
    this.confirmationService.confirm({
      message: 'Estas seguro de borrar este registro ?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
 
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha eliminado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectHeadquarters();
            this.loadingService.hideLoading();
          });
      },
    });
  } else {
    this.dependenciesToDelete = dependencies.map((dependencie: Headquarters) => {
      const dependenciToList: Dependence = {
        id: dependencie.id,
        type: AcademyAdministratorType.HEADQUARTERS,
        name: dependencie.name,
      };
      return dependenciToList;
    });
    this.showDependencies = true;
  }
  }

  getFormNew() {
    this.validateForm = this.fb.group({
      name: [null, [Validators.required]],
      address: [null, [Validators.required]],
      telephone: [null, [Validators.required]],
      administratorId: [null],
      isActive: [true, [Validators.required]],
      headquarterId: [null],
    });
    this.dialogHeader = 'Nueva Sede';
    this.isNewForm = true;
    this.isUpdateForm = false;
    this.showForm = true;
  }

  getFormRenewal(item: Headquarters) {
    this.validateForm = this.fb.group({
      id: [item.id, [Validators.required]],
      headquarterId: [item.headquarterId],
      name: [item.name, [Validators.required]],
      address: [item.address, [Validators.required]],
      telephone: [item.telephone, [Validators.required]],
      administratorId: [item.headquarterId || null],
      isActive: [item.isActive, [Validators.required]],
    });
    this.dialogHeader = `Editar ${item.name}`;
    this.isNewForm = false;
    this.isUpdateForm = true;
    this.showForm = true;
  }

  closeDialog() {
    this.showForm = false;
  }
  
  closeDependenceDialog() {
    this.showDependencies = false;
  }


  addNewItem() {
    if (this.validateForm.valid) {
      this.loadingService.showLoading();
      const item = this.buildAcademyItem(this.validateForm.value);
      const entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.CREATE,
        payload: item,
        type: AcademyAdministratorType.HEADQUARTERS,
      };
      this.administrationService
        .sendRequest(entity)
        .subscribe((res: HttpResponse) => {
          if (res.code === 200) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'El registro se ha creado correctamente!',
              life: 3000,
            });
          } else {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail:
                'Ocurrió un error creando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
              life: 3000,
            });
          }
          this.selectHeadquarters();
          this.showForm = false;
          this.loadingService.hideLoading();
        });
    } else {
      this.messageService.add({
        severity: 'warning',
        summary: 'Verificar',
        detail:
          'Verifique que todos los campos obligatorios del formulario estén completos',
        life: 3000,
      });
    }
  }

  editExistentItem() {
    this.confirmationService.confirm({
      message: '¿Estas seguro de actualizar este registro?',
      header: 'Confirmar',
      acceptLabel: 'Si',
      rejectLabel: 'No',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.loadingService.showLoading();
        const item = this.buildAcademyItem(this.validateForm.value);
        const entity: AdministratorEntity = {
          operation: AcademyAdministratorOperation.UPDATE,
          payload: item,
          type: AcademyAdministratorType.HEADQUARTERS,
        };
        this.administrationService
          .sendRequest(entity)
          .subscribe((res: HttpResponse) => {
            if (res.code === 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'El registro se ha actualizado correctamente!',
                life: 3000,
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail:
                  'Ocurrió un error eliminando el registro, inténtelo nuevamente si el error persiste contacte el administrador del sistema',
                life: 3000,
              });
            }
            this.selectHeadquarters();
            this.showForm = false;
            this.loadingService.hideLoading();
          });
      },
    });
  }

  buildAcademyItem(form: any): Headquarters {
    let item: Headquarters = {
      name: form['name'].toUpperCase(),
      address: form['address'],
      headquarterId: form['headquarterId'] || form['name'],
      administrator: form['administrator'],
      isActive: form['isActive'],
      telephone: form['telephone'],
    };

    if (form['id']) {
      item.id = form['id'];
    }

    return item;
  }
}
