import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import moment from 'moment';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MenuModule } from 'primeng/menu';
import { PanelModule } from 'primeng/panel';
import { SpeedDialModule } from 'primeng/speeddial';
import { BehaviorSubject } from 'rxjs';
import { Headquarters } from '../../../model/Headquarter';
import { HttpResponse } from '../../../model/HttpResponse';
import { MembershipDetail } from '../../../model/Membership';
import { Season } from '../../../model/Season';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { IdType } from '../../../model/idType';
import { Member } from '../../../model/member';
import { AdministrationService } from '../../../services/administration.service';
import { GestionService } from '../../../services/gestion.service';
import { LoadingService } from '../../../services/loading.service';
import { MemberMenuComponent } from "../../gestor/matriculas/member-menu/member-menu.component";
import { PageHeaderComponent } from "../../shared/page-header/page-header.component";
import { CheckinMembershipsComponent } from "./checkin-memberships/checkin-memberships.component";
@Component({
    selector: 'app-member-checkin',
    standalone: true,
    providers: [MessageService, ConfirmationService],
    templateUrl: './member-checkin.component.html',
    styleUrl: './member-checkin.component.scss',
    imports: [FormsModule,SpeedDialModule,FormsModule, DropdownModule, CommonModule, PanelModule, AvatarModule, AvatarGroupModule, ButtonModule, MenuModule, DividerModule, ZXingScannerModule, PageHeaderComponent, MemberMenuComponent, CheckinMembershipsComponent,InputNumberModule]
})
export class MemberCheckinComponent implements OnInit, OnDestroy{
  items: MenuItem[] = [];
  availableDevices!: MediaDeviceInfo[];
  currentDevice?: MediaDeviceInfo;
  hasDevices!: boolean;
  hasPermission!: boolean;
  seasons: Season[] = [];
  seasonSelected!: Season;
  headquarters: Headquarters[] = [];
  headquarterSelected!: Headquarters;
  qrResultString!: string;
  showQRScanner:boolean = false;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  scannerEnable = false;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  memberId!:string;
  member!:Member;
  showNoFindedMember:boolean = false;
  showMemberMenu:boolean = false;
  showSeasons:boolean = false;
  showHeadquarters:boolean = false;
  showMemberships:boolean = false;
  showManualSearch:boolean = false;

  idTypes = [
    { name: 'Registro Civil', value: IdType.REGISTRO_CIVIL },
    { name: 'Tarjeta de identidad', value: IdType.TARJETA_IDENTIDAD },
    { name: 'Cédula', value: IdType.CEDULA },
    { name: 'Pasaporte', value: IdType.PASAPORTE },
    { name: 'Cédula de Extranjería', value: IdType.CEDULA_EXTRANGERIA },
    {
      name: 'Documento de Identidad Extranjero',
      value: IdType.IDENTIDAD_EXTRANGERA,
    },
    
  ];
  idTypeSelected: IdType = IdType.EMPTY;
  idNumberSelected!: any;

  constructor( private administrationService:AdministrationService, private route: ActivatedRoute,
    private loadingService: LoadingService,private gestionService:GestionService, private messageService:MessageService){

  }

  
  ngOnDestroy(): void {
    this.currentDevice = undefined;
    this.scannerEnable = false;
  }
  ngOnInit(): void {
    this.items = [
      {
          icon: 'pi pi-id-card',
          command: () => {
            this.changeModeToManualSearch();
          }
      },
      {
          icon: 'pi pi-qrcode',
          command: () => {
             this.changeModeToQRSearch();
          }
      }
  ];
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.memberId = params.get('id') || '';
     this.searchMemberById()
    });
    this.selectHeadquarters();
  }
  changeModeToManualSearch(){
    this.showManualSearch = true;
    this.showQRScanner = false
  }
  changeModeToQRSearch(){
    this.showManualSearch = false;
    this.showQRScanner = true;
  }

  onManaualSearch(){
    this.memberId = `${this.idTypeSelected}${this.idNumberSelected}`;
    this.refreshResults();
  }

  async selectSeasons() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'availableToAdministrativeGestion',
              parameterName: '@Id',
              parameterValue: true,
            }
          ],
        },
      },
      type: AcademyAdministratorType.SEASONS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        if (res.response.length > 0) {
          this.seasons = res.response;
          this.showSeasons = true;
        }
        this.loadingService.hideLoading();
      });
  }


  async selectHeadquarters() {
    this.loadingService.showLoading();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'isActive',
              parameterName: '@Id',
              parameterValue: true,
            }
          ],
        },
      },
      type: AcademyAdministratorType.HEADQUARTERS,
    };
    this.administrationService
      .sendRequest(entity)
      .subscribe((res: HttpResponse) => {
        if (res.response.length > 0) {
          this.headquarters = res.response;
          this.showHeadquarters = true;
        }
        this.loadingService.hideLoading();
      });
  }





  onHeadquarterChange(headquarter:Headquarters){
    this.clearResult();
    this.headquarterSelected = headquarter;
    this.selectSeasons();
  }



  onSeasonSelectChange(season:Season){
    this.clearResult();
    this.seasonSelected = season;
    this.showQRScanner = true;
  
  }



  clearResult(): void {
    this.showMemberships = false;
    this.showMemberMenu = false;
    this.memberId = '';
  }

  refreshResults(){
    this.showMemberships = false;
    this.showMemberMenu = false;
    this.searchMemberById();
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {

    this.qrResultString = resultString;
    this.memberId = resultString.split('checker/')[1];
    this.refreshResults();
   
  }

  onDeviceSelectChange(selected: string) {
    this.showQRScanner = false;
    const device = this.availableDevices.find(x => x.deviceId === selected);
    if(device){
      this.currentDevice = device;
    }

    this.scannerEnable = true;
    this.showQRScanner = true;
  }

  openFormatsDialog() {
    const data = {
      formatsEnabled: this.formatsEnabled,
    };
    this.messageService.add({
      severity: 'success',
      summary: 'OK',
      detail: 'El registro se ha creado correctamente!',
      life: 3000,
    });

  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  openInfoDialog() {
    const data = {
      hasDevices: this.hasDevices,
      hasPermission: this.hasPermission,
    };

    // this._dialog.open(AppInfoDialogComponent, { data });
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
  async searchMemberById() {

    if (
      this.memberId && this.memberId != ''
    ) {
      this.loadingService.showLoading();
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: 'memberId',
                parameterName: '@Id',
                parameterValue: this.memberId,
              },
            ],
          },
        },
        type: AcademyAdministratorType.MEMBERS,
      };
      this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
        if(res.response.length > 0){
            this.member = res.response[0];
            this.showMemberMenu = true;
            this.showMemberships = true;
            
        }else{
            this.showMemberMenu = false;
            this.showMemberships = false;
        }
        this.loadingService.hideLoading();
      });
    } else {
      // TODO: vista de alumno no encontrado
    }
  }

  calculateYearsOld(birthDay: string) {
    return moment().diff(birthDay, 'years');
  }

  finishMemberCheckIn(membershipDetail:MembershipDetail){
    this.clearResult();
  }

}
